import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialGlobalState from './initialState';
import {
  ICertificates,
  ICurrencyAndCosts,
  INonSubstReason,
  IPersonType,
  IThirdPartyProviderState,
  ITPP,
} from './types';

const thirdPartyProvidersSlice = createSlice({
  name: 'tpp',
  initialState: initialGlobalState(),
  reducers: {
    setProviderNames: (state, action: PayloadAction<string[]>) => {
      state.providerNames = action.payload;
    },
    setSelectedTPP(state, action: PayloadAction<string>) {
      state.selectedTPP = action.payload;
    },

    set_tpps(state, action: PayloadAction<ITPP[]>) {
      state.tpps = action.payload;
    },
    set_isAddRowTpp(
      state: IThirdPartyProviderState,
      action: PayloadAction<boolean>,
    ) {
      state.isAddRowTPP = action.payload;
    },
    set_addRowCountTpp(
      state: IThirdPartyProviderState,
      action: PayloadAction<number>,
    ) {
      state.addRowCountTpp = action.payload;
    },
    set_nonSubstReason(state, action: PayloadAction<INonSubstReason[]>) {
      state.nonSubstReason = action.payload;
    },
    set_personType(state, action: PayloadAction<IPersonType[]>) {
      state.personType = action.payload;
    },
    set_currencyAndCosts(state, action: PayloadAction<ICurrencyAndCosts[]>) {
      state.currencyAndCosts = action.payload;
    },
    set_certificates(state, action: PayloadAction<ICertificates[]>) {
      state.certificates = action.payload;
    },
    set_isTppAdded(
      state: IThirdPartyProviderState,
      action: PayloadAction<boolean>,
    ) {
      state.isTppAdded = action.payload;
    },
    set_isNonSubstReasonAdded(
      state: IThirdPartyProviderState,
      action: PayloadAction<boolean>,
    ) {
      state.isNonSubstReasonAdded = action.payload;
    },
    set_isPersonTypeAdded(
      state: IThirdPartyProviderState,
      action: PayloadAction<boolean>,
    ) {
      state.isPersonTypeAdded = action.payload;
    },
    set_isCurrencyAndCostsAdded(
      state: IThirdPartyProviderState,
      action: PayloadAction<boolean>,
    ) {
      state.isCurrencyAndCostsAdded = action.payload;
    },
    set_isCertificateAdded(
      state: IThirdPartyProviderState,
      action: PayloadAction<boolean>,
    ) {
      state.isCertificateAdded = action.payload;
    },

    set_isAddRowTppFilled(
      state: IThirdPartyProviderState,
      action: PayloadAction<boolean>,
    ) {
      state.isAddRowTppFilled = action.payload;
    },
    set_isTppUpdated: (
      state: IThirdPartyProviderState,
      action: PayloadAction<boolean>,
    ) => {
      state.isTppUpdated = action.payload;
    },
    set_tppId: (
      state: IThirdPartyProviderState,
      action: PayloadAction<any>,
    ) => {
      state.tppId = action.payload;
    },
    set_nameOfTpp(
      state: IThirdPartyProviderState,
      action: PayloadAction<string>,
    ) {
      state.nameOfTpp = action.payload;
    },
  },
});

export const {
  setProviderNames,
  setSelectedTPP,
  set_tpps,
  set_isAddRowTpp,
  set_addRowCountTpp,
  set_nonSubstReason,
  set_personType,
  set_currencyAndCosts,
  set_certificates,
  set_isTppAdded,
  set_isNonSubstReasonAdded,
  set_isPersonTypeAdded,
  set_isCurrencyAndCostsAdded,
  set_isCertificateAdded,
  set_isAddRowTppFilled,
  set_isTppUpdated,
  set_tppId,
  set_nameOfTpp,
} = thirdPartyProvidersSlice.actions;
export default thirdPartyProvidersSlice;
