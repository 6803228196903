import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { set_isAddRowFilled } from 'store/ducks/DataEntry/Entities/entitySlice';

const useEntityStatus = () => {
  const dispatch = useDispatch();

  const {
    isEntityAdded,
    isHierarchyInGroupAdded,
    isEntityTypesAdded,
    isNatureOfEntityAdded,
    isCompetentAuthorityAdded,
    isCountryAdded,
    isValueOfTotalAssetsAdded,
    isActivitiesByEntityTypesAdded,
  } = useSelector((state: RootState) => state.entities);

  useEffect(() => {
    const statuses = [
      isEntityAdded,
      isHierarchyInGroupAdded,
      isEntityTypesAdded,
      isNatureOfEntityAdded,
      isCompetentAuthorityAdded,
      isCountryAdded,
      isValueOfTotalAssetsAdded,
      isActivitiesByEntityTypesAdded,
    ];

    if (statuses.every(Boolean)) {
      dispatch(set_isAddRowFilled(true));
    } else {
      dispatch(set_isAddRowFilled(false));
    }
  }, [
    isEntityAdded,
    isHierarchyInGroupAdded,
    isEntityTypesAdded,
    isNatureOfEntityAdded,
    isCompetentAuthorityAdded,
    isCountryAdded,
    isValueOfTotalAssetsAdded,
    isActivitiesByEntityTypesAdded,
  ]);
};

export default useEntityStatus;
