import React from 'react';
import { Input, Form, theme, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import AuthLayout from 'components/Layout/AuthLayout/AuthLayout';
import { globalOp } from 'store/ducks/Global';
import { IResetPass } from 'store/ducks/globalTypes';
import resetPassInputs from 'constants/resetPassInputs';
import * as Styled from './ResetPassword.styled';

const { useToken } = theme;

const ResetPass: React.FC = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const handleSubmit = (values: IResetPass) => {
    const token = queryParams.token;
    if (queryParams.token) {
      const payload = {
        ...values,
        token,
      };

      globalOp.resetPassword(payload).then(() => navigate('/'));
    } else {
      notification.warning({
        message: 'Warning',
        description: 'There is no token for reset password',
        duration: 20,
      });
    }
  };

  return (
    <AuthLayout>
      <Form form={form} name="reset_password" onFinish={handleSubmit}>
        {resetPassInputs.map((item) => (
          <Styled.StyledFormItem
            key={item.label}
            token={token}
            label={item.label}
            name={item.name}
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
            ]}
          >
            <Input.Password />
          </Styled.StyledFormItem>
        ))}
        <Styled.ButtonContainer>
          <Styled.StyledButton
            type="primary"
            htmlType="submit"
            disabled={!queryParams.token}
          >
            Submit
          </Styled.StyledButton>
        </Styled.ButtonContainer>
      </Form>
    </AuthLayout>
  );
};

export default ResetPass;
