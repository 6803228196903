import { useState, useEffect } from 'react';

const useRole = () => {
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    const storedRoles = localStorage.getItem('role');
    if (storedRoles) {
      try {
        const parsedRoles = JSON.parse(storedRoles);
        setRoles(parsedRoles);
        console.log('Roles from localStorage:', parsedRoles);
      } catch (error) {
        console.error('Failed to parse roles from localStorage:', error);
      }
    }
  }, []);

  const hasRole = (role: string) => {
    return roles.includes(role);
  };

  const canCreate = () => hasRole('Admin') || hasRole('Creator');
  const canEdit = () => hasRole('Admin') || hasRole('Editor');
  const canDelete = () => hasRole('Admin');
  const canView = () => roles.length > 0;
  const canSubmit = () => hasRole('Admin') || hasRole('Editor') || hasRole('Creator');
  const canAddRow = () => hasRole('Admin') || hasRole('Creator');

  return {
    roles,
    hasRole,
    canCreate,
    canEdit,
    canDelete,
    canView,
    canSubmit,
    canAddRow,
  };
};

export default useRole;
