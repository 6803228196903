import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import ContractualInformation from 'components/Tables/DataEntry/ContractualInformation/ContractualInformation/ContractualInformation';

const steps = [
  {
    title: 'Contractual Information',
    content: <ContractualInformation />,
  },
];

const ContractualInformationSteps: React.FC = () => {
  return <StepComponent steps={steps} title="Contractual Information Steps" />;
};

export default ContractualInformationSteps;
