import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  contractual_arangement_ref_num: string;
  identification_code: string;
  type_of_code: string;
}

interface B0302Props {
  entityName: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
      <p>Contractual arrangement reference number - <span>B.03.02.0010</span></p>
        <AdditionalInformation description="As reported in B_02.02.0010
Identify the contractual arrangement reference number signed by the ICT third-party service provider
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'contractual_arangement_ref_num',
    key: 'contractual_arangement_ref_num',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
      <p> Identification code of ICT third-party service provider - <span>B.03.02.0020</span></p>
        <AdditionalInformation description="As reported in B_05.01.0010
Code to identify the ICT third-party service provider as reported in B_05.01.0020 for that provider.
(alphanumerical)
" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'identification_code',
    key: 'identification_code',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
      <p> Type of code to identify the ICT third-party service provider - <span>B.03.02.0030</span></p>
        <AdditionalInformation description="As reported in B_05.01.0020
Type of code to identify the ICT third-party service provider in B_03.02.0020 as reported in B_05.01.0020 for that provider.
(pattern)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_code',
    key: 'type_of_code',
  },
];

const B0302: React.FC<B0302Props> = ({ entityName }) => {
  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchRT0302(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          contractual_arangement_ref_num:
            item['Contractual arrangement ref. number'],
          identification_code: item['ICT service provider code'],
          type_of_code: item['Type of ICT service provider code'],
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0302;
