import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { notification } from 'antd';
import qs from 'qs';
import { environment } from 'environments/environment';

const authToken = localStorage.getItem('authToken');

const AxiosInstance = axios.create({
  baseURL: environment.serverUrl,
  headers: {
    Authorization: authToken ? `Bearer ${authToken}` : '',
    'Content-Type': 'application/json',
  },
});

AxiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config.paramsSerializer = (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    };

    return config;
  },
  async (error) => {
    console.error('Request Error: ', error);
    notification.error({
      message: 'error.message',
      description: 'In Request Error',
      duration: 20,
    });

    return Promise.reject(error);
  },
);

AxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    const { status, statusText, data } = error.response || {};
    if (status === 207) {
      notification.warning({
        message: status,
        description: data.error,
        duration: 20,
      });
    }
    if (status === 400) {
      notification.warning({
        message: `${status} ${data.message}`,
        description: data.error,
        duration: 20,
      });
    }

    if (status === 401) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('role');
      window.location.href = '/';
      notification.error({
        message: `${status} ${statusText}`,
        description: data.message,
        duration: 20,
      });
    }

    // if (status === 404) {
    //   console.log(status, 'status');
    //   console.log(data, 'data');
    //   notification.warning({
    //     message: `Warning: ${status}`,
    //     description: data.error ? data.error : data.message,
    //     duration: 20,
    //   });
    // }

    if (status === 500) {
      notification.error({
        message: `Server is facing issues: ${data.details}`,
        description: error.response.data.error,
        duration: 20,
      });
    }

    return Promise.reject(error);
  },
);

export default AxiosInstance;
