import reportingImage from '../assets/img/u1496137188_Paper_Report_--v_6.1_5072ae31-1489-49d7-b8d7-ea94d37fe7c8_0.png';
import dataEntryImage from '../assets/img/man_typing.png';
import dataEntryAreas from '../assets/img/dta_entry_areas.png';
import additionalReports from '../assets/img/additional_reports.webp';

const workingAreaImages = {
  reporting: reportingImage,
  dataEntry: dataEntryImage,
  dataEntryAreas: dataEntryAreas,
  additionalReports: additionalReports,
};

export default workingAreaImages;
