import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import CardComponent from 'components/CardComponent';
import workingAreas from 'constants/WorkingAreas';
import TitleBar from 'components/TitleBar';
import { TitleList } from 'store/ducks/globalTypes';
import * as Styled from './WorkingAreas.styled';

const WorkingAreasPage = () => {
  const areas = workingAreas();
  const navigate = useNavigate();

  const handleSignUp = async () => {
    navigate('/sign_up');
  };
  const roles = localStorage.getItem('role') || '[]';
  const isAdmin = roles.includes('Admin');

  return (
    <Layout>
      <TitleBar title={TitleList.WORKING_AREAS} />
      <Styled.Container>
        <Styled.SignUpContainer>
          {isAdmin && (
            <Styled.StyledButton type="primary" onClick={handleSignUp}>
              Sign up
            </Styled.StyledButton>
          )}
        </Styled.SignUpContainer>
        <Styled.GridContainer>
          {areas.map((item: any) => (
            <CardComponent
              key={item.area}
              area={item.area}
              infoText={item.infoText}
              imgSrc={item.imgSrc}
              href={item.href}
            />
          ))}
        </Styled.GridContainer>
      </Styled.Container>
    </Layout>
  );
};

export default WorkingAreasPage;
