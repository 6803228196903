import React from 'react';
import { Input, Form, theme, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import AuthLayout from 'components/Layout/AuthLayout/AuthLayout';
import { globalOp } from 'store/ducks/Global';
import { IChangePass } from 'store/ducks/globalTypes';
import changePassInputs from 'constants/changePassInputs';
import * as Styled from './ChangePassword.styled';

const { useToken } = theme;

const ChangePassword: React.FC = () => {
  const { token } = useToken();
  const authToken = localStorage.getItem('authToken');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handleSubmit = async (values: IChangePass) => {
    try {
      if (!authToken) {
        notification.error({
          message: 'Error',
          description: 'You must be logged in or provide a valid reset token.',
          duration: 20,
        });
        return;
      }

      const userToken = authToken;

      const response = await globalOp.changePass(values, userToken);

      notification.success({
        message: 'Success',
        description: 'Your password has been updated successfully.',
        duration: 20,
      });

      navigate('/');
    } catch (error) {
      notification.error({
        message: 'Error',
        description:
          'There was an issue updating your password. Please try again later.',
        duration: 20,
      });
      console.error('Error during password change:', error);
    }
  };

  return (
    <AuthLayout>
      <Form form={form} name="change_pass" onFinish={handleSubmit}>
        {changePassInputs.map((item) => (
          <Styled.StyledFormItem
            key={item.label}
            token={token}
            label={item.label}
            name={item.name}
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
            ]}
          >
            <Input.Password />
          </Styled.StyledFormItem>
        ))}
        <Styled.ButtonContainer>
          <Styled.StyledButton
            type="primary"
            htmlType="submit"
            disabled={!authToken}
          >
            Submit
          </Styled.StyledButton>
        </Styled.ButtonContainer>
      </Form>
    </AuthLayout>
  );
};

export default ChangePassword;
