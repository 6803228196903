import { IThirdPartyProviderState } from './types';

const initialThirdPartyProviderState = (): IThirdPartyProviderState => ({
  loading: false,
  providerNames: [],
  selectedTPP: '',
  isAddRowTPP: false,
  addRowCountTpp: 0,
  tpps: [],
  nonSubstReason: [],
  personType: [],
  currencyAndCosts: [],
  certificates: [],
  isTppAdded: false,
  isNonSubstReasonAdded: false,
  isPersonTypeAdded: false,
  isCurrencyAndCostsAdded: false,
  isCertificateAdded: false,
  isAddRowTppFilled: false,
  isTppUpdated: false,
  tppId: 0,
  nameOfTpp: '',
});

export default initialThirdPartyProviderState;
