import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isAdditionalDisplayed } from 'store/ducks/Reportings/reportingSlice';

interface DataType {
  key: React.Key;
  entity_name: string;
  function_description: string;
  function_level: number;
  function_name: string;
  ict_service_level: string;
  ict_service_provider_name: string;
}

interface AR0101Props {
  entityName: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Name of the entity',
    width: 100,
    dataIndex: 'entity_name',
    key: 'entity_name',
    fixed: 'left',
  },
  {
    title: 'Functions',
    width: 100,
    dataIndex: 'function_name',
    key: 'function_name',
  },
  {
    title: 'Function name description',
    width: 100,
    dataIndex: 'function_description',
    key: 'function_description',
  },
  {
    title: 'Function level',
    width: 100,
    dataIndex: 'function_level',
    key: 'function_level',
    fixed: 'left',
  },
  {
    title: 'Third Party ICT Services',
    width: 100,
    dataIndex: 'ict_service_provider_name',
    key: 'ict_service_provider_name',
  },
  {
    title: 'ICT service level',
    width: 100,
    dataIndex: 'ict_service_level',
    key: 'ict_service_level',
  },
];

const AR0101: React.FC<AR0101Props> = ({ entityName }) => {
  const { date, is_additional_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isAdditionalDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchAR0101(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          entity_name: item.entity_name,
          function_description: item.function_description,
          function_level: item.function_level,
          function_name: item.function_name,
          ict_service_level: item.ict_service_level,
          ict_service_provider_name: item.ict_service_provider_name,
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_additional_displayed]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default AR0101;
