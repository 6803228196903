import React from 'react';
import * as Styled from './AuthLayout.styled';

interface IAuthLayoutProps {
  children: JSX.Element;
}

const AuthLayout: React.FC<IAuthLayoutProps> = ({ children }) => (
  <Styled.StyledContainer>
    <Styled.FormContainer>{children}</Styled.FormContainer>
  </Styled.StyledContainer>
);

export default AuthLayout;
