import React from 'react';
import StepComponent from 'components/StepComponent/CustomSteps';
import ThirdPartyServiceProvider from 'components/Tables/DataEntry/TPP/ThirdPartyServiceProvider/ThirdPartyServiceProvider';
import NonSubstReason from 'components/Tables/DataEntry/TPP/NonSubstReason/NonSubstReason';
import PersonType from 'components/Tables/DataEntry/TPP/PersonType/PersonType';
import CurrencyAndCosts from 'components/Tables/DataEntry/TPP/CurencyAndCosts/CurencyAndCosts';

const TPPSteps: React.FC = () => {
  const steps = [
    {
      title: 'ThirdPartyServiceProvider',
      content: <ThirdPartyServiceProvider />,
    },
    { title: 'NonSubstReason', content: <NonSubstReason /> },
    { title: 'PersonType', content: <PersonType /> },
    { title: 'CurrencyAndCosts', content: <CurrencyAndCosts /> },
  ];

  return (
    <div>
      <StepComponent steps={steps} title="Entity Steps" />
    </div>
  );
};

export default TPPSteps;
