import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { generateExcelData } from '../../constants/excelData';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import RT0203 from 'components/Tables/Reporting/ B0203/B0203';
import RT0103 from 'components/Tables/Reporting/B0103/B0103';
import RT0301 from 'components/Tables/Reporting/B0301/B0301';
import RT0303 from 'components/Tables/Reporting/B0303/B0303';
import RT0302 from 'components/Tables/Reporting/B0302/B0302';
import RT0401 from 'components/Tables/Reporting/B0401/B0401';
import RT0101 from 'components/Tables/Reporting/B0101/B0101';
import RT0102 from 'components/Tables/Reporting/B0102/B0102';
import RT0201 from 'components/Tables/Reporting/B0201/B0201';
import RT0202 from 'components/Tables/Reporting/B0202/B0202';
import RT0501 from 'components/Tables/Reporting/B0501/B0501';
import RT0502 from 'components/Tables/Reporting/B0502/B0502';
import RT0601 from 'components/Tables/Reporting/B0601/B0601';
import RT0701 from 'components/Tables/Reporting/B0701/B0701';
import { reportingOp } from 'store/ducks/Reportings';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';

export interface ReportModalProps {
  title: string;
  tableNumber: string;
}

const ReportModal: React.FC<ReportModalProps> = ({ title, tableNumber }) => {
  const { date } = useSelector((state: RootState) => state.reportings);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const entityName =
    typeof queryParams.entity_name === 'string' ? queryParams.entity_name : '';
  const showModal = () => {
    setOpen(true);
  };
  useEffect(() => {
    console.log(entityName, 'entityName');
  }, [entityName]);

  const fetchDataByTableNumber = async (tableNumber: string, params: any) => {
    switch (tableNumber) {
      case 'B_01.03':
        return await reportingOp.fetchBranches(params);
      case 'B_02.03':
        return await reportingOp.fetchEntityContractualArrangements(params);
      case 'B_03.01':
        return await reportingOp.fetchContractualArrangementsLei(params);
      case 'B_03.03':
        return await reportingOp.fetchContractualArrangementsICT(params);
      case 'B_04.01':
        return await reportingOp.fetchContractualArrangementsICT(params);
      case 'B_01.01':
        return await reportingOp.fetchRT0101(params);
      case 'B_01.02':
        return await reportingOp.fetchRT0102(params);
      case 'B_02.01':
        return await reportingOp.fetchRT0201(params);
      case 'B_02.02':
        return await reportingOp.fetchRT0202(params);
      case 'B_03.02':
        return await reportingOp.fetchRT0302(params);
      case 'B_05.01':
        return await reportingOp.fetchRT0501(params);
      case 'B_05.02':
        return await reportingOp.fetchRT0502(params);
      case 'B_06.01':
        return await reportingOp.fetchRT0601(params);
      case 'B_07.01':
        return await reportingOp.fetchRT0701(params);
      default:
        throw new Error('Invalid table number');
    }
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const params = {
        entity_name: queryParams.entity_name,
        date,
      };
      const fetchedData = await fetchDataByTableNumber(tableNumber, params);

      const { wb, title: excelTitle } = generateExcelData(fetchedData, title);
      XLSX.writeFile(wb, `${excelTitle}.xlsx`);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {title}
      </Button>
      <Modal
        open={open}
        centered
        width={1500}
        title={title}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          entityName && (
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOk}
              disabled
            >
              <DownloadOutlined />
              Download
            </Button>
          ),
        ]}
      >
        {title === 'B_02.03' ? (
          <RT0203 entityName={entityName} />
        ) : title === 'B_01.03' ? (
          <RT0103 entityName={entityName} />
        ) : title === 'B_03.01' ? (
          <RT0301 entityName={entityName} />
        ) : title === 'B_03.02' ? (
          <RT0302 entityName={entityName} />
        ) : title === 'B_03.03' ? (
          <RT0303 entityName={entityName} />
        ) : title === 'B_04.01' ? (
          <RT0401 entityName={entityName} />
        ) : title === 'B_01.01' ? (
          <RT0101 entityName={entityName} />
        ) : title === 'B_01.02' ? (
          <RT0102 entityName={entityName} />
        ) : title === 'B_02.01' ? (
          <RT0201 entityName={entityName} />
        ) : title === 'B_02.02' ? (
          <RT0202 entityName={entityName} />
        ) : title === 'B_03.02' ? (
          <RT0202 entityName={entityName} />
        ) : title === 'B_05.01' ? (
          <RT0501 entityName={entityName} />
        ) : title === 'B_05.02' ? (
          <RT0502 entityName={entityName} />
        ) : title === 'B_06.01' ? (
          <RT0601 entityName={entityName} />
        ) : title === 'B_07.01' ? (
          <RT0701 entityName={entityName} />
        ) : null}
      </Modal>
    </>
  );
};

export default ReportModal;
