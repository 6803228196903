import { AliasToken } from 'antd/es/theme/interface';

export interface IToken {
  token: AliasToken;
}

export interface IFilters {
  filter: string;
  options: ISelectionOption[];
}

export interface ISelectionOption {
  value: string;
  label: string;
  id: number;
  name_of_entity: string;
}

export interface IResponseFilters {
  ent?: number;
}

export enum ThemeList {
  MAIN_THEME = 'mainTheme',
  FIRST_THEME = 'firstTheme',
  SECOND_THEME = 'secondTheme',
  THIRD_THEME = 'thirdTheme',
  FOURTH_THEME = 'fourthTheme',
  FIFTH_THEME = 'fifthTheme',
  SIXTH_THEME = 'sixthTheme',
  SEVENTH_THEME = 'seventhTheme',
  EIGHTH_THEME = 'eighthTheme',
}

export interface ICustomMenuItem {
  label: JSX.Element;
  key: string;
  path: string;
}

export enum TitleList {
  WORKING_AREAS = 'Working areas',
  INFO_REG = 'ProgRisCom',
  DATA_ENTRY = 'ProgRisCom - Data Entry Areas',
  REPORTING = 'ProgRisCom - Reporting',
}

export enum TitleDataEntry {
  ENTITY = 'Entity',
  THIRD_PARTY_PROVIDER = 'Third Party Provider',
  FUNCTION = 'Function',
  ICT_SERVICES = 'ICT Services',
  CONTRACTUAL_INFORMATION = 'Contractual Information',
  BRANCH = 'Branch',
}

export interface IChangePass {
  old_password: string;
  new_password: string;
}

export interface IResetPass {
  password: string;
}
