import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { Button, DatePicker, notification, Modal } from 'antd';
import CustomTable, {
  DataType,
  EditableColumnType,
} from 'components/CustomTable/CustomTable';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { IEntityTitle } from 'store/ducks/DataEntry/Entities/types';
import {
  set_addRowCount,
  set_entities,
  set_isAddRow,
  set_isEntityAdded,
  set_nameOfEntity,
  set_idOfEntity,
} from 'store/ducks/DataEntry/Entities/entitySlice';
import dayjs from 'dayjs';
import useEntityStatus from 'utils/hooks/useEntityStatus';
import CustomSelect from 'components/CustomSelect';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import useRole from 'utils/hooks/useRoles';
import * as Styled from '../../DataEntry.styled';
import { globalOp } from 'store/ducks/Global';

const Entity: React.FC = () => {
  useEntityStatus();
  const { query } = useFilters();
  const { confirm } = Modal;
  const { canCreate, canEdit, canDelete } = useRole();
  const [dataSource, setDataSource] = useState<IEntityTitle[]>([]);
  const [initialData, setInitialData] = useState<IEntityTitle[]>([]);
  const [combinedData, setCombinedData] = useState<IEntityTitle[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [entityTypes, setEntityTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [hierarchyInGroups, setHierarchyInGroups] = useState<
    { label: string; value: string }[]
  >([]);
  const [natures, setNatures] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [competentAuthorities, setCompetentAuthorities] = useState<
    { label: string; value: string }[]
  >([]);
  const [currencies, setCurrencies] = useState<
    { label: string; value: string }[]
  >([]);
  const [countries, setCountries] = useState<
    { label: string; value: string }[]
  >([]);
  const [pageSize, setPageSize] = useState(10);
  // const [canAddRow, setCanAddRow] = useState(true);
  const { addRowCount, entities = [] } = useSelector(
    (state: RootState) => state.entities,
  );
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem('authToken');

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    let combined: IEntityTitle[] = [];

    if (
      entities.length > 0 &&
      dataSource.length > 0 &&
      query.entity !== undefined
    ) {
      combined = [...entities, ...dataSource];
    } else if (entities.length < 1 && dataSource.length > 0) {
      combined = [...dataSource];
    } else if (dataSource.length < 1 && entities.length > 0) {
      combined = [...entities];
    }

    combined = combined.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.id === value.id),
    );

    setCombinedData(combined);
  }, [dataSource, entities, query.entity]);

  useEffect(() => {
    if (entities && entities.length > 0) {
      dispatch(set_entities(entities));
      dispatch(set_addRowCount(1));
    }
  }, [entities]);

  const handleSave = (row: IEntityTitle) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setDataSource(updatedDataSource);
      entities.length < 1
        ? setCombinedData(updatedDataSource)
        : setCombinedData([...entities]);
    } else {
      const updatedEntity = entities.map((item) => {
        if (item.key === row.key) {
          if (row.isNew) {
            dispatch(set_idOfEntity(row.id));
            dispatch(set_nameOfEntity(row.name_of_entity));
            // dispatch(set_nameOfEntityType(row.name_of_entity));
            // dispatch(set_nameOfEntity(row.name_of_entity));
            // dispatch(set_nameOfEntity(row.name_of_entity));
            // dispatch(set_nameOfEntity(row.name_of_entity));
            // dispatch(set_nameOfEntity(row.name_of_entity));
          }
          return { ...item, ...row };
        }
        return item;
      });
      entities.length < 1
        ? setCombinedData([...dataSource])
        : setCombinedData([...updatedEntity]);

      dispatch(set_entities(updatedEntity));
    }
  };

  let uniqueIdCounter = 0;

  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now() % 1_000_000_000;
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId =
      (timestampPart * 1000 + randomPart + uniqueIdCounter) % 2_147_483_648;

    return uniqueId;
  };
  
  const fetchEntityType = async () => {
    try {
      const response = await entityOp.fetchEntityTypes();
      if (response?.data.entity_types) {
        const formattedOptions = response.data.entity_types.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setEntityTypes(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHierarchyInGroups = async () => {
    try {
      const response = await entityOp.fetchHierarchyInGroup();
      if (response?.data.hierarchy_groups) {
        const formattedOptions = response.data.hierarchy_groups.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setHierarchyInGroups(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNatures = async () => {
    try {
      const response = await entityOp.fetchNatureID();
      if (response?.data.nature_of_entities) {
        const formattedOptions = response.data.nature_of_entities.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setNatures(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCompetentAuthorities = async () => {
    try {
      const response = await entityOp.fetchCompetentAuthorities();
      if (response?.data.competent_authorities) {
        const formattedOptions = response.data.competent_authorities.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setCompetentAuthorities(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await entityOp.fetchCurrencies();
      if (response?.data.currencies) {
        const formattedOptions = response.data.currencies.map(
          (reasons: { currency_name: any; id: any }) => ({
            label: reasons.currency_name,
            value: reasons.id,
          }),
        );
        setCurrencies(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await entityOp.fetchCountries();
      if (response?.data.countries) {
        const formattedOptions = response.data.countries.map(
          (reasons: { country_name: any; id: any }) => ({
            label: reasons.country_name,
            value: reasons.id,
          }),
        );
        setCountries(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRow = (record?: any, index?: number) => {
    // if (!canAddRow) {
    //   notification.info({
    //     message: 'Row already added',
    //     description: 'Please submit to add a new entity',
    //     duration: 20,
    //   });
    //   return;
    // }

    dispatch(set_isAddRow(true));

    const newData: IEntityTitle = {
      key: generateUniqueId(),
      id: generateUniqueId(),
      lei_entity_maintaining_register_information: '',
      name_of_entity: '',
      entity_type_id: '',
      lei_of_entity: '',
      hierarchy_in_group_id: '',
      parent_lei: '',
      lei_entity_making_use_ICT_service: '',
      lei_entity_signing_contractual_arrangement: '',
      nature_id: '',
      integration_date: '',
      deletion_date: '',
      total_assets: 0,
      lei_financial_entity: '',
      description: '',
      competent_authority_id: '',
      country_id: '',
      date_of_last_update: '',
      end_date: '',
      reporting_date: '',
      start_date: '',
      currency_id: '',
      name_of_financial_entity: '',
      name_of_entity_maintaining_register_information: '',
      isNew: true,
    };
    const savedDataSource =
      entities.length > 0 ? [...entities] : [...dataSource];

    let updatedDataSource = [...savedDataSource];
    if (record) {
      const insertIndex = updatedDataSource.findIndex(
        (item) => item.key === record.key,
      );
      updatedDataSource = [
        ...updatedDataSource.slice(0, insertIndex + 1),
        newData,
        ...updatedDataSource.slice(insertIndex + 1),
      ];
    } else {
      updatedDataSource.push(newData);
    }

    setDataSource(updatedDataSource);
    dispatch(set_entities(updatedDataSource));

    // setCanAddRow(false);
  };

  const fetchEntity = async () => {
    try {
      const response = await entityOp.fetchEntitiesByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data;
      const mappedData = [
        {
          key: data.entity_id,
          id: data.entity_id,
          active: data.active,
          lei_entity_maintaining_register_information:
            data.lei_entity_maintaining_register_information || '',
          name_of_entity: data.entity_name || '',
          entity_type_id: data.entity_type_id || undefined,
          lei_of_entity: data.lei_of_entity || '',
          hierarchy_in_group_id: data.hierarchy_in_group_id || undefined,
          parent_lei: data.parent_lei || '',
          lei_entity_making_use_ICT_service:
            data.lei_entity_making_use_ICT_service || '',
          lei_entity_signing_contractual_arrangement:
            data.lei_entity_signing_contractual_arrangement || '',
          nature_id: data.nature_id || undefined,
          integration_date: data.integration_date || '',
          deletion_date: data.deletion_date || '',
          total_assets: data.total_assets || 0,
          lei_financial_entity: data.lei_financial_entity || '',
          description: data.description || '',
          competent_authority_id: data.competent_authority_id || undefined,
          country_id: data.country_id || undefined,
          date_of_last_update: data.date_of_last_update || '',
          end_date: data.end_date || '',
          reporting_date: data.reporting_date || '',
          start_date: data.start_date || '',
          currency_id: data.currency_id || undefined,
          name_of_financial_entity: data.name_of_financial_entity || '',
          name_of_entity_maintaining_register_information:
            data.name_of_entity_maintaining_register_information || '',
          isNew: false,
          isEdited: false,
        },
      ];
      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchEntity();
      fetchEntityType();
      fetchHierarchyInGroups();
      fetchNatures();
      fetchCompetentAuthorities();
      fetchCurrencies();
      fetchCountries();
    } else {
      if (entities) {
        // dispatch(set_entities([]));
        fetchEntityType();
        fetchHierarchyInGroups();
        fetchNatures();
        fetchCompetentAuthorities();
        fetchCurrencies();
        fetchCountries();
        dispatch(set_addRowCount(0));
      }
    }
  }, [query]);

  const areAllFieldsFilled = (entities: IEntityTitle[]) => {
    return entities.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = entities.filter((item) => item.key !== key);
        setDataSource(updatedDataSource);
        dispatch(set_entities(updatedDataSource));
        dispatch(set_addRowCount(0));
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: IEntityTitle) => {
    if (record.id === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The entity ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the entity with ID ${record.id}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await entityOp.inactivateEntity([record.id]);
          fetchEntity();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  useEffect(() => {
    if (addRowCount === 1) {
      const allFieldsFilled = areAllFieldsFilled(entities);
      if (allFieldsFilled) {
        dispatch(set_isEntityAdded(true));
      } else {
        dispatch(set_isEntityAdded(false));
      }
    }
  }, [entities, addRowCount]);

  const handleSubmitEntity = async () => {
    
    const updatedData = dataSource.map((item) => ({
      ...item,
      total_assets: Number(item.total_assets),
    }));
    if (JSON.stringify(updatedData) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (entities.length !== 0) {
      console.log(entities, 'entities');
      const allFieldsFilled = areAllFieldsFilled(entities);
      if (!allFieldsFilled) {
        // notification.warning({
        //   message: 'Not submitted',
        //   description: 'Please fill in all the required fields in Entity.',
        //   placement: 'topRight',
        //   duration: 20,
        // });
        // return;
      } else {
        dispatch(set_isEntityAdded(true));
      }
    } else {
      //  setCanAddRow(true);
    }
    try {
      const response = await entityOp.fetchEntity(
        [...updatedData],
        isLoggedIn ?? '',
      );
      dispatch(set_entities([]));
      fetchEntity();
      dispatch(globalOp.setFilters() as any);  
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType[] = [
    {
      title: (
        <Styled.TitleComponent>
          LEI Entity Maintaining Register Information
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation description="Identify the financial entity maintaining and updating the register of information using the LEI, 20-character, alpha- numeric code based on the ISO 17442 standard (alphanumerical)" />
        </Styled.TitleComponent>
      ),
      dataIndex: 'lei_entity_maintaining_register_information',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          LEI financial entity
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation description="Identify the financial entity maintaining and updating the register of information using the LEI, 20-character, alpha- numeric code based on the ISO 17442 standard (alphanumerical)" />
        </Styled.TitleComponent>
      ),
      dataIndex: 'lei_financial_entity',
      editable: true,
    },
    {
      title: (
        <>
          Entity name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'name_of_entity',
      editable: true,
      fixed: 'left',
    },
    {
      title: (
        <Styled.TitleComponent>
          LEI of the financial entity
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="As reported in B_04.01.0020
Identify the financial entity using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'lei_of_entity',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          LEI of the direct parent undertaking of the financial entity
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the direct parent undertaking of the financial entity reported in the register of information using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'parent_lei',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          LEI of the financial entity making use of the ICT service(s)
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the financial entity making use of the ICT service(s) using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'lei_entity_making_use_ICT_service',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          LEI of the entity signing the contractual arrangement
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the undertaking signing the contractual arrangement using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard or the EUID.
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'lei_entity_signing_contractual_arrangement',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          Date of integration in the register of information
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of integration of the financial entity into the register of information
(date)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'integration_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                const updatedRow: IEntityTitle = {
                  ...entityRecord,
                  integration_date: formattedDateString,
                };
                handleSave(updatedRow);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Date of deletion in the register of information
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of deletion of the financial entity from the register of information.
Where the financial entity has not been deleted, ‘9999-12-31’ shall be reported.
(date)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'deletion_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = dayjs(date).format('YYYY-MM-DD');
              const updatedRow: IEntityTitle = {
                ...entityRecord,
                deletion_date: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },
    // {
    //   title: (
    //     <>
    //       Value of total assets of the financial entity{' '}
    //       <Styled.Asterisx>*</Styled.Asterisx>
    //     </>
    //   ),
    //   dataIndex: 'total_assets',
    //   editable: true,
    // },
    {
      title: (
        <>
          Value of total assets of the financial entity{' '}
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'total_assets',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          Name of Financial entity
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation description="Legal name of the financial entity maintaining and updating the register of information (alphanumerical)" />
        </Styled.TitleComponent>
      ),
      dataIndex: 'name_of_financial_entity',
      editable: true,
    },
    {
      title: (
        <>
          Entity maintaining register information{' '}
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'name_of_entity_maintaining_register_information',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          Date of last update
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of the last update or modification made in the register of information in relation to the financial entity.
(date)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'date_of_last_update',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = dayjs(date).format('YYYY-MM-DD');
              const updatedRow: IEntityTitle = {
                ...entityRecord,
                date_of_last_update: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },
    {
      title: (
        <>
          Start date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'start_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = dayjs(date).format('YYYY-MM-DD');
              const updatedRow: IEntityTitle = {
                ...entityRecord,
                start_date: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },
    {
      title: (
        <>
          End date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'end_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = dayjs(date).format('YYYY-MM-DD');
              const updatedRow: IEntityTitle = {
                ...entityRecord,
                end_date: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },

    {
      title: (
        <Styled.TitleComponent>
          Date of the reporting
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of reporting (date) (mandatory in case of reporting)" />
        </Styled.TitleComponent>
      ),
      dataIndex: 'reporting_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as IEntityTitle;
        return (
          <DatePicker
            value={text ? dayjs(text) : null}
            format="YYYY-MM-DD"
            onClick={(e) => e.stopPropagation()}
            onChange={(date) => {
              const formattedDateString = dayjs(date).format('YYYY-MM-DD');
              const updatedRow: IEntityTitle = {
                ...entityRecord,
                reporting_date: formattedDateString,
              };
              handleSave(updatedRow);
            }}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Type of financial entity
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the type of financial entity using one of the options in the following closed list:
              1.	credit institutions;
              2.	payment institutions, including payment institutions exempted pursuant to Directive
              (EU) 2015/2366 of the European Parliament and of the Council (1);
              3.	account information service providers;
              4.	electronic money institutions, including electronic money institutions exempted pursuant to Directive 2009/110/EC of the European Parliament and of the Council (2);
              5.	investment firms;
              6.	crypto-asset service providers authorised under Regulation (EU) 2023/1114 of the European Parliament and of the Council (3)
              7.	issuers of asset-referenced tokens authorised under Regulation (EU) 2023/1114;
              8.	central securities depositories;
              9.	central counterparties;
              10.	trading venues;
              11.	trade repositories;
              12.	managers of alternative investment funds;
              13.	management companies;
              14.	data reporting service providers;
              15.	insurance and reinsurance undertakings;
              16.	insurance	intermediaries,	reinsurance intermediaries and ancillary insurance intermediaries;
              17.	institutions for occupational retirement provision;
              18.	credit rating agencies;
              19.	administrators of critical benchmarks;
              20.	crowdfunding service providers;
              21.	securitisation repositories.
              22.	other financial entity;
              Where the register of information is maintained at the group level by the parent undertaking, which is not itself subject to the obligation to maintain such register, i.e. it does not fall under the definition of financial entities set out in Article 2 of the Regulation (EU) 2022/2554 (e.g., financial holding company, mixed financial holding company or mixed-activity holding company) ‘Other financial entity’ option shall be chosen.

              (closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'entity_type_id',
      editable: false, 
      render: (_, record) => {
        return (
          <CustomSelect
          options={entityTypes}
          value={record.entity_type_id}
          onChange={(value: any) => {       
            handleSave({
              ...record,
              entity_type_id: value,
            } as IEntityTitle);
          }}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
        )
      }
    },
    {
      // title: (
      //   <>
      //     Hierarchy In Group<Styled.Asterisx>*</Styled.Asterisx>
      //   </>
      // ),
      title: (
        <Styled.TitleComponent>
            Hierarchy of the financial entity within the group
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Determine the hierarchy of the financial entity in the consolidation using one of the options in the following closed list:
1.	The financial entity is the ultimate parent undertaking in the consolidation;
2.	The financial entity is the parent undertaking of a sub-consolidated part in the consolidation;
3.	The financial entity is a subsidiary in the consolidation and is not a parent undertaking of a sub-consolidated part;
4.	The financial entity is not part of a group;
5.	The financial entity is a service provider to which the financial entity (or the third- party service provider acting on its behalf) is outsourcing all its operational activities.
Where an entity fulfils more than one options from the closed list above, the higher- level option applicable to this entity shall be selected.
(closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'hierarchy_in_group_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={hierarchyInGroups}
          value={record.hierarchy_in_group_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              hierarchy_in_group_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <>
           Nature of the financial entity<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'nature_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={natures}
          value={record.nature_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              nature_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
      <Styled.TitleComponent>
          Competent Authority
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation description="Identify the competent authority referred to in Article 46 of Regulation (EU) 2022/2554 to which the register of information is reported. (alphanumerical) (mandatory in case of reporting)." />
        </Styled.TitleComponent>
      ),
      dataIndex: 'competent_authority_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={competentAuthorities}
          value={record.competent_authority_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              competent_authority_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          Currency
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the ISO 4217 alphabetic code of the currency used for the preparation of the financial entity’s financial statements.
The currency reported shall be the same currency used by the financial entity for the preparation of the financial statements at entity, sub-consolidated or consolidated level, as applicable.
(currency)
(mandatory only if B_01.02.0110 is reported)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'currency_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={currencies}
          value={record.currency_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              currency_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          Country of the financial entity
          <Styled.Asterisx>*</Styled.Asterisx>
         
        </Styled.TitleComponent>
      ),
      dataIndex: 'country_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={countries}
          value={record.country_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              country_id: value,
            } as IEntityTitle)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button
              danger
              onClick={() => handleInactivate(record as IEntityTitle)}
            >
              Soft delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    dataSource && (
      <div>
        <Styled.AreaButton type="primary" onClick={handleAddRow}>
          Add Row
        </Styled.AreaButton>
        <CustomTable
          columns={columns}
          dataSource={dataSource}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        <Styled.AreaButton type="primary" onClick={handleSubmitEntity}>
            Submit
          </Styled.AreaButton>
         {/* {canCreate() &&  <Styled.AreaButton type="primary" onClick={handleSubmitEntity}>
            Submit
          </Styled.AreaButton>} */}
      </div>
    )
  );
};

export default Entity;
