import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  country_name: string;
  currency: string;
  date_of_last_update: string;
  deletion_date: string;
  entity_name: string;
  entity_type: string;
  hierarchy_in_group: string;
  integration_date: string;
  lei_entity: string;
  parent_lei: string;
  total_assets: string;
}

interface B0102Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>LEI of the financial entity - <span>B.01.02.0010</span></p>
        <AdditionalInformation description="Identify the financial entity reported in the register of information using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'lei_entity',
    key: 'lei_entity',
    fixed: 'left',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
      <p> Name of the financial entity - <span>B.01.02.0020</span></p>
        <AdditionalInformation description="Legal name of the financial entity reported in the register of information
(alphanumerical)." />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'entity_name',
    key: 'entity_name',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Country of the financial entity - <span>B.01.02.0030</span></p>
        <AdditionalInformation description="Identify the ISO 3166–1 alpha–2 code of the country where the license or the registration of the financial entity reported in the register of information has been issued.
(country)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'country_name',
    key: 'country_name',
    fixed: 'left',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>Type of financial entity - <span>B.01.02.0040</span></p>
        <AdditionalInformation description="Identify the type of financial entity using one of the options in the following closed list:
1.	credit institutions;
2.	payment institutions, including payment institutions exempted pursuant to Directive (EU) 2015/2366;
3.	account information service providers;
4.	electronic money institutions, including electronic money institutions exempted pursuant to Directive 2009/110/EC;
5.	investment firms;
6.	crypto-asset service providers authorised under Regulation (EU) 2023/1114;
7.	issuers of asset-referenced tokens authorised under Regulation (EU) 2023/1114;
8.	central securities depositories;
9.	central counterparties;
10.	trading venues;
11.	trade repositories;
12.	managers of alternative investment funds;
13.	management companies;
14.	data reporting service providers;
15.	insurance and reinsurance undertakings;
16.	insurance intermediaries, reinsurance intermediaries and ancillary insurance intermediaries;
17.	institutions for occupational retirement provision;
18.	credit rating agencies;
19.	administrators of critical benchmarks;
20.	crowdfunding service providers;
21.	securitisation repositories;
22.	other financial entity;
23.	non-financial entity: ICT intra-group service provider;
24.	non-financial entity: other.
(closed set of options)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'entity_type',
    key: 'entity_type',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Hierarchy of the financial entity within the group (where applicable) - <span>B.01.02.0050</span></p>
        <AdditionalInformation description="Determine the hierarchy of the financial entity in the consolidation using one of the options in the following closed list:
1.	The financial entity is the ultimate parent undertaking in the consolidation;
2.	The financial entity is the parent undertaking of a sub-consolidated part in the consolidation;
3.	The financial entity is a subsidiary in the consolidation and is not a parent undertaking of a sub-consolidated part;
4.	The financial entity is not part of a group;
5.	The financial entity is a service provider to which the financial entity (or the third- party service provider acting on its behalf) is outsourcing all its operational activities.
Where an entity fulfils more than one options from the closed list above, the higher- level option applicable to this entity shall be selected.
(closed set of options)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'hierarchy_in_group',
    key: 'hierarchy_in_group',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        pLEI of the direct parent undertaking of the financial entity - <span>B.01.02.0060</span>
        <AdditionalInformation description="Identify the direct parent undertaking of the financial entity reported in the register of information using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'parent_lei',
    key: 'parent_lei',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Date of last update - <span>B.01.02.0070</span></p>
        <AdditionalInformation description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of the last update or modification made in the register of information in relation to the financial entity.
(date)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'date_of_last_update',
    key: 'date_of_last_update',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Date of integration in the Register of information - <span>B.01.02.0080</span></p>
        <AdditionalInformation description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of integration of the financial entity into the register of information
(date)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'integration_date',
    key: 'integration_date',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Date of deletion in the register of information - <span>B.01.02.0090</span></p>
        <AdditionalInformation description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of deletion of the financial entity from the register of information.
Where the financial entity has not been deleted, ‘9999-12-31’ shall be reported.
(date)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'deletion_date',
    key: 'deletion_date',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Currency - <span>B.01.02.0100</span></p>
        <AdditionalInformation description="Identify the ISO 4217 alphabetic code of the currency used for the preparation of the financial entity’s financial statements.
The currency reported shall be the same currency used by the financial entity for the preparation of the financial statements at entity, sub-consolidated or consolidated level, as applicable.
(currency)
(mandatory only if B_01.02.0110 is reported)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>Value of total assets of the financial entity  - <span>B.01.02.0110</span></p>
        <AdditionalInformation description="Monetary value of total assets of the financial entity as reported in the financial entity’s annual financial statement of the year before the date of the last update of the register of information. Monetary value shall be reported in units.
Refer to Annex IV for the filling in this column.
(monetary)
(mandatory if the entity is a financial entity)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'total_assets',
    key: 'total_assets',
  },
];

const B0102: React.FC<B0102Props> = ({ entityName }) => {
  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchRT0102(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          country_name: item.country_name,
          currency: item.currency,
          date_of_last_update: item.date_of_last_update,
          deletion_date: item.deletion_date,
          entity_name: item.entity_name,
          entity_type: item.entity_type,
          hierarchy_in_group: item.hierarchy_in_group,
          integration_date: item.integration_date,
          lei_entity: item.lei_entity,
          parent_lei: item.parent_lei,
          total_assets: item.total_assets,
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0102;
