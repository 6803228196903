import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { notification, DatePicker, Input } from 'antd';
import dayjs from 'dayjs';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import useFilters from 'utils/hooks/useFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import {
  EditableColumnType,
  DataType,
} from 'components/CustomTable/CustomTable';
import {
  setSelectedTPP,
  set_currencyAndCosts,
  set_isCurrencyAndCostsAdded,
} from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import { ICurrencyAndCosts } from 'store/ducks/DataEntry/ThirdPartyProviders/types';
import * as Styled from '../../DataEntry.styled';
import useTppStatus from 'utils/hooks/useTppStatus';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import CustomSelect from 'components/CustomSelect';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';

const CurrencyAndCosts: React.FC = () => {
  useTppStatus();
  const dispatch = useDispatch();

  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<ICurrencyAndCosts[]>([]);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const [currencies, setCurrencies] = useState<
    { label: string; value: string }[]
  >([]);
  const [costs, setCosts] = useState<{ label: string; value: string }[]>([]);
  const [arrangements, setArrangements] = useState<
    { label: string; value: string }[]
  >([]);
  const [tppNames, setTppNames] = useState<{ label: string; value: string }[]>(
    [],
  );
  const {
    selectedTPP,
    tpps,
    currencyAndCosts,
    addRowCountTpp,
    tppId,
    nameOfTpp,
  } = useSelector((state: RootState) => state.tpp);

  const [selectedTPPID, setSelectedTPPID] = useState<string>('');
  const [combinedData, setCombinedData] = useState<ICurrencyAndCosts[]>([]);
  const [initialData, setInitialData] = useState<ICurrencyAndCosts[]>([]);
  const [tppName, setTppName] = useState<string>('');
  const isLoggedIn = localStorage.getItem('authToken');

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    setCombinedData([...dataSource, ...currencyAndCosts]);
  }, [dataSource, currencyAndCosts]);

  const getCurrencyAndCosts = async () => {
    try {
      const response = await tppOp.setCurrencyAndCosts(tppName, selectedTPPID);
      const mappedData = [
        {
          key: 0,
          currency_id: response.costs.currency_id,
          currency_name: response.currency.name,
          currency_description: response.currency.description,
          costs_id: response.costs.id,
          currency_amount_reported: response.costs.currency_amount_reported,
          annual_expense: response.costs.annual_expense,
          total_annual_expense: response.costs.total_annual_expense,
          cost_description: response.costs.description,
          id: response.currency.id,
          contractual_arrangement_id: response.costs.contractual_arrangement_id,
          third_party_service_provider_id:
            response.costs.third_party_service_provider_id,
          start_date: response.start_date,
          end_date: response.end_date,
          isNew: false,
          isEdited: false,
          tpp_name: response.tpp_name,
        },
      ];
      setDataSource(mappedData);
      setCount(mappedData.length);
      setInitialData(mappedData);
    } catch (error) {
      console.error('Error:', error);
      const newRow = {
        key: generateUniqueId(),
        id: generateUniqueId(),
        currency_id: '',
        currency_name: '',
        currency_description: '',
        costs_id: '',
        currency_amount_reported: null,
        annual_expense: null,
        total_annual_expense: null,
        cost_description: '',
        contractual_arrangement_id: '',
        third_party_service_provider_id: selectedTPPID || '',
        start_date: '',
        end_date: '',
        tpp_name: nameOfTpp,
        active: true,
        isNew: true,
      };

      setDataSource((prevData) => [...prevData, newRow]);
      setCount((prevCount) => prevCount + 1);
      setInitialData((prevData) => [...prevData, newRow]);
    }
  };

  const fetchTPPID = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;
      const dataTPP = data.map((item: any, index: number) => ({
        third_party_service_provider_name:
          item.third_party_service_provider_name,
        id: item.id,
        active: item.active,
      }));

      const dataForTable = data.map((item: any) => ({
        label: item?.third_party_service_provider_name || 'Unknown',
        value: item?.id?.toString() || '',
      }));

      setTppNames(dataForTable);

      const matchedTPP = dataTPP.find(
        (item: ICurrencyAndCosts) =>
          item.id.toString() === selectedTPP.toString(),
      );

      if (matchedTPP) {
        setSelectedTPPID(matchedTPP.id);
        setTppName(matchedTPP.third_party_service_provider_name);
        setIsActive(matchedTPP.active);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await entityOp.fetchCurrencies();
      if (response?.data.currencies) {
        const formattedOptions = response.data.currencies.map(
          (reasons: { currency_name: any; id: any }) => ({
            label: reasons.currency_name,
            value: reasons.id,
          }),
        );
        setCurrencies(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCosts = async () => {
    try {
      const response = await tppOp.fetchCosts();
      if (response?.data.costs) {
        const formattedOptions = response.data.costs.map(
          (reasons: { description: any; id: any }) => ({
            label: reasons.description,
            value: reasons.id,
          }),
        );
        setCosts(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchArrangements = async () => {
    try {
      const response = await tppOp.fetchContractualArrangements();
      if (response?.data.contractual_arrangements) {
        const formattedOptions = response.data.contractual_arrangements.map(
          (reasons: { arrangement_reference_number: any; id: any }) => ({
            label: reasons.arrangement_reference_number,
            value: reasons.id,
          }),
        );
        setArrangements(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (query.entity) {
      if (selectedTPP === '') {
        fetchTPPID();
      } else if (selectedTPP) {
        dispatch(setSelectedTPP(selectedTPP));
      } else if (!selectedTPP) {
        dispatch(setSelectedTPP(''));
        setDataSource([]);
      }
    }
  }, [query.entity]);

  useEffect(() => {
    if (selectedTPP === '' && dataSource.length > 0 && query.entity) {
      setDataSource([]);
      setSelectedTPPID('');
    }
  }, [selectedTPP, dataSource, query.entity]);

  useEffect(() => {
    if (selectedTPP && query.entity) {
      fetchTPPID();
      fetchCurrencies();
      fetchCosts();
      fetchArrangements();
    }
  }, [selectedTPP, query.entity]);

  useEffect(() => {
    if (selectedTPPID && query.entity) {
      getCurrencyAndCosts();
    }
  }, [selectedTPPID, query.entity]);

  let uniqueIdCounter = 0;

  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now() % 1_000_000_000;
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId =
      (timestampPart * 1000 + randomPart + uniqueIdCounter) % 2_147_483_648;

    return uniqueId;
  };

  useEffect(() => {
    if (addRowCountTpp === 1 && tpps.length > currencyAndCosts.length) {
      if (nameOfTpp) {
        const newRow = {
          key: generateUniqueId(),
          id: generateUniqueId(),
          currency_id: '',
          currency_name: '',
          currency_description: '',
          costs_id: '',
          currency_amount_reported: null,
          annual_expense: null,
          total_annual_expense: null,
          cost_description: '',
          contractual_arrangement_id: '',
          third_party_service_provider_id: tppId,
          start_date: '',
          end_date: '',
          tpp_name: nameOfTpp,
          active: true,
          isNew: true,
        };
        dispatch(set_currencyAndCosts([...currencyAndCosts, newRow]));
      }
     
    }
  }, [tpps, query.entity, nameOfTpp]);

  useEffect(() => {
    if (addRowCountTpp === 0) {
      dispatch(set_currencyAndCosts([]));
    }
  }, [addRowCountTpp, currencyAndCosts.length, dispatch]);

  const handleSave = (row: ICurrencyAndCosts) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );

      setDataSource(updatedDataSource);
      setCombinedData([...updatedDataSource, ...currencyAndCosts]);
    } else {
      const updatedEntityType = currencyAndCosts.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setCombinedData([...dataSource, ...updatedEntityType]);
      dispatch(set_currencyAndCosts(updatedEntityType));
    }
  };

  const areAllFieldsFilled = (currencyAndCosts: ICurrencyAndCosts[]) => {
    return currencyAndCosts.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  useEffect(() => {
    if (addRowCountTpp === 1) {
      const allFieldsFilled = areAllFieldsFilled(currencyAndCosts);
      if (allFieldsFilled) {
        dispatch(set_isCurrencyAndCostsAdded(true));
      } else {
        dispatch(set_isCurrencyAndCostsAdded(false));
      }
    }
  }, [currencyAndCosts, addRowCountTpp]);

  const handleSubmit = async () => {
    if (combinedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited currency or costs to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    const currencies_list = combinedData.map((item) => ({
      id: item.id,
      currency_name: item.currency_name,
      description: item.currency_description,
    }));

    const costs_list = combinedData.map((item) => ({
      id: item.costs_id,
      currency_amount_reported: item.currency_amount_reported,
      annual_expense: item.annual_expense,
      total_annual_expense: item.total_annual_expense,
      description: item.cost_description,
      currency_id: item.currency_id,
      contractual_arrangement_id: item.contractual_arrangement_id,
      third_party_service_provider_id: item.third_party_service_provider_id,
      start_date: item.start_date,
      end_date: item.end_date,
    }));

    try {
      const newRows = combinedData.filter((row) => row.isNew);
      const updatedRows = combinedData.filter((row) => !row.isNew);

      for (const newRow of newRows) {
        await tppOp.insertCurrencyAndCosts({
          tpp_name: newRow.tpp_name,
          entity_name: query.entity,
          currencies_list,
          costs_list,
          token: isLoggedIn,
        });
      }

      if (updatedRows.length > 0) {
        await tppOp.insertCurrencyAndCosts({
          tpp_name: tppName,
          entity_name: query.entity,
          currencies_list,
          costs_list,
          token: isLoggedIn,
        });
      }

      notification.success({
        message: 'Submission Successful',
        description: 'The data has been submitted successfully.',
        placement: 'topRight',
        duration: 20,
      });
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: 'Third Party Service Provider',
      dataIndex: 'tpp_name',
      editable: false,
      fixed: 'left',
      render: (text: string) => (
        <Input value={text} disabled />
      ),
    },
    {
      title: (
        <>
          Currency <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'currency_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={currencies}
          value={record.currency_id}
          onChange={(value: any) => {
            const selectedCurrency = currencies.find(
              (currency) => currency.value === value,
            );

            const currencyName = selectedCurrency ? selectedCurrency.label : '';

            handleSave({
              ...record,
              currency_id: value,
              currency_name: currencyName,
            } as ICurrencyAndCosts);
          }}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: 'Currency description',
      dataIndex: 'currency_description',
      editable: true,
      type: 'currency',
    },
    {
      title: (
        <Styled.TitleComponent>
          Currency of the amount reported in B_05.01.0070
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the ISO 4217 alphabetic code of the currency used to express the amount in B_05.01.0100.
The currency reported shall be the same currency used by the financial entity for the preparation of the financial statements at entity, sub-consolidated or consolidated level, as applicable.
(currency)
(mandatory if B_05.01.0100 is reported)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'currency_amount_reported',
      editable: true,
      type: 'cost',
    },
    {
      title: (
        <>
          Annual expense<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'annual_expense',
      editable: true,
      type: 'cost',
    },
    {
      title: (
        <strong>
          <Styled.TitleComponent>
            Total annual expense or estimated cost of the ICT third-party
            service provider
            <Styled.Asterisx>*</Styled.Asterisx>
            <AdditionalInformation
              description="Annual expense or estimated cost for using the ICT services provided by the ICT third-party service provider to the entities making use of the ICT services. Monetary value shall be reported in units.
(monetary)
(mandatory if the ICT third- party service provider is a direct ICT third-party service provider)"
            />
          </Styled.TitleComponent>
        </strong>
      ),
      dataIndex: 'total_annual_expense',
      editable: true,
      type: 'cost',
    },
    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'start_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as ICurrencyAndCosts;
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              format="YYYY-MM-DD"
              value={text ? dayjs(text) : null}
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                const updatedRow: ICurrencyAndCosts = {
                  ...entityRecord,
                  start_date: formattedDateString,
                };
                handleSave(updatedRow);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'end_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as ICurrencyAndCosts;
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              format="YYYY-MM-DD"
              value={text ? dayjs(text) : null}
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                const updatedRow: ICurrencyAndCosts = {
                  ...entityRecord,
                  end_date: formattedDateString,
                };
                handleSave(updatedRow);
              }}
            />
          </div>
        );
      },
    },

    {
      title: (
        <>
          Cost <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'costs_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={costs}
          value={record.costs_id}
          onChange={(value: any) => {
            handleSave({
              ...record,
              costs_id: value,
            } as ICurrencyAndCosts);
          }}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <>
          Contractual Arrangements <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'contractual_arrangement_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={arrangements}
          value={record.contractual_arrangement_id}
          onChange={(value: any) => {
            handleSave({
              ...record,
              contractual_arrangement_id: value,
            } as ICurrencyAndCosts);
          }}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: 'Cost description',
      dataIndex: 'cost_description',
      editable: true,
      type: 'cost',
    },
  ];

  return (
    dataSource && (
      <Styled.StyledWrapper hasEntity={!!query.entity}>
        <CustomTable
          columns={defaultColumns}
          dataSource={combinedData}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        {query.entity && (
          <Styled.AreaButton type="primary" onClick={handleSubmit}>
            Submit
          </Styled.AreaButton>
        )}
      </Styled.StyledWrapper>
    )
  );
};

export default CurrencyAndCosts;
