import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { Input, notification } from 'antd';
import CustomTable, {
  EditableColumnType,
} from 'components/CustomTable/CustomTable';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { IValueOfTotalAssetsDataType } from 'store/ducks/DataEntry/Entities/types';
import {
  set_valueOfTotalAssets,
  set_isValueOfTotalAssetsAdded,
} from 'store/ducks/DataEntry/Entities/entitySlice';
import useEntityStatus from 'utils/hooks/useEntityStatus';
import CustomSelect from 'components/CustomSelect';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../../DataEntry.styled';

const ValueOfTotalAssets: React.FC = () => {
  useEntityStatus();
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<IValueOfTotalAssetsDataType[]>(
    [],
  );
  const [initialData, setInitialData] = useState<IValueOfTotalAssetsDataType[]>(
    [],
  );
  const [entityNames, setEntityNames] = useState<
    { label: string; value: string }[]
  >([]);
  const [branches, setBranches] = useState<{ label: string; value: string }[]>(
    [],
  );
  const {
    valueOfTotalAssets,
    addRowCount,
    entities,
    idOfEntity,
    nameOfEntity,
  } = useSelector((state: RootState) => state.entities);
  const [combinedData, setCombinedData] = useState<
    IValueOfTotalAssetsDataType[]
  >([]);
  const isLoggedIn = localStorage.getItem('authToken');
  const dispatch = useDispatch();

  useEffect(() => {
    setCombinedData([...valueOfTotalAssets, ...dataSource]);
  }, [dataSource, valueOfTotalAssets]);

  const handleSave = (row: IValueOfTotalAssetsDataType) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );

      setDataSource(updatedDataSource);
      setCombinedData([...updatedDataSource, ...valueOfTotalAssets]);
    } else {
      const updatedEntityType = valueOfTotalAssets.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setCombinedData([...dataSource, ...updatedEntityType]);
      dispatch(set_valueOfTotalAssets(updatedEntityType));
    }
  };

  const fetchValueOfTotalAssets = async () => {
    try {
      const response = await entityOp.fetchValueOfTotalAssetsByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.value_of_total_assets;
      const mappedData = [
        {
          key: data?.id,
          id: data?.id ? data.id : generateUniqueId(),
          value: data?.value || '',
          entity_id: data?.entity_id ? data?.entity_id : query.entityId,
          description: data?.description || '',
          branch_id: data?.branch_id || '',
          entity_name: response?.data?.entity_name || query.entity,
        },
      ];

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchEntityNames = async () => {
    try {
      const response = await entityOp.fetchEntityNames();
      if (response?.data) {
        const formattedOptions = response.data.map(
          (reasons: { name_of_entity: any; id: any }) => ({
            label: reasons.name_of_entity,
            value: reasons.id,
          }),
        );
        setEntityNames(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await entityOp.fetchBranches();
      if (response?.data) {
        const formattedOptions = response.data.map(
          (reasons: { branch_name: any; id: any }) => ({
            label: reasons.branch_name,
            value: reasons.id,
          }),
        );
        setBranches(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchValueOfTotalAssets();
      fetchEntityNames();
      fetchBranches();
    } else {
      fetchBranches();
    }
  }, [query]);
  let uniqueIdCounter = 0;

  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now() % 100_000_000;
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId =
      (timestampPart * 1000 + randomPart + uniqueIdCounter) % 2_147_483_648;

    return uniqueId;
  };
  
  useEffect(() => {
    if (addRowCount === 0) {
      dispatch(set_valueOfTotalAssets([]));
    }
  }, [addRowCount, valueOfTotalAssets.length, dispatch]);

  const areAllFieldsFilled = (
    valueOfTotalAssets: IValueOfTotalAssetsDataType[],
  ) => {
    return valueOfTotalAssets.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  useEffect(() => {
    if (addRowCount === 1) {
      const allFieldsFilled = areAllFieldsFilled(valueOfTotalAssets);
      if (allFieldsFilled) {
        dispatch(set_isValueOfTotalAssetsAdded(true));
      } else {
        dispatch(set_isValueOfTotalAssetsAdded(false));
      }
    }
  }, [valueOfTotalAssets, addRowCount]);

  const handleSubmitValue = async () => {
    if (JSON.stringify(combinedData) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (valueOfTotalAssets.length !== 0) {
      const allFieldsFilled = areAllFieldsFilled(valueOfTotalAssets);

      if (!allFieldsFilled) {
        notification.warning({
          message: 'Not submitted',
          description:
            'Please fill in all the required fields in Value of Total Assets.',
          placement: 'topRight',
          duration: 20,
        });
        return;
      } else {
        dispatch(set_isValueOfTotalAssetsAdded(true));
      }
    }

    try {
      const payload = {
        entity_name: query.entity,
        data_list: [...combinedData],
        token: isLoggedIn,
      };
      const response = await entityOp.fetchValueOfTotalAssets(payload);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType[] = [
    {
      title: (
        <Styled.TitleComponent>
          Value of total assets of the financial entity
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation description="Identify the competent authority referred to in Article 46 of Regulation (EU) 2022/2554 to which the register of information is reported. (alphanumerical) (mandatory in case of reporting)." />
        </Styled.TitleComponent>
      ),
      dataIndex: 'value',
      editable: true,
    },
    {
      title: 'Total Assets Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: (
        <>
          Branch<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'branch_id',
      editable: false,
      render: (_, record) => {
        const selectedBranch = branches.find(
          (branch) => branch.value === record.branch_id,
        );
        return (
          <CustomSelect
            options={branches}
            value={selectedBranch ? selectedBranch.value : undefined}
            onChange={(value: any) =>
              handleSave({
                ...record,
                branch_id: value,
              } as IValueOfTotalAssetsDataType)
            }
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          />
        );
      },
    },
    {
      title: (
        <>
          Entity Name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'entity_name',
      editable: false,
      render: (text: string) => (
        <Input value={text} disabled />
      ),
    },
  ];

  return (
    <Styled.StyledWrapper
      hasEntity={!!query.entity}
      style={{ marginTop: '64px' }}
    >
      <CustomTable
        columns={columns}
        dataSource={combinedData}
        handleSave={handleSave}
      />
      <Styled.AreaButton type="primary" onClick={handleSubmitValue}>
        Submit
      </Styled.AreaButton>
    </Styled.StyledWrapper>
  );
};

export default ValueOfTotalAssets;
