import styled, { keyframes } from 'styled-components';
import { Layout, Card, Button, Drawer, Alert } from 'antd';
import { IToken, ThemeList } from 'store/ducks/globalTypes';
import { variablesList } from 'constants/variablesList';
import { IThemeSelectorProps } from 'components/ThemeSelector/ThemeSelector';
import CustomSelect from 'components/CustomSelect';

const { Header } = Layout;
const shake = keyframes`
  0% { transform: translateX(0); }
  10% { transform: translateX(-5px); }
  20% { transform: translateX(5px); }
  30% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  50% { transform: translateX(-5px); }
  60% { transform: translateX(5px); }
  70% { transform: translateX(-5px); }
  80% { transform: translateX(5px); }
  90% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`;

export const StyledLayout = styled(Layout)<IToken>`
  min-height: 100vh;

  > .ant-layout {
    min-height: initial;

    .ant-layout-header {
      height: fit-content;
    }
  }

  aside {
    padding-right: ${(props) => `${props.token.paddingLG}px`};
    flex: initial !important;
    text-align: center;
    max-width: fit-content !important;
    min-width: fit-content !important;
    width: fit-content !important;
    position: relative !important;
    right: 0px;
  }

  h4 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    padding-right: 34px;
  }
`;

export const AntDrawer = styled(Drawer)`
  .ant-drawer-mask {
    background-color: red;
  }
  .ant-picker {
    margin-top: 10px;
    min-width: 162px;
  }
`;

export const HorizontalLayout = styled('div')<IThemeSelectorProps>`
  padding: 0 24px 24px;
  border-bottom: 1px solid
    ${(props) => variablesList[props.theme as ThemeList].defaultTabBg};

  .ant-tabs-nav-list {
    width: -webkit-fill-available;
    margin-bottom: 1rem;

    .ant-tabs-tab {
      width: -webkit-fill-available;
      display: table;
    }
  }
`;

export const StyledHeader = styled(Header)<IToken>`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-inline: ${(props) => `${props.token.paddingLG}px`};
  line-height: ${(props) => `${props.token.lineHeightHeading2}px`};
`;

export const GridContainer = styled('div')`
  display: flex;
  column-gap: 170px;
  row-gap: 48px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;

  > a {
    height: fit-content;
    width: 350px;
  }

  .ant-card {
    width: 350px;

    .ant-card-meta-title {
      white-space: pre-line !important;
    }

    .ant-card-body {
      cursor: pointer;
    }
  }
`;

export const Container = styled('div')`
  padding: 0px;
`;

export const ReportCard = styled(Card)`
  width: 350px;
  text-align: center;

  .ant-btn {
    margin-top: 9px;
    width: -webkit-fill-available;
    font-size: 16px;
    font-weight: 600;
    height: 50px;

    span {
      text-wrap-mode: wrap;
    }
  }

  .ant-card-body {
    cursor: auto !important;
  }
`;

export const StyledButton = styled(Button)`
  width: 110px;
  text-transform: uppercase;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
  margin-top: 20px;
`;

export const FilterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  gap: 8px;
`;

export const OpenCloseButton = styled(Button)`
  width: 30px;
  text-transform: uppercase;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
`;

export const ExcelDownload = styled.div`
  text-align: -webkit-center;
  margin-bottom: 3rem;

  button {
    width: auto;
  }
`;

export const StepsComponent = styled('div')`
  padding: 0px;
  position: relative;

  .ant-steps-item {
    flex: auto;

    .ant-steps-item-title {
      font-size: 15px;
      color: #008080 !important;
    }
  }
  .ant-empty-description {
    color: black !important;
  }
`;

export const AlertComponent = styled('div')`
  padding-inline: 24px;
`;

export const AlertInfo = styled(Alert)`
  // float: right;
  // max-width: 530px;
  // margin: 10px 0;
  // position: relative;
  // font-weight: 900;
  // background-color: rgb(172 6 92);
  // color: white;
  // border: 1px solid rgb(172 6 92);
  float: right;
  position: absolute;
  right: 40px;
  top: -96px;
  background-color: rgb(172 6 92);
  color: white;
  border: 1px solid rgb(172 6 92);
  animation: iwrNJy 3s ease-out;
  border-radius: 5px;
  .ant-alert-description {
    color: white;
  }
  .ant-alert-close-icon {
    color: white;
  }
  animation: ${shake} 3s ease-out;
`;

export const AlertInfoForTPP = styled(Alert)`
  max-width: 222px;
  font-weight: 900;
  background-color: rgb(172 6 92);
  border: 1px solid rgb(172 6 92);
  position: absolute;
  padding: 11px;
  margin-top: 10px;
  .ant-alert-description {
    color: white;
  }
  .ant-alert-close-icon {
    color: white;
  }
  animation: ${shake} 3s ease-out;
`;

export const SelectionOfTPP = styled.div`
  position: relative;
  top: 72px;
  left: 189px;
`;

export const StepButtons = styled.div`
  display: flex;
  gap: 8px;

  div {
    display: flex;
    gap: 14px;
  }
  position: relative;

  div {
    position: absolute;
    top: 32px;
    left: 14px;
    z-index: 1;
  }
`;

export const WorkingAreaButton = styled.div`
  display: flex;
  margin: 0 24px 24px;
  justify-content: space-between;

  div {
    display: flex;
    gap: 15px;
  }
`;

export const SelectFilter = styled(CustomSelect)`
  margin: 10px 0;

  .ant-select-selector {
    width: 162px !important;
  }
`;

export const DataEntryTitle = styled.span`
  margin: 0;
  font-weight: bold;
  display: block;
  font-size: 1.5em;
`;

export const TitleContainer = styled('div')<IThemeSelectorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    ${(props) => variablesList[props.theme as ThemeList].tabSelected},
    ${(props) => variablesList[props.theme as ThemeList].buttonPrimaryBgHover}
  );
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;

export const TitleText = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

export const SubtitleText = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  margin: 5px 0 0 0;
`;

export const SelectedFilters = styled('div')`
  text-align: center;
  margin-bottom: 40px;
`;
export const AreaTitle = styled('div')`
  width: fit-content;
  margin: auto;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -100%);
`;
