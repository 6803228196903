import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { Input, notification } from 'antd';
import CustomTable from 'components/CustomTable/CustomTable';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { INatureOfEntityDataType } from 'store/ducks/DataEntry/Entities/types';
import {
  set_natureOfEntity,
  set_isNatureOfEntityAdded,
} from 'store/ducks/DataEntry/Entities/entitySlice';
import useEntityStatus from 'utils/hooks/useEntityStatus';
import * as Styled from '../../DataEntry.styled';

const NatureOfEntity: React.FC = () => {
  useEntityStatus();
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<INatureOfEntityDataType[]>([]);
  const [initialData, setInitialData] = useState<INatureOfEntityDataType[]>([]);
  const [combinedData, setCombinedData] = useState<INatureOfEntityDataType[]>(
    [],
  );
  const { addRowCount, natureOfEntity, entities, nameOfEntity } = useSelector(
    (state: RootState) => state.entities,
  );
  const isLoggedIn = localStorage.getItem('authToken');
  const dispatch = useDispatch();

  useEffect(() => {
    setCombinedData([...natureOfEntity, ...dataSource]);
  }, [dataSource, natureOfEntity]);

  const handleSave = (row: INatureOfEntityDataType) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );

      setDataSource(updatedDataSource);
      setCombinedData([...updatedDataSource, ...natureOfEntity]);
    } else {
      const updatedEntityType = natureOfEntity.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setCombinedData([...dataSource, ...updatedEntityType]);
      dispatch(set_natureOfEntity(updatedEntityType));
    }
  };

  const fetchEntityTypes = async () => {
    try {
      const response = await entityOp.fetchNatureOfEntityByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.nature_of_entity;

      const mappedData = [
        {
          key: data?.id,
          id: data?.id,
          name: data?.name || '',
          description: data?.description || '',
          entity_name: response?.data?.entity_name || query.entity,
        },
      ];

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchEntityTypes();
    }
  }, [query]);
  let uniqueIdCounter = 0;

  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now() % 1_000_000_000;
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId =
      (timestampPart * 1000 + randomPart + uniqueIdCounter) % 2_147_483_648;

    return uniqueId;
  };

  useEffect(() => {
    if (addRowCount === 0) {
      dispatch(set_natureOfEntity([]));
    }
  }, [addRowCount, natureOfEntity.length, dispatch]);

  const areAllFieldsFilled = (natureOfEntity: INatureOfEntityDataType[]) => {
    return natureOfEntity.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  useEffect(() => {
    if (addRowCount === 1) {
      const allFieldsFilled = areAllFieldsFilled(natureOfEntity);
      if (allFieldsFilled) {
        dispatch(set_isNatureOfEntityAdded(true));
      } else {
        dispatch(set_isNatureOfEntityAdded(false));
      }
    }
  }, [natureOfEntity, addRowCount]);

  const handleSubmitNature = async () => {
    if (JSON.stringify(combinedData) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (!areAllFieldsFilled(combinedData)) {
      notification.warning({
        message: 'Not submitted',
        description: 'Please fill in all the required fields in the data.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (natureOfEntity.length !== 0) {
      const allFieldsFilled = areAllFieldsFilled(natureOfEntity);
      if (!allFieldsFilled) {
        notification.warning({
          message: 'Not submitted',
          description:
            'Please fill in all the required fields in Nature of Entity.',
          placement: 'topRight',
          duration: 20,
        });
        return;
      } else {
        dispatch(set_isNatureOfEntityAdded(true));
      }
    }

    try {
      const payload = {
        entity_name: query.entity,
        data_list: [...combinedData],
        token: isLoggedIn,
      };
      const response = await entityOp.fetchNatureOfEntity(payload);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns = [
    {
      title: (
        <>
          Nature of Entity <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'name',
      width: '30%',
      editable: false,
      render: (text: string) => (
        <Input value={text} disabled />
      ),
    },
    {
      title: 'Nature of Entity Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: (
        <>
          Entity Name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'entity_name',
      editable: false,
      render: (text: string) => (
        <Input value={text} disabled />
      ),
    },
  ];

  return (
    <Styled.StyledWrapper
      hasEntity={!!query.entity}
      style={{ marginTop: '64px' }}
    >
      <CustomTable
        columns={columns}
        dataSource={combinedData}
        handleSave={handleSave}
      />
      <Styled.AreaButton type="primary" onClick={handleSubmitNature}>
        Submit
      </Styled.AreaButton>
    </Styled.StyledWrapper>
  );
};

export default NatureOfEntity;
