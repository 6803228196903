import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  branch_identification_code: string;
  branch_name: string;
  country_id: number;
  head_office_lei: string;
  name_of_entity: string;
}

interface B0103Props {
  entityName: string | null | undefined;
}
const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Identification code of the branch - <span>B.01.03.0010</span></p>
        <AdditionalInformation description="Identify a branch of a financial entity located outside its home country using a unique code for each branch. One of the options in the following closed list shall be used:
(a)	LEI of the branch if unique for this branch and different from B_01.03.0020;
(b)	other identification code used by the financial entity to identify the branch (where the LEI of the branch is equivalent to the one in template B_01.03.0020 or equivalent to the LEI of another branch).
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'branch_identification_code',
    key: 'branch_identification_code',
    fixed: 'left',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
      <p> LEI of the financial entity head office of the branch - <span>B.01.03.0020</span></p>
        <AdditionalInformation description="As reported in B_01.02.0010
Identify the financial entity head office of the branch, using the LEI, 20-character, alpha- numeric code based on the ISO 17442 standard
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'head_office_lei',
    key: 'head_office_lei',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Name of the branch - <span>B.01.03.0030</span></p>
        <AdditionalInformation description="Identify the name of the branch
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'branch_name',
    key: 'branch_name',
    fixed: 'left',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Country of the branch - <span>B.01.03.0040</span></p>
        <AdditionalInformation description="Identify the ISO 3166–1 alpha–2 code of the country where the branch is located
(country)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'country_id',
    key: 'country_id',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        Name of the financial entity
        <AdditionalInformation description="Legal name of the financial entity maintaining and updating the register of information
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'name_of_entity',
    key: 'name_of_entity',
  },
];


const B0103: React.FC<B0103Props> = ({ entityName }) => {
  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchBranches(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          branch_identification_code: item.branch_identification_code,
          branch_name: item.branch_name,
          country_id: item.country_id,
          head_office_lei: item.head_office_lei,
          name_of_entity: item.name_of_entity,
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0103;
