import ApiService from 'utils/ApiCalls';
import { useSelector } from 'react-redux';
import { AnyARecord } from 'dns';
import { notification } from 'antd';

const useFetchContractualInformation = () => {
  const {
    selectedBranchForContractualInformation,
    selectedICTForContractualInformation,
    selectedTPPForContractualInformation,
    selectedEntityForContractualInformation,
  } = useSelector((state: any) => state.contractualInformation);

  const fetchContractualInformation = async () => {
    try {
      const response = await ApiService.fetchContractualInformation(
        selectedBranchForContractualInformation,
        selectedICTForContractualInformation,
        selectedTPPForContractualInformation,
        selectedEntityForContractualInformation,
      );
      return response;
    } catch (error) {
      console.error('Error fetching Contractual Information:', error);
      throw error;
    }
  };

  return fetchContractualInformation;
};

const postContractualInformation = async (contractualInformation: any) => {
  try {
    const response = await ApiService.postContractualInformation(
      contractualInformation,
    );
    notification.success({
      message: 'Success',
      description: response.data.message,
      duration: 10,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchBranchesbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postBranchesbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the Branches:', error);
  }
};

const fetchICTServicesbyEntityName = async (entityTypeData: any) => {
  try {
    const response =
      await ApiService.postICTServicesbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    console.error('Error fetching the ICT services:', error);
  }
};

const fetchTerminatReasons = async () => {
  try {
    const response = await ApiService.getTerminatReasons();
    return response;
  } catch (error) {
    console.error('Error fetching the terminat reasons:', error);
  }
};

const fetchContractualArrangementTypes = async () => {
  try {
    const response = await ApiService.getContractualArrangementTypes();
    return response;
  } catch (error) {
    console.error('Error fetching the contractual arrangement types:', error);
  }
};

const inactivateContractualArrangement = async (dataList: number[]) => {
  try {
    const response =
      await ApiService.inactivateContractualArrangement(dataList);

    const { errors, deleted_contractual_arrangements, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach(
        (error: { contractual_arrangement_id: number; error: string }) => {
          notification.error({
            message: `Error for Contractual Arrangement ID: ${error.contractual_arrangement_id}`,
            description: error.error,
            duration: 10,
          });
        },
      );
    } else if (
      deleted_contractual_arrangements &&
      deleted_contractual_arrangements.length > 0
    ) {
      notification.success({
        message: 'Contractual Arrangement Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description:
        'Failed to inactivate contractual arrangement. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate contractual arrangement');
  }
};

const inactivateContract = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateContract(dataList);

    const { errors, deleted_contracts, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach((error: { contract_id: number; error: string }) => {
        if (!error.error.includes('Contract not found.')) {
          notification.error({
            message: `Error for Contract ID: ${error.contract_id}`,
            description: error.error,
            duration: 10,
          });
        }
      });
    } else if (deleted_contracts && deleted_contracts.length > 0) {
      notification.success({
        message: 'Contract Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate Contract. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate Contract');
  }
};

const inactivateCosts = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateCosts(dataList);
    const { errors, deleted_costs, message } = response.data;
    if (errors && errors.length > 0) {
      errors.forEach((error: { cost_id: number; error: string }) => {
        if (!error.error.includes('Cost record not found.')) {
          notification.error({
            message: `Error for Cost ID: ${error.cost_id}`,
            description: error.error,
            duration: 10,
          });
        }
      });
    } else if (deleted_costs && deleted_costs.length > 0) {
      notification.success({
        message: 'Cost Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate Cost. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate Cost');
  }
};
export default {
  useFetchContractualInformation,
  fetchBranchesbyEntityName,
  fetchICTServicesbyEntityName,
  postContractualInformation,
  fetchTerminatReasons,
  fetchContractualArrangementTypes,
  inactivateContractualArrangement,
  inactivateContract,
  inactivateCosts,
};
