import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  theme,
  Card,
  Layout,
  Button,
  message,
  DatePicker,
  notification,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { AppDispatch, RootState } from 'store/configureStore';
import TitleBar from 'components/TitleBar';
import { useLocation } from 'react-router-dom';
import CustomSelect from 'components/CustomSelect';
import { TitleList } from 'store/ducks/globalTypes';
import qs from 'qs';
import GoBackButton from 'components/GoBackButton';
import ReportModal from 'components/Modal/Modal';
import { tableData } from 'constants/excelData';
import useFilters from 'utils/hooks/useFilters';
import { globalOp } from 'store/ducks/Global';
import { reportingOp } from 'store/ducks/Reportings';
import {
  set_selectedDate,
  set_isDisplayed,
} from 'store/ducks/Reportings/reportingSlice';
import * as XLSX from 'xlsx';
import * as Styled from '../Layout.styled';

const { useToken } = theme;
const { Sider } = Layout;

const ReportingLayout: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { token } = useToken();
  const { scheme } = useSelector((state: RootState) => state.global);
  const { date } = useSelector((state: RootState) => state.reportings);
  const [open, setOpen] = useState(false);
  const { query, handleFilterChangeForReportings, extractedOptions } =
    useFilters();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onChangeStartDate = (
    date: dayjs.Dayjs | null,
    dateString: string | string[],
  ) => {
    if (typeof dateString === 'string') {
      dispatch(set_selectedDate(dateString));
    }
  };
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    dispatch(globalOp.setFilters());
  }, [dispatch]);

  const applyFilters = () => {
    dispatch(set_isDisplayed(true));
    setOpen(false);
    message.success('Filters applied');
  };
  const handleExcelSheetsDownload = async () => {
    if (!query.entityId) {
      notification.warning({
        message: 'Missing Entity',
        description:
          'Please provide a valid entity name to download the sheets.',
        placement: 'topRight',
      });
      return;
    }

    try {
      const params = {
        entity_name: queryParams.entity_name,
        date,
      };

      const fetchOperations = [
        reportingOp.fetchEntityContractualArrangements(params),
        reportingOp.fetchBranches(params),
        reportingOp.fetchContractualArrangementsLei(params),
        reportingOp.fetchContractualArrangementsICTUse(params),
        reportingOp.fetchContractualArrangementsICT(params),
        reportingOp.fetchRT0101(params),
        reportingOp.fetchRT0102(params),
        reportingOp.fetchRT0201(params),
        reportingOp.fetchRT0202(params),
        reportingOp.fetchRT0302(params),
        reportingOp.fetchRT0501(params),
        reportingOp.fetchRT0502(params),
        reportingOp.fetchRT0601(params),
        reportingOp.fetchRT0701(params),
      ];

      const sheetTitles = [
        'B_02.03',
        'B_01.03',
        'B_03.01',
        'B_03.03',
        'B_04.01',
        'B_01.01',
        'B_01.02',
        'B_02.01',
        'B_02.02',
        'B_03.02',
        'B_05.01',
        'B_05.02',
        'B_06.01',
        'B_07.01',
      ];

      const results = await Promise.all(fetchOperations);

      const processedResults = results.map((data) => {
        return Object.keys(data).length === 1 && data.message ? [] : data;
      });

      const wb = XLSX.utils.book_new();

      processedResults.forEach((data, index) => {
        const sheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, sheet, sheetTitles[index]);
      });

      XLSX.writeFile(
        wb,
        `Registry of Information for ${params.entity_name}.xlsx`,
      );

      setOpen(false);
    } catch (error) {
      console.error('Error fetching data or generating Excel:', error);
    }
  };

  return (
    <Styled.StyledLayout token={token}>
      <TitleBar title={TitleList.REPORTING} />
      <h4>Registry of Information</h4>
      <Styled.WorkingAreaButton>
        <div>
          <GoBackButton to="/working_area" text="Working areas" />
        </div>
        <Button
          type="primary"
          onClick={showDrawer}
          icon={<FilterOutlined />}
        ></Button>
      </Styled.WorkingAreaButton>
      <Styled.AlertComponent>
        {!queryParams.entity_name && (
          <Styled.AlertInfo
            description="In order to update the data, click on filter icon and select the filter option."
            type="error"
            closable
          />
        )}
      </Styled.AlertComponent>
      <Styled.HorizontalLayout theme={scheme}>
        <Styled.Container>
          <Styled.GridContainer>
            <Styled.ReportCard>
              <Styled.ExcelDownload>
                <Card.Meta
                  title={
                    <Styled.StyledButton
                      type="primary"
                      onClick={handleExcelSheetsDownload}
                      disabled
                    >
                      Registry of Information
                    </Styled.StyledButton>
                  }
                  description={<p>Format to be sent to the regulator</p>}
                />
              </Styled.ExcelDownload>
            </Styled.ReportCard>
            {tableData.map((item, index) => (
              <Styled.ReportCard key={index}>
                <Card.Meta
                  title={
                    <ReportModal title={item.title} tableNumber={item.title} />
                  }
                  description={<p>{item.description}</p>}
                />
              </Styled.ReportCard>
            ))}
          </Styled.GridContainer>
        </Styled.Container>
      </Styled.HorizontalLayout>
      <Styled.StyledLayout token={token}>
        <Sider>
          <Styled.AntDrawer title="Filters" onClose={onClose} open={open}>
            <CustomSelect
              placeholder="Select Entity"
              onChange={(
                value: any,
                option: { value: any; label: any; key: any },
              ) => {
                handleFilterChangeForReportings(option.value, option.label);
              }}
              options={extractedOptions}
              defaultValue={queryParams.entity}
            />
            <div>
              <DatePicker onChange={onChangeStartDate} />
            </div>
            <Styled.StyledButton type="primary" onClick={applyFilters}>
              Display
            </Styled.StyledButton>
          </Styled.AntDrawer>
        </Sider>
      </Styled.StyledLayout>
    </Styled.StyledLayout>
  );
};

export default ReportingLayout;
