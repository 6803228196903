import * as XLSX from 'xlsx';

export const tableData = [
  {
    title: 'B_01.01',
    description: 'Financial entity maintaining the register of information',
  },
  {
    title: 'B_01.02',
    description:
      'List of financial entities within the scope of the register of information',
  },
  { title: 'B_01.03', description: 'List of branches' },
  {
    title: 'B_02.01',
    description: 'Contractual arrangements – General information',
  },
  {
    title: 'B_02.02',
    description: 'Contractual arrangements – Specific information',
  },
  {
    title: 'B_02.03',
    description: 'List of intra-group contractual arrangements',
  },
  {
    title: 'B_03.01',
    description:
      'Entities signing the contractual arrangements for receiving ICT service(s) on behalf of the financial entities making use of the ICT service(s)',
  },
  {
    title: 'B_03.02',
    description:
      'ICT third-party service providers signing the contractual arrangements for providing ICT service(s)',
  },
  {
    title: 'B_03.03',
    description:
      'Financial entities signing the contractual arrangements for providing ICT service(s) to other financial entities in the consolidation',
  },
  {
    title: 'B_04.01',
    description: 'Financial entities making use of the ICT services',
  },
  { title: 'B_05.01', description: 'ICT third-party service provider' },
  { title: 'B_05.02', description: 'ICT service supply chains' },
  { title: 'B_06.01', description: 'Functions identification' },
  { title: 'B_07.01', description: 'Assessment of the ICT services' },
  {
    title: 'B_99.01',
    description:
      'Terminology used by financial entities using the ICT services',
  },
];
export const tableDataAdditional = [
  {
    title: 'AR.01.01',
    description: 'Entity maintaining the register of information',
  },
  {
    title: 'Functions and ICT-Services',
    description: '',
  },
  {
    title: 'ICT - Assets',
    description: '',
  },
  {
    title: 'Information Assets (Data)',
    description: '',
  },
  {
    title: 'Functions',
    description: '',
  },
  {
    title: 'Third Party Service Provider',
    description: '',
  },
  {
    title: 'ICT-Services',
    description: '',
  },
  {
    title: 'Outsourcing Registry (EBA Guidelines On Outsourcing)',
    description: '',
  },
  {
    title: 'Certificates',
    description: '',
  },
  {
    title: '',
    description: '',
  },
  {
    title: '',
    description: '',
  },
];
export const handleTableClick = (tableTitle: string, fetchedData: any[]) => {
  const { wb, title } = generateExcelData(fetchedData, tableTitle);

  XLSX.writeFile(wb, `${title}.xlsx`);
};

export const generateExcelData = (fetchedData: any[], tableTitle: string) => {
  if (!fetchedData || fetchedData.length === 0) {
    throw new Error('No data available');
  }

  const headers = Object.keys(fetchedData[0]);

  const data = [
    headers,
    ...fetchedData.map((item) => headers.map((key) => item[key] || '')),
  ];

  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws, tableTitle);

  return { wb, title: tableTitle };
};
