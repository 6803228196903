import React, { useState, useEffect } from 'react';
import i18nIsoCountries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { useDispatch, useSelector } from 'react-redux';
import { notification, Table, Input, Button, Modal } from 'antd';
import {
  PlusOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { contractual_informationOp } from 'store/ducks/DataEntry/ContractualInformation';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import {
  IContractulInformation,
  IContracts,
  ICosts,
  EditedData,
} from 'store/ducks/DataEntry/ContractualInformation/types';
import useFilters from 'utils/hooks/useFilters';
import DynamicField from 'components/DynamicCalendarField/DynamicField';
import CustomSelect from 'components/CustomSelect';
import { setSelecedEntity_Contractual_Information } from 'store/ducks/DataEntry/ContractualInformation/contractualInformationSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import * as Styled from '../../DataEntry.styled';

const ContractualInformation: React.FC = () => {
  const [data, setData] = useState<IContractulInformation[]>([]);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const [tppData, setTPPData] = useState<any[]>([]);
  const [terminatReasons, setTerminatReasons] = useState([]);
  const [contractualArrangementTypes, setContractualArrangementTypes] =
    useState([]);
  const [contractualInformation, setContractualInformation] = useState([]);
  const [branches, setBranches] = useState<{ label: string; value: string }[]>(
    [],
  );
  const { confirm } = Modal;
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const { query } = useFilters();
  const [countries, setCountries] = useState<
    { label: string; value: string }[]
  >([]);
  const [currencies, setCurrencies] = useState<
    { label: string; value: string }[]
  >([]);
  const {
    selectedBranchForContractualInformation,
    selectedICTForContractualInformation,
    selectedTPPForContractualInformation,
    selectedEntityForContractualInformation,
  } = useSelector((state: any) => state.contractualInformation);
  const isLoggedIn = localStorage.getItem('authToken');
  i18nIsoCountries.registerLocale(enLocale);
  const dispatch = useDispatch();

  const fetchTerminatReasons = async () => {
    try {
      const response = await contractual_informationOp.fetchTerminatReasons();
      if (response?.data.terminat_reasons) {
        const formattedOptions = response.data.terminat_reasons.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setTerminatReasons(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContractualArrangementTypes = async () => {
    try {
      const response =
        await contractual_informationOp.fetchContractualArrangementTypes();
      if (response?.data.contractual_arrangement_types) {
        const formattedOptions =
          response.data.contractual_arrangement_types.map(
            (reasons: { name: any; id: any }) => ({
              label: reasons.name,
              value: reasons.id,
            }),
          );
        setContractualArrangementTypes(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContractualArrangements = async () => {
    try {
      const response = await tppOp.fetchContractualArrangements();
      if (response?.data.contractual_arrangements) {
        const formattedOptions = response.data.contractual_arrangements.map(
          (arr: { arrangement_reference_number: any; id: any }) => ({
            label: arr.arrangement_reference_number,
            value: arr.id,
          }),
        );
        setContractualInformation(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await entityOp.fetchCountries();
      if (response?.data.countries) {
        const formattedOptions = response.data.countries.map(
          (reasons: { country_name: any; id: any }) => ({
            label: reasons.country_name,
            value: reasons.id,
          }),
        );
        setCountries(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContractualInformation =
    contractual_informationOp.useFetchContractualInformation();

  useEffect(() => {
    if (!selectedEntityForContractualInformation) {
      const selectedEntity = {
        entityId: query.entityId ? Number(query.entityId) : 0,
        entity: query.entity ? String(query.entity) : '',
      };
      dispatch(setSelecedEntity_Contractual_Information(selectedEntity));
    }
  }, [query]);

  const fetchContracts = async () => {
    try {
      const response = await fetchContractualInformation();
      const data = response?.data.arrangements;
      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        terminatReason_id: item.terminat_reason.id,
        terminatReason_name: item.terminat_reason.name,
        terminatReason_description: item.terminat_reason.description,
        typeofContractualArrangement_id: item.contract_type_arrangement.id,
        typeofContractualArrangement_name: item.contract_type_arrangement.name,
        typeofContractualArrangement_description:
          item.contract_type_arrangement.description,
        contractType_id: item.contract_type.id,
        contractType_name: item.contract_type.name,
        contractType_description: item.contract_type.description,
        costs: item.costs
          ? item.costs.map((cost: any) => ({
              costs_id: cost.id,
              costs_currency_amount_reported: cost.currency_amount_reported,
              costs_annual_expense: cost.annual_expense,
              costs_total_annual_expense: cost.total_annual_expense,
              costs_description: cost.description,
              costs_currency_id: cost.currency.id,
              costs_contractual_arrangement_id: cost.contractual_arrangement_id,
              costs_third_party_service_provider_id:
                cost.third_party_service_provider.id,
              costs_start_date: cost.start_date,
              costs_end_date: cost.end_date,
              currency_name: cost.currency.currency_name,
              currency_description: cost.currency.description,
              currency_id: cost.currency.id,
            }))
          : [],
        country_id: item.governing_law_country.id,
        country_country_name: item.governing_law_country.country_name,
        country_iso_code: item.governing_law_country.iso_code,
        country_description: item.governing_law_country.description,
        contractualArrangement_id: item.id,
        contractualArrangement_arrangement_reference_number:
          item.arrangement_reference_number,
        contractualArrangement_start_date: item.start_date,
        contractualArrangement_end_date: item.end_date,
        contractualArrangement_terminat_reason_id: item.terminat_reason.id,
        contractualArrangement_contract_type_id: item.contract_type.id,
        contractualArrangement_contract_type_arrangement_id:
          item.contract_type_arrangement.id,
        contractualArrangement_overarching_reference_number:
          item.overarching_reference_number,
        contractualArrangement_linked_contractual_arrangement:
          item.linked_contractual_arrangement,
        contractualArrangement_description: item.description,
        contractualArrangement_governing_law_country_id:
          item.governing_law_country.id,
        contractualArrangement_branch_id: item.branch.id,
        contractualArrangement_entity_id: item.entity.id,
        contractualArrangement_third_party_service_provider_id:
          item.third_party_service_provider.id,
        contracts: item.contracts
          ? item.contracts.map((contract: any) => ({
              contract_third_party_service_provider_id:
                item.third_party_service_provider.id,
              contract_id: contract.id,
              contract_name: contract.name,
              contract_description: contract.description,
            }))
          : [],
      }));
      setData(mappedData);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = dataWithKey.filter(
          (item) => item.key !== key,
        );
        fetchContracts();
        setData(updatedDataSource);
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: IContractulInformation) => {
    if (record.contractualArrangement_id === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The Contractual Arrangement ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the Contractual Arrangement with ID ${record.contractualArrangement_id}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response =
            await contractual_informationOp.inactivateContractualArrangement([
              record.contractualArrangement_id,
            ]);
          fetchContracts();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  const fetchCurrencies = async () => {
    try {
      const response = await entityOp.fetchCurrencies();
      if (response?.data.currencies) {
        const formattedOptions = response.data.currencies.map(
          (reasons: { currency_name: any; id: any }) => ({
            label: reasons.currency_name,
            value: reasons.id,
          }),
        );
        setCurrencies(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBranches = async () => {
    try {
      const response = await entityOp.fetchBranches();
      if (response?.data) {
        const formattedOptions = response.data.map(
          (reasons: { branch_name: any; id: any }) => ({
            label: reasons.branch_name,
            value: reasons.id,
          }),
        );
        setBranches(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const shouldFetch =
    selectedEntityForContractualInformation &&
    (selectedBranchForContractualInformation ||
      selectedICTForContractualInformation ||
      selectedTPPForContractualInformation);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchContractualInformation();
        // TODO
        await Promise.all([
          fetchContracts(),
          fetchTerminatReasons(),
          fetchCountries(),
          fetchTPPbyEntity(),
          fetchCurrencies(),
          fetchContractualArrangements(),
          fetchContractualArrangementTypes(),
          fetchBranches(),
        ]);
      } catch (error) {
        console.error('Error during data fetching:', error);
      }
    };
    if (shouldFetch) {
      fetchData();
    }
  }, [
    selectedEntityForContractualInformation,
    selectedICTForContractualInformation,
    selectedBranchForContractualInformation,
    selectedTPPForContractualInformation,
    query,
  ]);

  const addNewCost = (rowKey: string | number) => {
    const newCost: ICosts = {
      costs_id: generateUniqueId(),
      costs_currency_amount_reported: '',
      costs_annual_expense: '',
      costs_total_annual_expense: '',
      costs_description: '',
      costs_currency_id: '',
      costs_contractual_arrangement_id: '',
      costs_third_party_service_provider_id: '',
      costs_start_date: '',
      costs_end_date: '',
      currency_name: '',
      currency_description: '',
      currency_id: '',
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.contractualArrangement_id === rowKey) {
          return {
            ...record,
            costs: [...record.costs, { ...newCost }],
          };
        }
        return record;
      }),
    );
  };
  const addNewContract = (rowKey: string | number) => {
    const newContract: IContracts = {
      contract_third_party_service_provider_id: '',
      contract_id: generateUniqueId(),
      contract_name: '',
      contract_description: '',
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.contractualArrangement_id === rowKey) {
          return {
            ...record,
            contracts: [...record.contracts, { ...newContract }],
          };
        }
        return record;
      }),
    );
  };

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.contractualArrangement_id,
  }));

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  let uniqueIdCounter = 0;
  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now() % 1_000_000_000;
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId =
      (timestampPart * 1000 + randomPart + uniqueIdCounter) % 2_147_483_648;

    return uniqueId;
  };

  const fetchTPPbyEntity = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      if (response?.data.third_party_service_providers) {
        const formattedOptions =
          response.data.third_party_service_providers.map(
            (reasons: { third_party_service_provider_name: any; id: any }) => ({
              label: reasons.third_party_service_provider_name,
              value: reasons.id,
            }),
          );
        setTPPData(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addNewRow = async () => {
    const terminatReasons = await fetchTerminatReasons();
    const countries = await fetchCountries();
    const tpps = await fetchTPPbyEntity();
    const currencies = await fetchCurrencies();
    const arrangementTypes = await fetchContractualArrangementTypes();
    const contractualInformation = await fetchContractualArrangements();
    const branches = await fetchBranches();

    const contractType_id = generateUniqueId();
    const newRowTemplate: IContractulInformation = {
      key: generateUniqueId(),
      isNew: true,
      terminatReason_id: '',
      terminatReason_name: '',
      terminatReason_description: '',
      typeofContractualArrangement_id: '',
      typeofContractualArrangement_name: '',
      typeofContractualArrangement_description: '',
      contractType_id: contractType_id,
      contractType_name: '',
      contractType_description: '',
      costs: [
        {
          costs_id: generateUniqueId(),
          costs_currency_amount_reported: '',
          costs_annual_expense: '',
          costs_total_annual_expense: '',
          costs_description: '',
          costs_currency_id: '',
          costs_contractual_arrangement_id: '',
          costs_third_party_service_provider_id: '',
          costs_start_date: '',
          costs_end_date: '',
          currency_name: '',
          currency_description: '',
          currency_id: '',
        },
      ],
      contracts: [
        {
          contract_third_party_service_provider_id: '',
          contract_id: generateUniqueId(),
          contract_name: '',
          contract_description: '',
        },
      ],
      country_id: '',
      country_country_name: '',
      country_iso_code: '',
      country_description: '',
      contractualArrangement_id: generateUniqueId(),
      contractualArrangement_arrangement_reference_number: '',
      contractualArrangement_start_date: '',
      contractualArrangement_end_date: '',
      contractualArrangement_terminat_reason_id: '',
      contractualArrangement_contract_type_id: contractType_id,
      contractualArrangement_contract_type_arrangement_id: '',
      contractualArrangement_overarching_reference_number: '',
      contractualArrangement_linked_contractual_arrangement: '',
      contractualArrangement_description: '',
      contractualArrangement_governing_law_country_id: '',
      contractualArrangement_branch_id: '',
      contractualArrangement_entity_id: query.entityId,
      contractualArrangement_third_party_service_provider_id: '',
    };

    const newRow = structuredClone(newRowTemplate);

    setData((prevData) => [...prevData, newRow]);
    setChangedData((prevChangedData) => [...prevChangedData, newRow]);
  };
  const handleOpenTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    rowKey: string,
  ) => {
    const { value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    const updatedRow = data.find(
      (row) => row.contractualArrangement_id.toString() == rowKey,
    );

    if (updatedRow) {
      const updatedRowWithChanges: Record<string, any> = {
        ...updatedRow,
        [fieldName]: value,
      };
      if (fieldName === 'costs_id' || 'contract_id') {
        if (updatedRow.costs) {
          const match = fieldName.match(/^(\d+)_c_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.costs.findIndex(
              (cost) => cost.costs_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.costs[targetIndex] = {
                ...updatedRow.costs[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching cost found for idFromFieldName (${idFromFieldName}) in cost`,
              );
            }
          }
        }
        if (updatedRow.contracts) {
          const match = fieldName.match(/^(\d+)_newContract_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.contracts.findIndex(
              (contract) => contract.contract_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.contracts[targetIndex] = {
                ...updatedRow.contracts[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching contract found for idFromFieldName (${idFromFieldName}) in contracts`,
              );
            }
          }
        }
      }

      const cleanedUpRow = Object.keys(updatedRowWithChanges).reduce(
        (acc: Record<string, any>, key) => {
          const newKey = key.replace(/^[^a-zA-Z]+/, '');
          acc[newKey] = updatedRowWithChanges[key];
          return acc;
        },
        {},
      );

      setChangedData((prevChangedData) => {
        const newChangedData = [...prevChangedData];
        const existingRowIndex = newChangedData.findIndex(
          (row) =>
            row.contractualArrangement_id ===
            cleanedUpRow.contractualArrangement_id,
        );

        if (existingRowIndex !== -1) {
          const existingRow = newChangedData[existingRowIndex];
          const mergedRow = { ...existingRow, ...cleanedUpRow };
          if (JSON.stringify(existingRow) !== JSON.stringify(mergedRow)) {
            newChangedData[existingRowIndex] = mergedRow;
          }
        } else {
          newChangedData.push(cleanedUpRow);
        }

        return newChangedData;
      });
    } else {
      console.log('No match found for contractualArrangement_id:', rowKey);
    }
  };
  const updateChangedData = (editedData: any, changedData: any) => {
    if (editedData && typeof editedData === 'object') {
      Object.keys(editedData).forEach((key: string) => {
        const keyFromEditedData = parseInt(key.split('_')[0]);
        const matchingChangedRow = changedData.find((dataItem: any) => {
          return dataItem.contractualArrangement_id === keyFromEditedData;
        });

        if (matchingChangedRow) {
          const fieldName = key.replace(/^\d+_/, '');
          if (matchingChangedRow.hasOwnProperty(fieldName)) {
            matchingChangedRow[fieldName] = editedData[key];
          }
        }
      });
    } else {
      console.error('editedData is not an object:', editedData);
    }

    setChangedData([...changedData]);
  };

  useEffect(() => {
    updateChangedData(editedData, changedData);
  }, [editedData]);

  const handleSubmit = async () => {
    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description:
          'There are no new or edited Contractual Informations to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    const formattedData = {
      tpp_name: selectedTPPForContractualInformation?.label,
      branch_name: selectedBranchForContractualInformation?.label,
      ict_service_id: selectedICTForContractualInformation?.value,
      entity_name: query.entity,

      contract_type: changedData.map((item) => ({
        id: item.contractType_id,
        name: item.contractType_name,
        description: item.contractType_description,
      })),

      terminat_reason: changedData.map((item) => ({
        id: item.terminatReason_id,
        name: item.terminatReason_name,
        description: item.terminatReason_description,
      })),

      type_of_contractual_arrangement: changedData.map((item) => ({
        id: item.typeofContractualArrangement_id,
        name: item.typeofContractualArrangement_name,
        description: item.typeofContractualArrangement_description,
      })),

      contractual_arrangement: changedData.map((item) => {
        return {
          id: item?.contractualArrangement_id,
          arrangement_reference_number:
            item?.contractualArrangement_arrangement_reference_number,
          start_date: item?.contractualArrangement_start_date,
          end_date: item?.contractualArrangement_end_date,
          terminat_reason_id: item?.contractualArrangement_terminat_reason_id,
          contract_type_id: item?.contractualArrangement_contract_type_id,
          contract_type_arrangement_id:
            item?.contractualArrangement_contract_type_arrangement_id,
          overarching_reference_number:
            item?.contractualArrangement_overarching_reference_number,
          linked_contractual_arrangement:
            item?.contractualArrangement_linked_contractual_arrangement,
          description: item?.contractualArrangement_description,
          governing_law_country_id:
            item?.contractualArrangement_governing_law_country_id,
          branch_id: selectedBranchForContractualInformation
            ? selectedBranchForContractualInformation.value
            : item.contractualArrangement_branch_id,
          entity_id: query.entityId,
          third_party_service_provider_id: selectedTPPForContractualInformation
            ? selectedTPPForContractualInformation.value
            : item?.contractualArrangement_third_party_service_provider_id,
        };
      }),

      countries: changedData.map((item) => {
        return {
          id: item.country_id,
          country_name: item.country_country_name,
          iso_code: item.country_iso_code,
          description: item.country_description,
        };
      }),

      contract: changedData.flatMap(
        (item) =>
          item.contracts?.map((contract: IContracts) => ({
            id: contract.contract_id,
            name: contract.contract_name,
            description: contract.contract_description,
            third_party_service_provider_id:
              selectedTPPForContractualInformation
                ? selectedTPPForContractualInformation.value
                : contract.contract_third_party_service_provider_id,
          })) || [],
      ),
      costs: changedData.flatMap(
        (item) =>
          item.costs?.map((cost: ICosts) => ({
            id: cost.costs_id,
            currency_amount_reported: cost.costs_currency_amount_reported,
            annual_expense: cost.costs_annual_expense,
            total_annual_expense: cost.costs_total_annual_expense,
            description: cost.costs_description,
            currency_id: cost.costs_currency_id,
            contractual_arrangement_id: cost.costs_contractual_arrangement_id,
            third_party_service_provider_id:
              selectedTPPForContractualInformation
                ? selectedTPPForContractualInformation.value
                : cost.costs_third_party_service_provider_id,
            start_date: cost.costs_start_date,
            end_date: cost.costs_end_date,
          })) || [],
      ),

      currency: changedData.flatMap(
        (item) =>
          item.costs?.map((cost: ICosts) => ({
            currency_name: cost.currency_name,
            description: cost.currency_description,
            id: cost.currency_id,
          })) || [],
      ),
      token: isLoggedIn,
    };
    try {
      const response =
        await contractual_informationOp.postContractualInformation(
          formattedData,
        );
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const showAddRowButton = shouldFetch && data?.length > 0;

  const columns: EditableColumnType<IContractulInformation>[] = [
    {
      title: (
        <Styled.TitleComponent>
          Third Party Service Provider
          <Styled.Asterisx>*</Styled.Asterisx>
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_third_party_service_provider_id',
      editable: true,
      fixed: 'left',
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_third_party_service_provider_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={tppData}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_third_party_service_provider_id
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <>
          Contracts<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'contracts',
      key: 'contracts',
      fixed: 'left',
      render: (_, record) => {
        return (
          <div>
            {(record.contracts as IContracts[]).map((contract: IContracts) => {
              const idFieldName = `${contract.contract_id}_newcontract_costs_id`;
              const contractNameFieldName = `${contract.contract_id}_newContract_contract_name`;
              const descriptionFieldName = `${contract.contract_id}_newContract_contract_description`;
              const tppFieldName = `${contract.contract_id}_newContract_contract_third_party_service_provider_id`;
              const rowKey = record.key;
              const handleRemoveContract = (
                contractKey: number,
                contractID: number,
              ) => {
                Modal.confirm({
                  title: 'Are you sure you want to remove this Contract?',
                  content: 'This action cannot be undone.',
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: async () => {
                    try {
                      const response =
                        await contractual_informationOp.inactivateContract([
                          contractID,
                        ]);
                      const updatedContracts = data.map((data) => {
                        if (data.key === contractKey) {
                          const updatedData = data.datas
                            ? data.datas.filter(
                                (contract: IContracts) =>
                                  contract.contract_id !== contractID,
                              )
                            : [];

                          return {
                            ...data,
                            datas: updatedData,
                          };
                        }
                        return data;
                      });
                      setData(updatedContracts);
                      fetchContracts();
                    } catch (error) {
                      console.error('Failed to remove Data:', error);
                    }
                  },
                  onCancel: () => {
                    notification.info({
                      message: 'Action Canceled',
                      description: 'The Contract removal has been canceled.',
                      duration: 10,
                    });
                  },
                });
              };
              return (
                <Styled.TooltipContainer
                  key={contract.contract_id}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                        <Button
                          size="small"
                          type="text"
                          danger
                          onClick={() =>
                            handleRemoveContract(
                              record.key,
                              contract.contract_id,
                            )
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          Name <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(contractNameFieldName)
                              ? typeof editedData[contractNameFieldName] ===
                                'boolean'
                                ? editedData[contractNameFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[contractNameFieldName])
                              : String(contract.contract_name)
                          }
                          onChange={(e) =>
                            handleEditChange(e, contractNameFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(contract.contract_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Third Party Service Provider</strong>
                        <CustomSelect
                          options={tppData}
                          placeholder="Select an option"
                          value={
                            editedData.hasOwnProperty(tppFieldName)
                              ? editedData[tppFieldName]
                              : contract.contract_third_party_service_provider_id
                          }
                          onChange={(value: string) => {
                            const event = {
                              target: {
                                value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;

                            handleEditChange(event, tppFieldName, rowKey);
                          }}
                          size="large"
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(contractNameFieldName)
                          ? typeof editedData[contractNameFieldName] ===
                            'boolean'
                            ? editedData[contractNameFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[contractNameFieldName])
                          : String(contract.contract_name)
                      }
                      readOnly
                      addonAfter={<PlusCircleOutlined />}
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewContract(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Contractual arrangement reference number
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the contractual arrangement between the financial entity or, in case of a group, the group subsidiary and the direct ICT third-party service provider.
The contractual arrangement reference number is the internal reference number of the contractual arrangement assigned by the financial entity.
The contractual arrangement reference number shall be unique and consistent over time at entity, sub-consolidated and consolidated level, where applicable.
The contractual arrangement reference number shall be used consistently across all templates of the register of information when referring to the same contractual arrangement.
For the case where an entity is acting on behalf of a financial entity for all the activities of the financial entity including the ICT services (refer to recital 7) the contractual arrangement reference number can be the contractual arrangement between the entity and its direct ICT third-party service provider.
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_arrangement_reference_number',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_arrangement_reference_number`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_arrangement_reference_number
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Start date of the contractual arrangement
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the date of entry into force of the contractual arrangement as stipulated in the contractual arrangement using the ISO 8601 (yyyy–mm–dd) code
(date)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_start_date',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_start_date`;
        const rowKey = record.key;
        return (
          <DynamicField
            value={
              editedData.hasOwnProperty(fieldName)
                ? String(editedData[fieldName] ?? '')
                : String(record.contractualArrangement_start_date ?? '')
            }
            fieldName={fieldName}
            rowKey={record.key}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEditChange(e, fieldName, record.key)
            }
            type="date"
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          End date of the contractual arrangement
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the end date as stipulated in the contractual arrangement using the ISO 8601 (yyyy–mm–dd) code. Where the contractual arrangement is indefinite, it shall be filled in with ‘9999-12-31’. Where the contractual arrangement has been terminated on a date different than the end date, this shall be filled in with the termination date.
Where the contractual arrangement foresees a renewal, this shall be filled in with the date of the contract renewal as stipulated in the contractual arrangement.
(date)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_end_date',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_end_date`;
        const rowKey = record.key;
        return (
          <DynamicField
            value={
              editedData.hasOwnProperty(fieldName)
                ? String(editedData[fieldName] ?? '')
                : String(record.contractualArrangement_end_date ?? '')
            }
            fieldName={fieldName}
            rowKey={record.key}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEditChange(e, fieldName, record.key)
            }
            type="date"
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Overarching contractual arrangement reference number
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Not applicable if the contractual arrangement is the ‘overarching contractual arrangement’ or a ‘standalone arrangement’. In the other cases, report the contractual arrangement reference number of the overarching arrangement, which shall be equal to the value as reported in column B_02.01.0010 when reporting the overarching contractual arrangement.
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_overarching_reference_number',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_overarching_reference_number`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_overarching_reference_number
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Contractual arrangement linked to the contractual arrangement referred
          in B_02.03.0010
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Contractual arrangement reference number of the contractual arrangement between the ICT intra-group service provider of the contractual arrangement in B_02.03.0010 and its direct ICT third-party service provider.
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_linked_contractual_arrangement',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_linked_contractual_arrangement`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_linked_contractual_arrangement
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'contractualArrangement_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Reason of the termination or ending of the contractual arrangement
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Where the contractual arrangement has been terminated or ended, identify the reason of the termination or ending of the contractual arrangements using one of the options in the following closed list:
1.	Termination not for cause: The contractual arrangement has expired/ended and has not been renewed by any of the parties;
2.	Termination for cause: The contractual arrangement has been terminated, the ICT third-party service provider being in a breach of applicable law, regulations or contractual provisions;
3.	Termination for cause: The contractual arrangement has been terminated, due to the fact that impediments of the ICT third-party service provider capable of altering the supported function have been identified;
4.	Termination for cause: The contractual arrangement has been terminated due to weaknesses of the ICT third-party service provider regarding the management and security of sensitive data or information of any of the counterparties;
5.	Termination following a request by a competent authority: The contractual arrangement has been terminated following a request by a competent Authority.
6.	Other: The contractual arrangement has been terminated by any of the parties for any other reason than the reasons referred to in points 1 to 5.
(closed set of options)
(mandatory if the contractual arrangement is terminated)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'terminatReason_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_terminatReason_name`;
        const fieldNameId = `${record.contractualArrangement_id}_terminatReason_id`;
        const fieldNameIdContract = `${record.contractualArrangement_id}_contractualArrangement_terminat_reason_id`;

        const rowKey = record.key;
        return (
          <CustomSelect
            options={terminatReasons}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.terminatReason_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldNameId, rowKey);
              handleEditChange(idEvent, fieldNameIdContract, rowKey);
              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: 'Terminat reason description',
      dataIndex: 'terminatReason_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_terminatReason_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.terminatReason_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Type of contractual arrangement
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the type of contractual arrangement by using one of the options in the following closed list:
1.	standalone arrangement;
2.	overarching / master contractual arrangement;
3.	subsequent or associated arrangement.
(closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'typeofContractualArrangement_name',
      editable: true,

      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_typeofContractualArrangement_name`;
        const fieldNameId = `${record.contractualArrangement_id}_typeofContractualArrangement_id`;
        const fieldNameIdArrangement = `${record.contractualArrangement_id}_contractualArrangement_contract_type_arrangement_id`;

        const rowKey = record.key;
        return (
          <CustomSelect
            options={contractualArrangementTypes}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.typeofContractualArrangement_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldNameId, rowKey);
              handleEditChange(idEvent, fieldNameIdArrangement, rowKey);

              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },

    {
      title: 'Type of contractual arrangement description',
      dataIndex: 'typeofContractualArrangement_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_typeofContractualArrangement_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.typeofContractualArrangement_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Contract type name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'contractType_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractType_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractType_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Contract type description',
      dataIndex: 'contractType_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractType_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractType_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Costs<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'costs',
      key: 'costs',
      render: (_, record) => {
        return (
          <div>
            {(record.costs as ICosts[]).map((cost: ICosts) => {
              const idFieldName = `${cost.costs_id}_c_costs_id`;
              const currencyAmountReportedFieldName = `${cost.costs_id}_c_costs_currency_amount_reported`;
              const annualExpenseFieldName = `${cost.costs_id}_c_costs_annual_expense`;
              const totalAnnualExpenseFieldName = `${cost.costs_id}_c_costs_total_annual_expense`;
              const descriptionFieldName = `${cost.costs_id}_c_costs_description`;
              const currencyNameFieldName = `${cost.costs_id}_c_currency_name`;
              const currencyDescriptionFieldName = `${cost.costs_id}_c_currency_description`;
              const startDateFieldName = `${cost.costs_id}_c_costs_start_date`;
              const endDateFieldName = `${cost.costs_id}_c_costs_end_date`;
              const tppFieldName = `${cost.costs_id}_c_costs_third_party_service_provider_id`;
              const currencyIdFieldName = `${cost.costs_id}_c_costs_currency_id`;
              const currencyMainIdFieldName = `${cost.costs_id}_c_currency_id`;
              const contractualArrangementFieldName = `${cost.costs_id}_c_costs_contractual_arrangement_id`;
              const rowKey = record.key;
              const handleRemoveCost = (costKey: number, costID: number) => {
                Modal.confirm({
                  title: 'Are you sure you want to remove this Cost?',
                  content: 'This action cannot be undone.',
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: async () => {
                    try {
                      const response =
                        await contractual_informationOp.inactivateCosts([
                          costID,
                        ]);
                      const updatedContracts = data.map((data) => {
                        if (data.key === costKey) {
                          const updatedData = data.datas
                            ? data.datas.filter(
                                (cost: ICosts) => cost.costs_id !== costID,
                              )
                            : [];

                          return {
                            ...data,
                            datas: updatedData,
                          };
                        }
                        return data;
                      });
                      setData(updatedContracts);
                      fetchContracts();
                    } catch (error) {
                      console.error('Failed to remove the Cost:', error);
                    }
                  },
                  onCancel: () => {
                    notification.info({
                      message: 'Action Canceled',
                      description: 'The Cost removal has been canceled.',
                      duration: 10,
                    });
                  },
                });
              };
              return (
                <Styled.TooltipContainer
                  key={cost.costs_id}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                        <Button
                          size="small"
                          type="text"
                          danger
                          onClick={() =>
                            handleRemoveCost(record.key, cost.costs_id)
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>Currency</strong>
                        <CustomSelect
                          options={currencies}
                          placeholder="Select an option"
                          value={
                            editedData.hasOwnProperty(currencyIdFieldName)
                              ? editedData[currencyIdFieldName]
                              : cost.costs_currency_id
                          }
                          onChange={(selectedOption: {
                            value: any;
                            label: any;
                          }) => {
                            const valueEvent = {
                              target: {
                                value: selectedOption.value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;

                            const labelEvent = {
                              target: {
                                value: selectedOption.label,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;

                            handleEditChange(
                              valueEvent,
                              currencyIdFieldName,
                              rowKey,
                            );
                            handleEditChange(
                              labelEvent,
                              currencyNameFieldName,
                              rowKey,
                            );
                            handleEditChange(
                              labelEvent,
                              currencyMainIdFieldName,
                              rowKey,
                            );
                          }}
                          size="large"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Currency description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              currencyDescriptionFieldName,
                            )
                              ? typeof editedData[
                                  currencyDescriptionFieldName
                                ] === 'boolean'
                                ? editedData[currencyDescriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[currencyDescriptionFieldName],
                                  )
                              : String(cost.currency_description)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              currencyDescriptionFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Currency of the amount reported in B_02.01.0050
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Identify the ISO 4217 alphabetic code of the currency used to express the amount in B_02.01.0050.
(currency)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              currencyAmountReportedFieldName,
                            )
                              ? typeof editedData[
                                  currencyAmountReportedFieldName
                                ] === 'boolean'
                                ? editedData[currencyAmountReportedFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[currencyAmountReportedFieldName],
                                  )
                              : String(cost.costs_currency_amount_reported)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              currencyAmountReportedFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Annual expense or estimated cost of the contractual
                            arrangement for the past year
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Annual expenses or estimated costs (or intragroup transfer) of the ICT services contractual arrangement for the past year. Monetary value shall be reported in units.
The annual expense or estimated cost shall be expressed in the currency reported in B_01.02.0040.
In case of an overarching arrangement with subsequent or associated arrangements, the sum of the annual expenses or estimated costs reported for the overarching arrangement and the subsequent or associated arrangements shall be equal to the total expenses or estimated costs for the overall contractual arrangement. There shall be no repetition or duplication of annual expenses or estimated costs. The following cases should be reflected:
(a) where the annual expenses or estimate costs are not determined at the level of the overarching arrangement (i.e. they are 0), the annual expenses or estimated costs shall be reported at the level of each subsequent or associated arrangements.
(b)	where the annual expenses or estimated costs cannot be reported for each of the subsequent or associated arrangements, the total annual expenses or estimated costs shall be reported at the level of the overarching arrangement.
(c)	where there are annual expenses or estimated costs related to each level of the arrangement, i.e. overarching and subsequent or associated, and that information is available, the annual expenses or estimated costs shall be reported without duplication at each level of the contractual arrangement.
(monetary)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(annualExpenseFieldName)
                              ? typeof editedData[annualExpenseFieldName] ===
                                'boolean'
                                ? editedData[annualExpenseFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[annualExpenseFieldName])
                              : String(cost.costs_annual_expense)
                          }
                          onChange={(e) =>
                            handleEditChange(e, annualExpenseFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Total annual expense or estimated cost of the ICT
                            third-party service provider
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Annual expense or estimated cost for using the ICT services provided by the ICT third-party service provider to the entities making use of the ICT services. Monetary value shall be reported in units.
(monetary)
(mandatory if the ICT third- party service provider is a direct ICT third-party service provider)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              totalAnnualExpenseFieldName,
                            )
                              ? typeof editedData[
                                  totalAnnualExpenseFieldName
                                ] === 'boolean'
                                ? editedData[totalAnnualExpenseFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[totalAnnualExpenseFieldName],
                                  )
                              : String(cost.costs_total_annual_expense)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              totalAnnualExpenseFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Start date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={(() => {
                            const resolvedValue = editedData.hasOwnProperty(
                              startDateFieldName,
                            )
                              ? String(editedData[startDateFieldName])
                              : String(cost.costs_start_date);
                            return resolvedValue;
                          })()}
                          fieldName={startDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, startDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          End date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(endDateFieldName)
                              ? String(editedData[endDateFieldName] ?? '')
                              : String(cost.costs_end_date ?? '')
                          }
                          fieldName={endDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, endDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(cost.costs_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>

                      <Styled.InputField>
                        <strong>Third Party Service Provider</strong>
                        <CustomSelect
                          options={tppData}
                          placeholder="Select an option"
                          value={
                            tppData.find(
                              (option) =>
                                option.value ===
                                (editedData.hasOwnProperty(tppFieldName)
                                  ? editedData[tppFieldName]
                                  : cost.costs_third_party_service_provider_id),
                            )?.label
                          }
                          onChange={(value: string) => {
                            const event = {
                              target: {
                                value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;

                            handleEditChange(event, tppFieldName, rowKey);
                          }}
                          size="large"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Contractual Arrangement</strong>
                        <CustomSelect
                          options={contractualInformation}
                          placeholder="Select an option"
                          value={
                            editedData.hasOwnProperty(
                              contractualArrangementFieldName,
                            )
                              ? editedData[contractualArrangementFieldName]
                              : cost.costs_contractual_arrangement_id
                          }
                          onChange={(value: string) => {
                            const event = {
                              target: {
                                value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;

                            handleEditChange(
                              event,
                              contractualArrangementFieldName,
                              rowKey,
                            );
                          }}
                          size="large"
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(descriptionFieldName)
                          ? typeof editedData[descriptionFieldName] ===
                            'boolean'
                            ? editedData[descriptionFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[descriptionFieldName])
                          : String(cost.costs_description)
                      }
                      readOnly
                      addonAfter={<PlusCircleOutlined />}
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewCost(record.key)}
            />
          </div>
        );
      },
    },

    {
      title: (
        <Styled.TitleComponent>
          Branch
          <Styled.Asterisx>*</Styled.Asterisx>
        </Styled.TitleComponent>
      ),
      dataIndex: 'contractualArrangement_branch_id',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_contractualArrangement_branch_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={branches}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.contractualArrangement_branch_id
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    // {
    //   title: (
    //     <Styled.TitleComponent>
    //       Third Party Service Provider
    //       <Styled.Asterisx>*</Styled.Asterisx>
    //     </Styled.TitleComponent>
    //   ),
    //   dataIndex: 'contractualArrangement_third_party_service_provider_id',
    //   editable: true,
    //   render: (_: any, record: any) => {
    //     const fieldName = `${record.contractualArrangement_id}_contractualArrangement_third_party_service_provider_id`;
    //     const rowKey = record.key;
    //     return (
    //       <CustomSelect
    //         options={tppData}
    //         placeholder="Select an option"
    //         value={
    //           editedData.hasOwnProperty(fieldName)
    //             ? editedData[fieldName]
    //             : record.contractualArrangement_third_party_service_provider_id
    //         }
    //         onChange={(value: string) => {
    //           const event = {
    //             target: {
    //               value,
    //             },
    //           } as React.ChangeEvent<HTMLInputElement>;

    //           handleEditChange(event, fieldName, rowKey);
    //         }}
    //         size="large"
    //       />
    //     );
    //   },
    // },
    {
      title: (
        <Styled.TitleComponent>
          Country of the governing law of the contractual arrangement
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the country of the governing law of the contractual arrangement using the ISO 3166–1 alpha–2 code.
    (country)
    (mandatory if the ICT service is supporting a critical or important function)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'country_country_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_country_country_name`;
        const fieldNameId = `${record.contractualArrangement_id}_country_id`;
        const fieldNameIdGoverningLaw = `${record.contractualArrangement_id}_contractualArrangement_governing_law_country_id`;
        const rowKey = record.key;

        return (
          <>
            <CustomSelect
              value={
                editedData.hasOwnProperty(fieldName)
                  ? editedData[fieldName]
                  : record.country_country_name
              }
              options={countries}
              onChange={(
                value: string,
                option: { label: string; value: string },
              ) => {
                const idEvent = {
                  target: {
                    value: option.value,
                  },
                } as React.ChangeEvent<HTMLInputElement>;
                handleEditChange(idEvent, fieldNameId, rowKey);
                handleEditChange(idEvent, fieldNameIdGoverningLaw, rowKey);

                const nameEvent = {
                  target: {
                    value: option.label,
                  },
                } as React.ChangeEvent<HTMLInputElement>;
                handleEditChange(nameEvent, fieldName, rowKey);
                const selectedCountry = countries.find(
                  (country) => country.value === value,
                );
                if (selectedCountry) {
                  const newIsoCode = i18nIsoCountries.getAlpha2Code(
                    selectedCountry.label,
                    'en',
                  );
                  const isoEvent = {
                    target: { value: newIsoCode || '' },
                  } as React.ChangeEvent<HTMLInputElement>;
                  handleEditChange(
                    isoEvent,
                    `${record.contractualArrangement_id}_country_iso_code`,
                    rowKey,
                  );
                }
              }}
              filterOption={(input: string, option: { label: string }) =>
                option?.label?.toLowerCase().includes(input.toLowerCase())
              }
            />
          </>
        );
      },
    },
    {
      title: (
        <>
          Country iso code<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'country_iso_code',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_country_iso_code`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.country_iso_code
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Country description',
      dataIndex: 'country_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.contractualArrangement_id}_country_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.country_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button
              danger
              onClick={() => handleInactivate(record as IContractulInformation)}
            >
              Soft delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Styled.ButtonDiv isEmpty={dataWithKey.length === 0}>
        {query.entity && (
          <Styled.AreaButton type="primary" onClick={addNewRow}>
            Add Row
          </Styled.AreaButton>
        )}
      </Styled.ButtonDiv>
      <Styled.TooltipTableContainer>
        <Table
          dataSource={dataWithKey}
          rowKey="key"
          scroll={{ x: 'max-content' }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
          columns={columns}
        />
      </Styled.TooltipTableContainer>
      {dataWithKey.length > 0 && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      )}
    </>
  );
};

export default ContractualInformation;
