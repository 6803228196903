import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IActivitiesByTypeEntityDataType,
  ICompetentAuthorityDataType,
  ICountryComponentDataType,
  IEntityState,
  IEntityTitle,
  IEntityTypeData,
  IHierarchyInGroupType,
  INatureOfEntityDataType,
  IValueOfTotalAssetsDataType,
} from './types';
import initialGlobalState from './initialState';

const entitySlice = createSlice({
  name: 'entity',
  initialState: initialGlobalState(),
  reducers: {
    set_entities(state, action: PayloadAction<IEntityTitle[]>) {
      state.entities = action.payload;
    },
    set_isAddRow(state: IEntityState, action: PayloadAction<boolean>) {
      state.isAddRow = action.payload;
    },
    set_addRowCount(state: IEntityState, action: PayloadAction<number>) {
      state.addRowCount = action.payload;
    },
    set_entityType(state, action: PayloadAction<IEntityTypeData[]>) {
      state.entityType = action.payload;
    },
    set_hierarchyInGroup(
      state,
      action: PayloadAction<IHierarchyInGroupType[]>,
    ) {
      state.hierarchyInGroup = action.payload;
    },
    set_natureOfEntity(
      state,
      action: PayloadAction<INatureOfEntityDataType[]>,
    ) {
      state.natureOfEntity = action.payload;
    },
    set_countryComponent(
      state,
      action: PayloadAction<ICountryComponentDataType[]>,
    ) {
      state.countryComponent = action.payload;
    },
    set_competentAuthority(
      state,
      action: PayloadAction<ICompetentAuthorityDataType[]>,
    ) {
      state.competentAuthority = action.payload;
    },
    set_valueOfTotalAssets(
      state,
      action: PayloadAction<IValueOfTotalAssetsDataType[]>,
    ) {
      state.valueOfTotalAssets = action.payload;
    },
    set_activitiesByTypeEntity(
      state,
      action: PayloadAction<IActivitiesByTypeEntityDataType[]>,
    ) {
      state.activitiesByTypeEntity = action.payload;
    },
    set_isEntityAdded(state: IEntityState, action: PayloadAction<boolean>) {
      state.isEntityAdded = action.payload;
    },
    set_isHierarchyInGroupAdded(
      state: IEntityState,
      action: PayloadAction<boolean>,
    ) {
      state.isHierarchyInGroupAdded = action.payload;
    },
    set_isEntityTypesAdded(
      state: IEntityState,
      action: PayloadAction<boolean>,
    ) {
      state.isEntityTypesAdded = action.payload;
    },
    set_isNatureOfEntityAdded(
      state: IEntityState,
      action: PayloadAction<boolean>,
    ) {
      state.isNatureOfEntityAdded = action.payload;
    },
    set_isCompetentAuthorityAdded(
      state: IEntityState,
      action: PayloadAction<boolean>,
    ) {
      state.isCompetentAuthorityAdded = action.payload;
    },
    set_isCountryAdded(state: IEntityState, action: PayloadAction<boolean>) {
      state.isCountryAdded = action.payload;
    },
    set_isValueOfTotalAssetsAdded(
      state: IEntityState,
      action: PayloadAction<boolean>,
    ) {
      state.isValueOfTotalAssetsAdded = action.payload;
    },
    set_isActivitiesByEntityTypesAdded(
      state: IEntityState,
      action: PayloadAction<boolean>,
    ) {
      state.isActivitiesByEntityTypesAdded = action.payload;
    },
    set_isAddRowFilled(state: IEntityState, action: PayloadAction<boolean>) {
      state.isAddRowFilled = action.payload;
    },
    set_nameOfEntity(state: IEntityState, action: PayloadAction<string>) {
      state.nameOfEntity = action.payload;
    },
    set_idOfEntity(state: IEntityState, action: PayloadAction<number>) {
      state.idOfEntity = action.payload;
    },
  },
});
export const {
  set_entities,
  set_isAddRow,
  set_addRowCount,
  set_entityType,
  set_hierarchyInGroup,
  set_natureOfEntity,
  set_countryComponent,
  set_competentAuthority,
  set_valueOfTotalAssets,
  set_activitiesByTypeEntity,
  set_isEntityAdded,
  set_isHierarchyInGroupAdded,
  set_isEntityTypesAdded,
  set_isNatureOfEntityAdded,
  set_isCompetentAuthorityAdded,
  set_isCountryAdded,
  set_isValueOfTotalAssetsAdded,
  set_isActivitiesByEntityTypesAdded,
  set_isAddRowFilled,
  set_nameOfEntity,
  set_idOfEntity,
} = entitySlice.actions;
export default entitySlice;
