import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  arrangement_reference_number: string;
  linked_contractual_arrangement: string;
  name_of_entity: string;
}

interface B0203Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
      <p> Contractual arrangement reference number - <span>B.02.03.0010</span></p>
        <AdditionalInformation description="Reference number of the contractual arrangement between the entity making use of the ICT service(s) provided and the ICT intra-group service provider.
The contractual arrangement reference number shall be unique and consistent over time and across all the group.
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'arrangement_reference_number',
    key: 'arrangement_reference_number',
    fixed: 'left',
  },
  {
      title: (
        <Styled.AddttionalInfoContainer>
         <p>Contractual arrangement linked to the contractual arrangement referred in B_02.03.0010 - <span>B.02.03.0020</span></p>
          <AdditionalInformation description="Contractual arrangement linked to the contractual arrangement referred in B_02.03.0010" />
        </Styled.AddttionalInfoContainer>
      ),
    width: 100,
    dataIndex: 'linked_contractual_arrangement',
    key: 'linked_contractual_arrangement',
    fixed: 'left',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        Name of the financial entity
        <AdditionalInformation description="Legal name of the financial entity maintaining and updating the register of information
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'entity_name',
    key: 'entity_name',
  },
];

const B0203: React.FC<B0203Props> = ({ entityName }) => {
  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData =
        await reportingOp.fetchEntityContractualArrangements(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          arrangement_reference_number: item.arrangement_reference_number,
          linked_contractual_arrangement: item.linked_contractual_arrangement,
          name_of_entity: item.name_of_entity,
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching entity contractual arrangements:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0203;
