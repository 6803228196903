import ApiService from 'utils/ApiCalls';
import axios from 'axios';
import { notification } from 'antd';

const fetchTPPbyEntityName = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postTPPbyEntityName(entityTypeData);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching TPP:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const setNonSubstReason = async () => {
  try {
    const response = await ApiService.getNonSubstReason();
    return response;
  } catch (error) {
    console.error('Error setting the Value of Non Subst Reason:', error);
  }
};

const fetchNonSubstReason = async (tpp_name: string, tpp_id: string) => {
  try {
    const response = await ApiService.fetchNonSubstReason(tpp_name, tpp_id);
    if (response.data) {
      return response.data;
    } else {
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error fetching the Value of Non Subst Reason:', error);
  }
};

const setPersonType = async (tpp_name: string, tpp_id: string) => {
  try {
    const response = await ApiService.fetchPersonType(tpp_name, tpp_id);
    if (response.data) {
      return response.data;
    } else {
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error fetching the Value of Person Type:', error);
  }
};

const setCurrencyAndCosts = async (tpp_name: string, tpp_id: string) => {
  try {
    const response = await ApiService.fetchCurrencyAndCosts(tpp_name, tpp_id);
    if (response.data) {
      return response.data;
    } else {
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error fetching the Currency and Costs:', error);
  }
};

const setCertificates = async (tpp_name: string, tpp_id: string) => {
  try {
    const response = await ApiService.fetchCertificates(tpp_name, tpp_id);
    if (response.data) {
      return response.data;
    } else {
      console.error('Unexpected response structure:', response);
    }
  } catch (error) {
    console.error('Error fetching the Value of Certificates:', error);
  }
};
const fetchTPP = async (tpp: any) => {
  try {
    const response = await ApiService.postTPP(tpp);
    notification.success({
      message: response.status,
      description: 'Third Party Provider - successfuly saved',
      duration: 20,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const insertNonSubstReason = async (nonSubstReason: any) => {
  try {
    const response = await ApiService.postNonSubstReason(nonSubstReason);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching NonSubstReason:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const insertPersonTypes = async (personTypes: any) => {
  try {
    const response = await ApiService.postPersonTypes(personTypes);
    notification.success({
      message: response.status,
      description: 'Person types - successfuly saved',
      duration: 20,
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching person types:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const insertCurrencyAndCosts = async (currencyAndCosts: any) => {
  try {
    const response = await ApiService.postCurrencyAndCosts(currencyAndCosts);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching Currency and Costs:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const insertCertificates = async (certificates: any) => {
  try {
    const response = await ApiService.postCertificates(certificates);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        'Error fetching Certificates:',
        error.response?.data || error.message,
      );
    } else {
      console.error('An unexpected error occurred:', error);
    }
    throw error;
  }
};

const fetchValues = async () => {
  try {
    const response = await ApiService.getValueOfTotalAssets();
    return response;
  } catch (error) {
    console.error('Error fetching values api:', error);
  }
};

const fetchNonSubstReasons = async () => {
  try {
    const response = await ApiService.getNonSubstReasons();
    return response;
  } catch (error) {
    console.error('Error fetching non subst reasons api:', error);
  }
};

const fetchPersonTypes = async () => {
  try {
    const response = await ApiService.getListPersonTypes();
    return response;
  } catch (error) {
    console.error('Error fetching person types api:', error);
  }
};

const fetchCosts = async () => {
  try {
    const response = await ApiService.getCosts();
    return response;
  } catch (error) {
    console.error('Error fetching costs api:', error);
  }
};

const fetchContractualArrangements = async () => {
  try {
    const response = await ApiService.getContractualArrangements();
    return response;
  } catch (error) {
    console.error('Error fetching contractual arrangements api:', error);
  }
};

const fetchFunctions = async () => {
  try {
    const response = await ApiService.getFunctions();
    return response;
  } catch (error) {
    console.error('Error fetching functions api:', error);
  }
};

const fetchICTServices = async () => {
  try {
    const response = await ApiService.getICTServices();
    return response;
  } catch (error) {
    console.error('Error fetching ict services api:', error);
  }
};

const inactivateTPP = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateTPP(dataList);

    const { errors, deleted_tpps, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach((error: { tpp_id: number; error: string }) => {
        notification.error({
          message: `Error for TPP ID: ${error.tpp_id}`,
          description: error.error,
          duration: 10,
        });
      });
    } else if (deleted_tpps && deleted_tpps.length > 0) {
      notification.success({
        message: 'TPPs Inactivated Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate tpp. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate tpp');
  }
};

const inactivateCertificates = async (dataList: number[]) => {
  try {
    const response = await ApiService.inactivateCertificates(dataList);

    const { errors, deleted_certificates, message } = response.data;

    if (errors && errors.length > 0) {
      errors.forEach((error: { certificate_id: number; error: string }) => {
        if (!error.error.includes('Certificate not found')) {
          notification.error({
            message: `Error for Certificate ID: ${error.certificate_id}`,
            description: error.error,
            duration: 10,
          });
        }
      });
    } else if (deleted_certificates && deleted_certificates.length > 0) {
      notification.success({
        message: 'Licensed Certificate Successfully',
        description: message,
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Action Completed',
        description: message,
        duration: 10,
      });
    }

    return response;
  } catch (error) {
    notification.error({
      message: 'Inactivation Failed',
      description: 'Failed to inactivate Certificate. Please try again.',
      duration: 10,
    });

    throw new Error('Failed to inactivate Certificate');
  }
};

export default {
  fetchTPPbyEntityName,
  setNonSubstReason,
  fetchNonSubstReason,
  setPersonType,
  setCurrencyAndCosts,
  setCertificates,
  fetchTPP,
  insertNonSubstReason,
  insertPersonTypes,
  insertCurrencyAndCosts,
  insertCertificates,
  fetchValues,
  fetchNonSubstReasons,
  fetchPersonTypes,
  fetchCosts,
  fetchContractualArrangements,
  fetchFunctions,
  fetchICTServices,
  inactivateTPP,
  inactivateCertificates,
};
