import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Form, theme, Col, notification } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { globalOp } from 'store/ducks/Global';
import { logoSrc } from 'components/TitleBar/TitleBar.styled';
import * as Styled from './Login.styled';

const { useToken } = theme;

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (values: { email: string; password: string }) => {
    setLoading(true);
    const { email, password } = values;

    globalOp
      .login(email, password)
      .then((response) => {
        const token = response.token;
        // const role = response.user.roles;
        const role = ["Viewer"];
        console.log(role, 'rolessssss');
        localStorage.setItem('authToken', token);
        localStorage.setItem('role', JSON.stringify(role));
        navigate('/working_area');
      })
      .catch((error) => {
        notification.error({
          message: 'Login Failed',
          description: error?.response?.data?.error || 'An error occurred. Please try again.',
          placement: 'topRight',
          duration: 10,
        });
        form.setFields([
          { name: 'email', errors: ['Invalid email or password'] },
          { name: 'password', errors: ['Invalid email or password'] },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Styled.LoginContainer>
      <Styled.NavbarLogin>
        <Col span={8}>
          <img src={logoSrc} alt="logo" />
        </Col>
        <Col span={8}>
          <h1>ProgRisCom</h1>
          <p>
            <span>Progress -</span> <span>Risk -</span> <span>Compliance</span>
          </p>
        </Col>
      </Styled.NavbarLogin>
      <Styled.FormContainer>
        <Form form={form} name="login" onFinish={handleSubmit}>
          <Styled.ItemsContainer>
            <Styled.StyledFormItem
              token={token}
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                {
                  type: 'email',
                  message: 'The input is not valid email!',
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                type="email"
              />
            </Styled.StyledFormItem>
            <Styled.StyledFormItem
              token={token}
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password />
            </Styled.StyledFormItem>
          </Styled.ItemsContainer>

          <Styled.BottomSection>
            <Styled.StyledButton
              type="primary"
              loading={loading}
              htmlType="submit"
            >
              Sign In
            </Styled.StyledButton>

            <Styled.StyledLink to="/forgot_password">
              <span>Forgot your password?</span>
              <span>Click here</span>
            </Styled.StyledLink>
          </Styled.BottomSection>
        </Form>
      </Styled.FormContainer>
    </Styled.LoginContainer>
  );
};

export default LoginForm;
