import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification, Table, Input, Button, Modal } from 'antd';
import {
  PlusOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import {
  IData,
  ICertificate,
  IICTSystem,
  IICTServices,
  IICTLevels,
  EditedData,
} from 'store/ducks/DataEntry/ICTServices/types';
import useFilters from 'utils/hooks/useFilters';
import * as Styled from '../../DataEntry.styled';
import DynamicField from 'components/DynamicCalendarField/DynamicField';
import CustomSelect from 'components/CustomSelect';
import { globalOp } from 'store/ducks/Global';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import { setSelecedEntity_ICT } from 'store/ducks/DataEntry/ICTServices/ICTServiceSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { entityOp } from 'store/ducks/DataEntry/Entities';

const ICTServices: React.FC = () => {
  const [data, setData] = useState<IICTServices[]>([]);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const [ictServicesTypes, setICTServicesType] = useState([]);
  const [sensitivenessOfData, setSensitivenessOfData] = useState([]);
  const [levelsOfReliance, setLevelsOfReliance] = useState([]);
  const [criticalityAssessments, setCriticalityAssessments] = useState([]);
  const [substitutability, setSubstitubility] = useState([]);
  const [fetchTriggered, setFetchTriggered] = useState(false);
  const [possibility, setPossibility] = useState([]);
  const [countries, setCountries] = useState<
    { label: string; value: string }[]
  >([]);
  const [tppNames, setTppNames] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [functionNames, setFunctionNames] = useState<
    { label: string; value: string }[]
  >([]);
  const [services, setServices] = useState<{ label: string; value: string }[]>(
    [],
  );
  const { confirm } = Modal;
  const [impact, setImpact] = useState([]);
  const [alternatives, setAlternatives] = useState([]);
  const isLoggedIn = localStorage.getItem('authToken');
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const {
    selectedContractForICT,
    selectedEntityForICT,
    selectedFunctionForICT,
    selectedTPPForICT,
  } = useSelector((state: any) => state.ictServices);
  const dispatch = useDispatch();
  const { query } = useFilters();
  const isFetching = useRef(false);
  const fetchICT = ict_serviceOp.useFetchICTServices();

  useEffect(() => {
    if (!selectedEntityForICT) {
      const selectedEntity = {
        entityId: query.entityId ? Number(query.entityId) : 0,
        entity: query.entity ? String(query.entity) : '',
      };
      dispatch(setSelecedEntity_ICT(selectedEntity));
    }
  }, [query]);

  const fetchICTServices = async () => {
    try {
      const response = await fetchICT();
      const data = response?.data['ICT services'];
      if (data.length === 0) {
        notification.warning({
          message: 'Warning',
          description: response.data.message,
          placement: 'topRight',
          duration: 20,
        });
      }
      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        alternatives_exit_id: item.alternatives_exit.id,
        alternatives_exit_alternative_providers_identified:
          item.alternatives_exit.alternative_providers_identified,
        alternatives_exit_exit_plan_exists:
          item.alternatives_exit.exit_plan_exists,
        alternatives_exit_reintegration_possible:
          item.alternatives_exit.reintegration_possible,
        alternatives_exit_alternative_ict_tpp_identification:
          item.alternatives_exit.alternative_ict_tpp_identification,
        alternatives_exit_description: item.alternatives_exit.description,
        yesNo_id: item.storage_of_data.id,
        yesNo_status: item.storage_of_data.status,
        yesNo_description: item.storage_of_data.description,
        auswahlServicesDiscount_id: item.auswahl_services_discount.id,
        auswahlServicesDiscount_name: item.auswahl_services_discount.name,
        auswahlServicesDiscount_description:
          item.auswahl_services_discount.description,
        // ictServiceLevel_id: item.ict_service_level.id,
        // ictServiceLevel_name: item.ict_service_level.name,
        // ictServiceLevel_description: item.ict_service_level.description,
        ictServiceType_id: item.ict_service_type.id,
        ictServiceType_name: item.ict_service_type.name,
        ictServiceType_description: item.ict_service_type.description,
        impactOfDiscontinuingFunction_id:
          item.impact_of_discontinuing_function.id,
        impactOfDiscontinuingFunction_description:
          item.impact_of_discontinuing_function.description,
        impactOfDiscontinuingIctServices_id:
          item.impact_of_discontinuing_services.id,
        impactOfDiscontinuingIctServices_description:
          item.impact_of_discontinuing_services.description,
        levelOfRelianceICTService_id: item.level_of_reliance.id,
        levelOfRelianceICTService_name: item.level_of_reliance.name,
        levelOfRelianceICTService_description:
          item.level_of_reliance.description,
        levelofSupportingICTService_id: item.level_of_supporting_ict_service.id,
        levelofSupportingICTService_name:
          item.level_of_supporting_ict_service.name,
        levelofSupportingICTService_description:
          item.level_of_supporting_ict_service.description,
        criticality_assessment_id: item.criticality_assessment?.id || '',
        criticality_assessment_name: item.criticality_assessment?.name || '',
        criticality_assessment_description:
          item.criticality_assessment?.description || '',
        ictService_id: item.ict_service_id,
        ictService_ict_service_type_id: item.ict_service_type.id,
        ictService_third_party_service_provider_id:
          item.third_party_service_provider.id,
        ictService_description: item.ict_service_description,
        ictService_notice_period: item.notice_period,
        ictService_level_of_supporting_ict_service_id:
          item.level_of_supporting_ict_service.id,
        ictService_rank: item.rank,
        ictService_auswahl_services_discount_id:
          item.auswahl_services_discount.id,
        ictService_storage_of_data_id: item.storage_of_data.id,
        ictService_recipient_identification_code:
          item.recipient_identification_code,
        ictService_recipient_identification_type:
          item.recipient_identification_type,
        ictService_function_id: item.function ? item.function.id : '',
        ictService_country_id: item.country.id,
        // ictService_ict_service_level_id: item.ict_service_level.id,
        ictService_impact_of_discontinuing_function_id:
          item.impact_of_discontinuing_function.id,
        ictService_impact_of_discontinuing_ict_services_id:
          item.impact_of_discontinuing_services.id,
        ictService_alternatives_exit_id: item.alternatives_exit.id,
        ictService_substitutability_of_ict_provider_id:
          item.substitutability.id,
        ictService_level_of_reliance_id: item.level_of_reliance.id,
        ictService_start_date: item.start_date,
        ictService_end_date: item.end_date,
        ict_systems: item.ict_systems
          ? item.ict_systems.map((ict_system: any) => ({
              ict_systems_id: ict_system.id,
              ict_systems_name: ict_system.name,
              ict_systems_description: ict_system.description,
              ict_systems_start_date: ict_system.start_date,
              ict_systems_end_date: ict_system.end_date,
            }))
          : [],

        certificates: item.certificates
          ? item.certificates.map((certificate: any) => ({
              certificates_id: certificate.id,
              certificates_description: certificate.description,
              certificates_validity: certificate.validity,
              certificates_date_of_issuance: certificate.date_of_issuance,
              certificates_registry: certificate.certificate_registry,
              certificates_date_of_certificate_register:
                certificate.date_of_certificate_register,
              certificates_third_party_service_provider_id:
                certificate.third_party_service_provider_id,
              certificates_function_id: certificate.function_id,
              certificates_ict_service_id: certificate.ict_service_id,
              certificates_end_date: certificate.end_date,
              certificates_start_date: certificate.start_date,
            }))
          : [],
        data: item.data
          ? item.data.map((iData: any) => ({
              sensitivenessOfDataStoredbyICTTPSP_id:
                iData.data_sensitiveness.data_stored_by_ict_tpsp.id,
              sensitivenessOfDataStoredbyICTTPSP_name:
                iData.data_sensitiveness.data_stored_by_ict_tpsp.name,
              sensitivenessOfDataStoredbyICTTPSP_description:
                iData.data_sensitiveness.data_stored_by_ict_tpsp.description,
              dataSensitiveness_id: iData.data_sensitiveness.id,
              dataSensitiveness_sensitiveness_name:
                iData.data_sensitiveness.name,
              dataSensitiveness_description:
                iData.data_sensitiveness.description,
              data_id: iData.id,
              data_location_of_data_at_rest: iData.location_of_data_at_rest,
              data_location_of_data_management:
                iData.location_of_data_management,
              data_sensitiveness_id: iData.data_sensitiveness.id,
              data_description: iData.description,
              data_ict_system_id: iData.ict_system_id,
              data_start_date: iData.start_date,
              data_end_date: iData.end_date,
            }))
          : [],
        ict_levels: item.ict_service_level
          ? item.ict_service_level.map((level: any) => ({
            ict_level_id: level.id,
            ict_level_name: level.name,
            ict_level_description: level.description,
            }))
          : [],
      }));
      setData(mappedData);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await entityOp.fetchCountries();
      if (response?.data.countries) {
        const formattedOptions = response.data.countries.map(
          (reasons: { country_name: any; id: any }) => ({
            label: reasons.country_name,
            value: reasons.id,
          }),
        );
        setCountries(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchICT();
        const timeout = setTimeout(() => {
          if (
            response?.data?.message ===
            'ICT services for the specified criteria do not exist.'
          ) {
            notification.error({
              message: 'No ICT Services',
              description: response?.data.message,
              duration: 20,
            });
          }
        }, 500);
        if (
          response?.data?.message ===
          'ICT services for the specified criteria do not exist.'
        ) {
          setData([]);
          clearTimeout(timeout);
          return;
        }
        await Promise.all([
          fetchICTServices(),
          fetchICTServiceTypes(),
          fetchSensitivinessOfData(),
          fetchLevelsOfReliance(),
          fetchCriticalityAssessments(),
          fetchSubstitutability(),
          fetchReintegrationPossibilities(),
          fetchImpactOfDiscontinuingICTServices(),
          fetchAlternativesExit(),
          fetchTPPID(),
          fetchFunctionNames(),
          fetchServices(),
          fetchCountries(),
        ]);
      } catch (error) {
        console.error('Error during data fetching:', error);
        notification.error({
          message: 'Data Fetch Error',
          description: 'An error occurred while fetching data.',
          duration: 20,
        });
      }
    };

    const shouldFetch =
      selectedEntityForICT &&
      (selectedTPPForICT || selectedContractForICT || selectedFunctionForICT);

    if (shouldFetch) {
      fetchData();
    }
  }, [
    selectedEntityForICT,
    selectedContractForICT,
    selectedFunctionForICT,
    selectedTPPForICT,
    query,
  ]);

  const addNewIctSystem = (rowKey: string | number) => {
    const newIctSystem: IICTSystem = {
      ict_systems_id: generateUniqueId(),
      ict_systems_name: '',
      ict_systems_description: '',
      ict_systems_start_date: '',
      ict_systems_end_date: '',
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.ictService_id === rowKey) {
          return {
            ...record,
            ict_systems: [...record.ict_systems, { ...newIctSystem }],
          };
        }
        return record;
      }),
    );
  };

    const addNewICTLevel = (rowKey: string | number) => {
    const newICTLevels: IICTLevels = {
      ict_level_id: generateUniqueId(),
      ict_level_name: '',
      ict_level_description: '',
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.ictService_id === rowKey) {
          return {
            ...record,
            ict_levels: [...record.ict_levels, { ...newICTLevels }],
          };
        }
        return record;
      }),
    );
  };
  const addNewCertificate = (rowKey: string | number) => {
    const newCertificate: ICertificate = {
      certificates_id: generateUniqueId(),
      certificates_description: '',
      certificates_validity: '',
      certificates_date_of_issuance: '',
      certificates_date_of_certificate_register: '',
      certificates_third_party_service_provider_id: '',
      certificates_function_id: '',
      certificates_ict_service_id: '',
      certificates_registry: '',
      certificates_end_date: '',
      certificates_start_date: '',
    };

    setData((prevData) => {
      return prevData.map((record) => {
        if (record.ictService_id === rowKey) {
          return {
            ...record,
            certificates: [...record.certificates, newCertificate],
          };
        }
        return record;
      });
    });
  };

  let uniqueIdCounter = 0;
  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now() % 1_000_000_000;
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId =
      (timestampPart * 1000 + randomPart + uniqueIdCounter) % 2_147_483_648;

    return uniqueId;
  };

  const dataSensitivenessId = generateUniqueId();
  const addNewData = (rowKey: string | number) => {
    const newData: IData = {
      sensitivenessOfDataStoredbyICTTPSP_id: '',
      sensitivenessOfDataStoredbyICTTPSP_name: '',
      sensitivenessOfDataStoredbyICTTPSP_description: '',
      dataSensitiveness_id: generateUniqueId(),
      dataSensitiveness_location_of_data_at_rest: '',
      dataSensitiveness_location_of_data_management: '',
      dataSensitiveness_sensitiveness_id: dataSensitivenessId,
      dataSensitiveness_sensitiveness_name: '',
      dataSensitiveness_description: '',
      dataSensitiveness_ict_system_id: generateUniqueId(),
      data_id: generateUniqueId(),
      data_location_of_data_at_rest: '',
      data_location_of_data_management: '',
      data_sensitiveness_id: dataSensitivenessId,
      data_description: '',
      data_ict_system_id: generateUniqueId(),
      data_start_date: '',
      data_end_date: '',
    };

    setData((prevData) => {
      return prevData.map((record) => {
        if (record.ictService_id === rowKey) {
          return {
            ...record,
            data: [...record.data, newData],
          };
        }
        return record;
      });
    });
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    rowKey: string,
  ) => {
    const { value } = e.target;

    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    const updatedRow = data.find(
      (row) => row.ictService_id.toString() == rowKey,
    );

    if (updatedRow) {
      const updatedRowWithChanges: Record<string, any> = {
        ...updatedRow,
        [fieldName]: value,
      };

      if (
        fieldName === 'ict_systems_id' ||
        'certificates_id' ||
        'data_id' ||
        'ict_level_id'
      ) {
        if (updatedRow.ict_systems) {
          const match = fieldName.match(/^(\d+)_systems_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];

            const targetIndex = updatedRow.ict_systems.findIndex(
              (system) => system.ict_systems_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.ict_systems[targetIndex] = {
                ...updatedRow.ict_systems[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching system found for idFromFieldName (${idFromFieldName}) in ict_systems`,
              );
            }
          }
        }
        if (updatedRow.certificates) {
          const match = fieldName.match(/^(\d+)_newcert_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];

            const targetIndex = updatedRow.certificates.findIndex(
              (cert) => cert.certificates_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.certificates[targetIndex] = {
                ...updatedRow.certificates[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching certificate found for idFromFieldName (${idFromFieldName}) in certificates`,
              );
            }
          }
        }
        if (updatedRow.data) {
          const match = fieldName.match(/^(\d+)_newItem_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.data.findIndex(
              (item) => item.data_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.data[targetIndex] = {
                ...updatedRow.data[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching data found for idFromFieldName (${idFromFieldName}) in datas`,
              );
            }
          }
        }
         if (updatedRow.ict_levels) {
          const match = fieldName.match(/^(\d+)_levels_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.ict_levels.findIndex(
              (item) => item.ict_level_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.ict_levels[targetIndex] = {
                ...updatedRow.ict_levels[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching data found for idFromFieldName (${idFromFieldName}) in ict levels`,
              );
            }
          }
        }
      }
      const cleanedUpRow = Object.keys(updatedRowWithChanges).reduce(
        (acc: Record<string, any>, key) => {
          const newKey = key.replace(/^[^a-zA-Z]+/, '');
          acc[newKey] = updatedRowWithChanges[key];
          return acc;
        },
        {},
      );

      setChangedData((prevChangedData) => {
        const newChangedData = [...prevChangedData];
        const existingRowIndex = newChangedData.findIndex(
          (row) => row.ictService_id === cleanedUpRow.ictService_id,
        );

        if (existingRowIndex !== -1) {
          const existingRow = newChangedData[existingRowIndex];
          const mergedRow = { ...existingRow, ...cleanedUpRow };
          if (JSON.stringify(existingRow) !== JSON.stringify(mergedRow)) {
            newChangedData[existingRowIndex] = mergedRow;
          }
        } else {
          newChangedData.push(cleanedUpRow);
        }

        return newChangedData;
      });
    } else {
      console.log('No match found for ictService_id:', rowKey);
    }
  };
  const updateChangedData = (editedData: any, changedData: any) => {
    if (editedData && typeof editedData === 'object') {
      Object.keys(editedData).forEach((key: string) => {
        const keyFromEditedData = parseInt(key.split('_')[0]);
        const matchingChangedRow = changedData.find((dataItem: any) => {
          return dataItem.ictService_id === keyFromEditedData;
        });

        if (matchingChangedRow) {
          const fieldName = key.replace(/^\d+_/, '');
          if (matchingChangedRow.hasOwnProperty(fieldName)) {
            matchingChangedRow[fieldName] = editedData[key];
          }
          if (
            fieldName.includes('certificates_start_date') ||
            fieldName.includes('certificates_end_date')
          ) {
            matchingChangedRow[fieldName] =
              editedData[key] !== undefined
                ? String(editedData[key] ?? '')
                : null;
          }
        }
      });
    } else {
      console.error('editedData is not an object:', editedData);
    }

    setChangedData([...changedData]);
  };

  useEffect(() => {
    updateChangedData(editedData, changedData);
  }, [editedData]);

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.ictService_id,
  }));
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  const addNewRow = () => {
    const ictServiceTypeId = generateUniqueId();
    const yesNo_id = generateUniqueId();
    const alternatives_exit_id = generateUniqueId();
    const newRowTemplate: IICTServices = {
      key: generateUniqueId(),
      isNew: true,
      yesNo_id: yesNo_id,
      yesNo_status: null,
      yesNo_description: '',
      auswahlServicesDiscount_id: generateUniqueId(),
      auswahlServicesDiscount_name: '',
      auswahlServicesDiscount_description: '',
      ictServiceType_id: ictServiceTypeId,
      ictServiceType_name: '',
      ictServiceType_description: '',
      levelofSupportingICTService_id: generateUniqueId(),
      levelofSupportingICTService_name: '',
      levelofSupportingICTService_description: '',
      ictService_id: generateUniqueId(),
      ictService_ict_service_type_id: ictServiceTypeId,
      ictService_third_party_service_provider_id: '',
      ictService_description: '',
      ictService_notice_period: '',
      ictService_level_of_supporting_ict_service_id: generateUniqueId(), // TODO
      ictService_rank: '',
      ictService_auswahl_services_discount_id: yesNo_id,
      ictService_storage_of_data_id: '',
      ictService_recipient_identification_code: '',
      ictService_recipient_identification_type: '',
      ictService_function_id: '',
      ictService_country_id: '',
      ictService_start_date: '',
      ictService_end_date: '',
      // ictService_ict_service_level_id: generateUniqueId(), //  TODO
      ictService_impact_of_discontinuing_function_id: '',
      ictService_impact_of_discontinuing_ict_services_id: '',
      ictService_alternatives_exit_id: alternatives_exit_id,
      ictService_substitutability_of_ict_provider_id: generateUniqueId(),
      ictService_level_of_reliance_id: '',
      alternatives_exit_id: alternatives_exit_id,
      alternatives_exit_alternative_providers_identified: '',
      alternatives_exit_exit_plan_exists: null, // TODOD
      alternatives_exit_reintegration_possible: '',
      alternatives_exit_alternative_ict_tpp_identification: '', //?
      alternatives_exit_description: '',
      impactOfDiscontinuingFunction_id: '',
      impactOfDiscontinuingFunction_description: '',
      impactOfDiscontinuingIctServices_id: '',
      impactOfDiscontinuingIctServices_description: '',
      levelOfRelianceICTService_id: '',
      levelOfRelianceICTService_name: '',
      levelOfRelianceICTService_description: '',
      // ictServiceLevel_id: generateUniqueId(), // TODO
      // ictServiceLevel_name: '',
      // ictServiceLevel_description: '',
      criticality_assessment_id: '',
      criticality_assessment_name: '',
      criticality_assessment_description: '',
      certificates: [
        {
          certificates_id: generateUniqueId(),
          certificates_description: '',
          certificates_validity: '',
          certificates_date_of_issuance: '',
          certificates_date_of_certificate_register: '',
          certificates_third_party_service_provider_id: '',
          certificates_function_id: '',
          certificates_ict_service_id: '',
          certificates_registry: '',
          certificates_end_date: '',
          certificates_start_date: '',
        },
      ],
      ict_systems: [
        {
          ict_systems_id: generateUniqueId(),
          ict_systems_name: '',
          ict_systems_description: '',
          ict_systems_start_date: '',
          ict_systems_end_date: '',
        },
      ],
      data: [
        {
          sensitivenessOfDataStoredbyICTTPSP_id: '',
          sensitivenessOfDataStoredbyICTTPSP_name: '',
          sensitivenessOfDataStoredbyICTTPSP_description: '',
          dataSensitiveness_id: generateUniqueId(),
          dataSensitiveness_location_of_data_at_rest: '',
          dataSensitiveness_location_of_data_management: '',
          dataSensitiveness_sensitiveness_id: dataSensitivenessId,
          dataSensitiveness_sensitiveness_name: '',
          dataSensitiveness_description: '',
          dataSensitiveness_ict_system_id: generateUniqueId(),
          data_id: generateUniqueId(),
          data_location_of_data_at_rest: '',
          data_location_of_data_management: '',
          data_sensitiveness_id: dataSensitivenessId,
          data_description: '',
          data_ict_system_id: generateUniqueId(),
          data_start_date: '',
          data_end_date: '',
        },
      ],
      ict_levels: [
        {
          ict_level_id: generateUniqueId(),
          ict_level_name: '',
          ict_level_description: '',
        },
      ],
      data_sensitiveness_name: '',
      data_sensitiveness_description: '',
    };
    const newRow = structuredClone(newRowTemplate);

    setData((prevData) => [...prevData, newRow]);
    setChangedData((prevChangedData) => [...prevChangedData, newRow]);
  };
  const handleOpenTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const fetchICTServiceTypes = async () => {
    try {
      const response = await ict_serviceOp.fetchICTServiceTypes();
      if (response?.data.ict_service_types) {
        const formattedOptions = response.data.ict_service_types.map(
          (types: { name: any; id: any }) => ({
            label: types.name,
            value: types.id,
          }),
        );
        setICTServicesType(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSensitivinessOfData = async () => {
    try {
      const response = await globalOp.fetchSensitivenessOfData();
      if (response?.data.sensitiveness_of_data) {
        const formattedOptions = response.data.sensitiveness_of_data.map(
          (sensitiveness: { name: any; id: any }) => ({
            label: sensitiveness.name,
            value: sensitiveness.id,
          }),
        );
        setSensitivenessOfData(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLevelsOfReliance = async () => {
    try {
      const response = await ict_serviceOp.fetchLevelsOfReliance();
      if (response?.data.levels_of_reliance) {
        const formattedOptions = response.data.levels_of_reliance.map(
          (levels: { name: any; id: any }) => ({
            label: levels.name,
            value: levels.id,
          }),
        );
        setLevelsOfReliance(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCriticalityAssessments = async () => {
    try {
      const response = await ict_serviceOp.fetchCriticalityAssessments();
      if (response?.data.criticality_assessments) {
        const formattedOptions = response.data.criticality_assessments.map(
          (assessments: { name: any; id: any }) => ({
            label: assessments.name,
            value: assessments.id,
          }),
        );
        setCriticalityAssessments(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubstitutability = async () => {
    try {
      const response = await globalOp.fetchSubstitutability();
      if (response?.data.substitutability_of_ict_provider) {
        const formattedOptions =
          response.data.substitutability_of_ict_provider.map(
            (subst: { name: any; id: any }) => ({
              label: subst.name,
              value: subst.id,
            }),
          );
        setSubstitubility(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReintegrationPossibilities = async () => {
    try {
      const response = await ict_serviceOp.fetchReintegrationPossibilities();
      if (response?.data.possibility_of_reintegration) {
        const formattedOptions = response.data.possibility_of_reintegration.map(
          (possibility: { description: any; id: any }) => ({
            label: possibility.description,
            value: possibility.id,
          }),
        );
        setPossibility(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchImpactOfDiscontinuingICTServices = async () => {
    try {
      const response =
        await ict_serviceOp.fetchImpactOfDiscontinuingICTServices();
      if (response?.data.impact_of_discontinuing_ict_services) {
        const formattedOptions =
          response.data.impact_of_discontinuing_ict_services.map(
            (impact: { description: any; id: any }) => ({
              label: impact.description,
              value: impact.id,
            }),
          );
        setImpact(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTPPID = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;
      const dataForTable = data.map((item: any) => ({
        label: item?.third_party_service_provider_name || 'Unknown',
        value: item?.id || '',
      }));
      setTppNames(dataForTable);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchFunctionNames = async () => {
    try {
      const response = await tppOp.fetchFunctions();
      if (response?.data.functions) {
        const formattedOptions = response.data.functions.map(
          (reasons: { function_name: any; id: any }) => ({
            label: reasons.function_name,
            value: reasons.id,
          }),
        );
        setFunctionNames(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await tppOp.fetchICTServices();
      if (response?.data.ict_services) {
        const formattedOptions = response.data.ict_services.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setServices(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = dataWithKey.filter(
          (item) => item.key !== key,
        );
        fetchICTServices();
        setData(updatedDataSource);
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: IICTServices) => {
    if (record.ictService_id === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The ict service ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the ict service with ID ${record.ictService_id}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await ict_serviceOp.inactivateICTService([
            record.ictService_id,
          ]);
          fetchICTServices();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  const fetchAlternativesExit = async () => {
    try {
      const response = await ict_serviceOp.fetchAlternativesExit();
      if (response?.data.alternatives_exit) {
        const formattedOptions = response.data.alternatives_exit.map(
          (alternatives: { description: any; id: any }) => ({
            label: alternatives.description,
            value: alternatives.id,
          }),
        );
        setAlternatives(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited ICT Services to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    const formattedData = {
      tpp_name: selectedTPPForICT?.label,
      function_name: selectedFunctionForICT?.label,
      contractual_arrangement_id: selectedContractForICT?.value,
      entity_name: selectedEntityForICT?.label,

      sensitiveness_of_data_stored_by_ict_tpsp: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.sensitivenessOfDataStoredbyICTTPSP_id,
            name: itemData.sensitivenessOfDataStoredbyICTTPSP_name,
            description:
              itemData.sensitivenessOfDataStoredbyICTTPSP_description,
          })),
        )
        .flat(),

      data_sensitiveness: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.data_sensitiveness_id,
            name: itemData.dataSensitiveness_sensitiveness_name,
            sensitiveness_of_data_stored_by_ict_tpsp_id:
              itemData.sensitivenessOfDataStoredbyICTTPSP_id,
            description: itemData.dataSensitiveness_id,
          })),
        )
        .flat(),

      data_entries: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.data_id,
            location_of_data_at_rest: itemData.data_location_of_data_at_rest,
            location_of_data_management:
              itemData.data_location_of_data_management,
            sensitiveness_id: itemData.dataSensitiveness_id,
            ict_system_id: itemData.data_ict_system_id,
            description: itemData.data_description,
            start_date: itemData.data_start_date,
            end_date: itemData.data_end_date,
          })),
        )
        .flat(),

      yes_no_entries: changedData.map((item) => ({
        id: item.yesNo_id,
        status: item.yesNo_status,
        description: item.yesNo_description,
      })),

      criticality_assessment: changedData.map((item) => ({
        id: item.criticality_assessment_id,
        name: item.criticality_assessment_name,
        description: item.criticality_assessment_description,
      })),

      auswahl_services_discount_entries: changedData.map((item) => ({
        id: item.auswahlServicesDiscount_id,
        name: item.auswahlServicesDiscount_name,
        description: item.auswahlServicesDiscount_description,
      })),

      ict_service_type_entries: changedData.map((item) => ({
        id: item.ictServiceType_id,
        name: item.ictServiceType_name,
        description: item.ictServiceType_description,
      })),

      level_of_supporting_ict_service_entries: changedData.map((item) => ({
        id: item.levelofSupportingICTService_id,
        name: item.levelofSupportingICTService_name,
        description: item.levelofSupportingICTService_description,
      })),

      ict_service_entries: changedData.map((item) => ({
        id: item.ictService_id,
        ict_service_type_id: item.ictService_ict_service_type_id,
        third_party_service_provider_id:
          item.ictService_third_party_service_provider_id,
        description: item.ictService_description,
        notice_period: item.ictService_notice_period,
        level_of_supporting_ict_service_id:
          item.ictService_level_of_supporting_ict_service_id,
        rank: item.ictService_rank,
        auswahl_services_discount_id:
          item.ictService_auswahl_services_discount_id,
        storage_of_data_id: item.ictService_storage_of_data_id,
        recipient_identification_code:
          item.ictService_recipient_identification_code,
        recipient_identification_type:
          item.ictService_recipient_identification_type,
        function_id: item.ictService_function_id,
        country_id: item.ictService_country_id,
        // ict_service_level_id: item.ictService_ict_service_level_id,
        impact_of_discontinuing_function_id:
          item.ictService_impact_of_discontinuing_function_id,
        impact_of_discontinuing_ict_services_id:
          item.ictService_impact_of_discontinuing_ict_services_id,
        alternatives_exit_id: item.ictService_alternatives_exit_id,
        substitutability_of_ict_provider_id:
          item.ictService_substitutability_of_ict_provider_id,
        level_of_reliance_id: item.ictService_level_of_reliance_id,
        criticality_assessment_id: item.criticality_assessment_id,
        start_date: item.ictService_start_date,
        end_date: item.ictService_end_date,
      })),

      ict_systems_entries: changedData
        .map((item) =>
          item.ict_systems.map((ict: IICTSystem) => ({
            id: ict.ict_systems_id,
            name: ict.ict_systems_name,
            description: ict.ict_systems_description,
            start_date: ict.ict_systems_start_date,
            end_date: ict.ict_systems_end_date,
          })),
        )
        .flat(),
      alternatives_exit_entries: changedData.map((item) => ({
        id: item.alternatives_exit_id,
        alternative_providers_identified:
          item.alternatives_exit_alternative_providers_identified,
        exit_plan_exists: Boolean(item.alternatives_exit_exit_plan_exists),

        reintegration_possible: item.alternatives_exit_reintegration_possible,
        alternative_ict_tpp_identification:
          item.alternatives_exit_alternative_ict_tpp_identification,
        description: item.alternatives_exit_description,
      })),

      impact_of_discontinuing_function_entries: changedData.map((item) => ({
        id: item.impactOfDiscontinuingFunction_id,
        description: item.impactOfDiscontinuingFunction_description,
      })),

      impact_of_discontinuing_ict_services_entries: changedData.map((item) => ({
        id: item.impactOfDiscontinuingIctServices_id,
        description: item.impactOfDiscontinuingIctServices_description,
      })),

      Certificates: changedData
        .map((item) =>
          item.certificates.map((cert: ICertificate) => ({
            id: cert.certificates_id,
            description: cert.certificates_description,
            validity: cert.certificates_validity,
            date_of_issuance: cert.certificates_date_of_issuance,
            certificate_registry: cert.certificates_registry,
            date_of_certificate_register:
              cert.certificates_date_of_certificate_register,
            third_party_service_provider_id:
              cert.certificates_third_party_service_provider_id,
            function_id: cert.certificates_function_id,
            ict_service_id: cert.certificates_ict_service_id,
            end_date: cert.certificates_end_date,
            start_date: cert.certificates_start_date,
          })),
        )
        .flat(),
      ICTLevels: changedData
      .map((item) =>
        item.ict_levels.map((level: IICTLevels) => ({
          id: level.ict_level_id,
          name: level.ict_level_name,
          description: level.ict_level_description,
        })),
      )
      .flat(),
      // ict_service_level: changedData.map((item) => ({
      //   id: item.ictServiceLevel_id,
      //   name: item.ictServiceLevel_name,
      //   description: item.ictServiceLevel_description,
      // })),

      level_of_reliance_ict_service: changedData.map((item) => ({
        id: item.levelOfRelianceICTService_id,
        name: item.levelOfRelianceICTService_name,
        description: item.levelOfRelianceICTService_description,
      })),
      token: isLoggedIn,
    };
    try {
      const response = await ict_serviceOp.postICTServices(formattedData);
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType<IICTServices>[] = [
    {
      title: 'Third party service provider',
      dataIndex: 'ictService_third_party_service_provider_id',
      editable: true,
      fixed: 'left',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_third_party_service_provider_id`;
        return (
          <CustomSelect
            options={tppNames}
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_third_party_service_provider_id
            }
            fieldName={fieldName}
            onChange={(value: any) => {
              const syntheticEvent = {
                target: { value },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(syntheticEvent, fieldName, record.key);
            }}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Function
          <Styled.Asterisx>*</Styled.Asterisx>
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictService_function_id',
      editable: true,
      fixed: 'left',
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_function_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={functionNames}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_function_id
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Country
          <Styled.Asterisx>*</Styled.Asterisx>
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictService_country_id',
      editable: false,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_country_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={countries}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_country_id
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'ictService_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Notice period for the financial entity making use of the ICT
          service(s)
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the notice period for terminating the contractual arrangement by the financial entity in a business-as-usual case. The notice period shall be expressed as number of calendar days from the counterparty’s receipt of the request to terminate the ICT service.
(natural number)
(mandatory if the ICT service is supporting a critical or important function)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictService_notice_period',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_notice_period`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_notice_period
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Rank
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Where the ICT third-party service provider is signing the contractual arrangement with the financial entity, it is considered as a direct ICT third-party service provider and the ‘rank’ to be reported shall be 1;
Where the ICT third-party service provider is signing the contract with the direct ICT third-party service provider, it is considered as a subcontractor and the ‘rank’ to be reported shall be 2;
The same logic apply to all the following subcontractors by incrementing the ‘rank’.
Where multiple ICT third-party service providers have the same ‘rank’ in the ICT service supply chain, financial entities shall report the same ‘rank’ for all those ICT third-party service providers.
(natural number)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictService_rank',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_rank`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_rank
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Identification code of the recipient of sub-contracted ICT services
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="To be left blank if the ICT third-party service provider (template B_05.02.0030) is a direct ICT third-party service provider i.e. at ‘rank’ r = 1 (template B_05.02.0050);
Where the ICT third-party service provider is at ‘rank’ r = n where n>1, indicate the ‘Identification code of the recipient of the sub-contracted services’ at ‘rank’ r=n-1 that subcontracted the ICT service (even partially) to the ICT third-party service provider at ‘rank’ r=n.
Examples:
—	The identification code of the direct ICT third- party service provider receiving the service from the subcontractor at rank 2;
—	The identification code of the subcontractor at rank 2 receiving the service from the subcontractor at rank 3.
The code used to identify the recipient of sub- contracted ICT services shall match the identification code provided in B_05.01.0010 for that provider.
(alphanumerical)
(mandatory Not applicable for rank 1)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictService_recipient_identification_code',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_recipient_identification_code`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_recipient_identification_code
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Type of code to identify the recipient of sub-contracted ICT services
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="To be left blank where the ICT third-party service provider template B_05.02.0030) is at rank r = 1 (template B_05.02.0050);
Where the ICT third-party service provider is at ‘rank’ r = n where n>1, indicate the ‘Type of code to identify the recipient of the sub-contracted service’ at ‘rank’ r=n-1 that subcontracted the ICT service (even partially) to the ICT third-party service provider at ‘rank’ r=n.
1.	‘LEI’ for LEI
2.	‘EUID’ for EUID
3.	CRN for Corporate registration number
4.	VAT for VAT number
5.	PNR for Passport Number
6.	NIN for National Identity Number
The type of code used to identify the recipient of sub- contracted ICT services shall match the identification code provided in B_05.01.0020 for that provider.
(pattern)
(mandatory Not applicable for rank 1)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictService_recipient_identification_type',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_recipient_identification_type`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_recipient_identification_type
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Yes/no - status<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'yesNo_status',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_yesNo_status`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.yesNo_status
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Yes/no - description',
      dataIndex: 'yesNo_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_yesNo_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.yesNo_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Auswahl services discount - name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'auswahlServicesDiscount_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_auswahlServicesDiscount_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.auswahlServicesDiscount_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Auswahl services discount - description',
      dataIndex: 'auswahlServicesDiscount_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_auswahlServicesDiscount_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.auswahlServicesDiscount_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Level of ICT Service<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ict_levels',
      key: 'ict_levels',
      render: (_, record) => {
        console.log(record.ict_levels, 'record');
        return (
          <div>
            {(record.ict_levels as IICTLevels[]).map(
              (level: IICTLevels) => {
                const idFieldName = `${record.key}_${level.ict_level_id}_levels_ict_level_id`;
                const nameFieldName = `${record.key}_${level.ict_level_id}_levels_ict_level_name`;
                const descriptionFieldName = `${record.key}_${level.ict_level_id}_levels_ict_level_description`;
                const rowKey = record.key;
                const handleRemoveICTServiceLevel = (ictServiceLevelKey: number, ictServiceLevelID: number) => {
                  Modal.confirm({
                    title: 'Are you sure you want to remove this level?',
                    content: 'This action cannot be undone.',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      const dataToRemove = data.find(
                        (item) => item.key === ictServiceLevelKey,
                      );
                      try {
                        const response = await ict_serviceOp.inactivateICTServiceLevel([ictServiceLevelID]);
                        const updatedDatas = data.map((data) => {
                          if (data.key === ictServiceLevelKey) {
                            return {
                              ...data,
                              datas: data.data.filter(
                                (data: IData) => data.data_id !== ictServiceLevelID,
                              ),
                            };
                          }
                          return data;
                        });
                        setData(updatedDatas);
                        fetchICTServices();
                      } catch (error) {
                        console.error('Failed to remove ICT Service level:', error);
                      }
                    },
                    onCancel: () => {
                      notification.info({
                        message: 'Action Canceled',
                        description: 'The ICT Service level removal has been canceled.',
                        duration: 10,
                      });
                    },
                  });
                };
                return (
                  <Styled.TooltipContainer
                    key={level.ict_level_id}
                    visible={isTooltipVisible[idFieldName]}
                    title={
                      <div>
                        <Styled.TooltipButtonContainer>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => handleCloseTooltip(idFieldName)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                          <Button
                          size="small"
                          type="text"
                          danger
                          onClick={() =>
                            handleRemoveICTServiceLevel(record.key, level.ict_level_id)
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                        </Styled.TooltipButtonContainer>
                        <Styled.InputField>
                          <strong>
                            Name <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(nameFieldName)
                                ? typeof editedData[nameFieldName] === 'boolean'
                                  ? editedData[nameFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[nameFieldName])
                                : String(level.ict_level_name)
                            }
                            onChange={(e) =>
                              handleEditChange(e, nameFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>Description</strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(descriptionFieldName)
                                ? typeof editedData[descriptionFieldName] ===
                                  'boolean'
                                  ? editedData[descriptionFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[descriptionFieldName])
                                : String(level.ict_level_description)
                            }
                            onChange={(e) =>
                              handleEditChange(e, descriptionFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                      </div>
                    }
                    open={isTooltipVisible[idFieldName] || false}
                    trigger={[]}
                  >
                    <Styled.TagComponent
                      color="blue"
                      onClick={() => handleOpenTooltip(idFieldName)}
                    >
                      <Input
                        value={
                          editedData.hasOwnProperty(nameFieldName)
                            ? typeof editedData[nameFieldName] === 'boolean'
                              ? editedData[nameFieldName]
                                ? 'true'
                                : 'false'
                              : String(editedData[nameFieldName])
                            : String(level.ict_level_name)
                        }
                        readOnly
                        addonAfter={<PlusCircleOutlined />}
                      />

                    </Styled.TagComponent>
                    <Styled.GlobalStyle />
                  </Styled.TooltipContainer>
                );
              },
            )}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewICTLevel(record.key)}
            />
          </div>
        );
      },
    },
    // {
    //   title: (
    //     <>
    //       ICT service level - name<Styled.Asterisx>*</Styled.Asterisx>
    //     </>
    //   ),
    //   dataIndex: 'ictServiceLevel_name',
    //   editable: true,
    //   render: (_: any, record: any) => {
    //     const fieldName = `${record.ictService_id}_ictServiceLevel_name`;
    //     const rowKey = record.key;
    //     return (
    //       <Input
    //         value={
    //           editedData.hasOwnProperty(fieldName)
    //             ? editedData[fieldName]
    //             : record.ictServiceLevel_name
    //         }
    //         onChange={(e) => handleEditChange(e, fieldName, rowKey)}
    //       />
    //     );
    //   },
    // },
    // {
    //   title: 'ICT service level - description',
    //   dataIndex: 'ictServiceLevel_description',
    //   editable: true,
    //   render: (_: any, record: any) => {
    //     const fieldName = `${record.ictService_id}_ictServiceLevel_description`;
    //     const rowKey = record.key;
    //     return (
    //       <Input
    //         value={
    //           editedData.hasOwnProperty(fieldName)
    //             ? editedData[fieldName]
    //             : record.ictServiceLevel_description
    //         }
    //         onChange={(e) => handleEditChange(e, fieldName, rowKey)}
    //       />
    //     );
    //   },
    // },
    {
      title: (
        <Styled.TitleComponent>
          Type of ICT services
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="One of the types of ICT services referred to in Annex III
(closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ictServiceType_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictServiceType_name`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={ictServicesTypes}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictServiceType_name
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: 'ICT service type - description',
      dataIndex: 'ictServiceType_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictServiceType_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictServiceType_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Level of supporting ICT service - name
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'levelofSupportingICTService_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_levelofSupportingICTService_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.levelofSupportingICTService_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Level of supporting ICT service - description',
      dataIndex: 'levelofSupportingICTService_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_levelofSupportingICTService_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.levelofSupportingICTService_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Alternatives exit - alternative providers identified
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="One of the options in the following closed list shall be used:
          1.	Yes;
          2.	No;
          7. Assessment not performed.
          For each ICT third-party service provider supporting a critical or important function, the assessment to identify an alternative service provider shall be performed.
          (closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'alternatives_exit_alternative_providers_identified',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_alternative_providers_identified`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={alternatives}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_alternative_providers_identified
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const event = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },

    // {
    //   title: (
    //     <>
    //       Alternatives exit - exit plan exists
    //       <Styled.Asterisx>*</Styled.Asterisx>
    //     </>
    //   ),
    //   dataIndex: 'alternatives_exit_exit_plan_exists',
    //   editable: true,
    //   render: (_: any, record: any) => {
    //     const fieldName = `${record.ictService_id}_alternatives_exit_exit_plan_exists`;
    //     const rowKey = record.key;
    //     return (
    //       <Input
    //         value={
    //           editedData.hasOwnProperty(fieldName)
    //             ? editedData[fieldName]
    //             : record.alternatives_exit_exit_plan_exists
    //         }
    //         onChange={(e) => handleEditChange(e, fieldName, rowKey)}
    //       />
    //     );
    //   },
    // },
    {
      title: (
        <>
          Alternatives exit - exit plan exists
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'alternatives_exit_exit_plan_exists',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_exit_plan_exists`;
        const rowKey = record.key;

        const options = [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ];

        return (
          <CustomSelect
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_exit_plan_exists
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const event = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            options={options}
            size="large"
            placeholder="Select Yes or No"
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Alternatives exit - reintegration possible
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="One of the options in the following closed list shall be used:
           1.	Easy;
           2.	Difficult;
           3.	Highly complex.
           Use this column where the ICT service is provided by an ICT third-party service provider that is not an ICT intra-group service provider
           (closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'alternatives_exit_reintegration_possible',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_reintegration_possible`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={possibility}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_reintegration_possible
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const event = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <>
          Alternatives exit - alternative ict tpp identification
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'alternatives_exit_alternative_ict_tpp_identification',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_alternative_ict_tpp_identification`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_alternative_ict_tpp_identification
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },

    {
      title: 'Alternatives exit - description',
      dataIndex: 'alternatives_exit_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_alternatives_exit_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.alternatives_exit_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Impact of discontinuing function',
      dataIndex: 'impactOfDiscontinuingFunction_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldNameDesc = `${record.ictService_id}_impactOfDiscontinuingFunction_description`;
        const fieldNameID = `${record.ictService_id}_impactOfDiscontinuingFunction_id`;
        const fieldNameIDFunction = `${record.ictService_id}_ictService_impact_of_discontinuing_function_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={impact}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldNameDesc)
                ? editedData[fieldNameDesc]
                : record.impactOfDiscontinuingFunction_description
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldNameID, rowKey);
              handleEditChange(idEvent, fieldNameIDFunction, rowKey);
              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldNameDesc, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: 'Impact of discontinuing ICT Services',
      dataIndex: 'impactOfDiscontinuingIctServices_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_impactOfDiscontinuingIctServices_description`;
        const fieldNameID = `${record.ictService_id}_impactOfDiscontinuingIctServices_id`;
        const fieldNameIDICT = `${record.ictService_id}_ictService_impact_of_discontinuing_ict_services_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={impact}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.impactOfDiscontinuingIctServices_description
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldNameID, rowKey);
              handleEditChange(idEvent, fieldNameIDICT, rowKey);
              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <>
          Criticality assessment name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'criticality_assessment_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_criticality_assessment_name`;
        const fieldId = `${record.ictService_id}_criticality_assessment_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={criticalityAssessments}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.criticality_assessment_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldId, rowKey);

              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: 'Criticality assessment - description',
      dataIndex: 'criticality_assessment_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_criticality_assessment_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.criticality_assessment_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'ICT Systems',
      dataIndex: 'ict_systems',
      key: 'ict_systems',
      render: (_, record) => {
        return (
          <div>
            {(record.ict_systems as IICTSystem[]).map((system: IICTSystem) => {
              const idFieldName = `${system.ict_systems_id}_systems_ict_systems_id`;
              const nameFieldName = `${system.ict_systems_id}_systems_ict_systems_name`;
              const descriptionFieldName = `${system.ict_systems_id}_systems_ict_systems_description`;
              const startDateFieldName = `${system.ict_systems_id}_systems_ict_systems_start_date`;
              const endDateFieldName = `${system.ict_systems_id}_systems_ict_systems_end_date`;
              const rowKey = record.key;
              const handleRemoveIctSystem = (
                activityKey: number,
                ictSystemId: number,
              ) => {
                Modal.confirm({
                  title: 'Are you sure you want to remove this ICT system?',
                  content: 'This action cannot be undone.',
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: async () => {
                    try {
                      const response = await globalOp.inactivateICTSystem([
                        ictSystemId,
                      ]);
                      const updatedAICTSystems = data.map((system) => {
                        if (system.key === activityKey) {
                          return {
                            ...system,
                            ict_systems: system.ict_systems.filter(
                              (system: IICTSystem) =>
                                system.ict_systems_id !== ictSystemId,
                            ),
                          };
                        }
                        return system;
                      });
                      setData(updatedAICTSystems);
                      fetchICTServices();
                    } catch (error) {
                      console.error('Failed to remove ICT system:', error);
                    }
                  },
                  onCancel: () => {
                    notification.info({
                      message: 'Action Canceled',
                      description: 'The ICT system removal has been canceled.',
                      duration: 10,
                    });
                  },
                });
              };
              return (
                <Styled.TooltipContainer
                  key={system.ict_systems_id}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                        <Button
                          size="small"
                          type="text"
                          danger
                          onClick={() =>
                            handleRemoveIctSystem(
                              record.key,
                              system.ict_systems_id,
                            )
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          Name <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(nameFieldName)
                              ? typeof editedData[nameFieldName] === 'boolean'
                                ? editedData[nameFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[nameFieldName])
                              : String(system.ict_systems_name)
                          }
                          onChange={(e) =>
                            handleEditChange(e, nameFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Start date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(startDateFieldName)
                              ? String(editedData[startDateFieldName] ?? '')
                              : String(system.ict_systems_start_date ?? '')
                          }
                          fieldName={startDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, startDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          End date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(endDateFieldName)
                              ? String(editedData[endDateFieldName] ?? '')
                              : String(system.ict_systems_end_date ?? '')
                          }
                          fieldName={endDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, endDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(system.ict_systems_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(nameFieldName)
                          ? typeof editedData[nameFieldName] === 'boolean'
                            ? editedData[nameFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[nameFieldName])
                          : String(system.ict_systems_name)
                      }
                      readOnly
                      addonAfter={<PlusCircleOutlined />}
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewIctSystem(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: 'Certificates',
      dataIndex: 'certificates',
      key: 'certificates',
      render: (_, record) => {
        return (
          <div>
            {(record.certificates as ICertificate[]).map(
              (cert: ICertificate) => {
                const idFieldName = `${cert.certificates_id}_newcert_certificates_id`;
                const descriptionFieldName = `${cert.certificates_id}_newcert_certificates_description`;
                const registryFieldName = `${cert.certificates_id}_newcert_certificates_registry`;
                const issuanceDateFieldName = `${cert.certificates_id}_newcert_certificates_date_of_issuance`;
                const validityFieldName = `${cert.certificates_id}_newcert_certificates_validity`;
                const dateOfRegisterFieldName = `${cert.certificates_id}_newcert_certificates_date_of_certificate_register`;
                const startDateFieldName = `${cert.certificates_id}_newcert_certificates_start_date`;
                const endDateFieldName = `${cert.certificates_id}_newcert_certificates_end_date`;
                const fieldNameTpp = `${cert.certificates_id}_newcert_certificates_third_party_service_provider_id`;
                const fieldNameFunctions = `${cert.certificates_id}_newcert_certificates_function_id`;
                const fieldNameServices = `${cert.certificates_id}_newcert_certificates_ict_service_id`;
                const rowKey = record.key;
                const handleRemoveCertificates = (
                  certificateKey: number,
                  certificateID: number,
                ) => {
                  Modal.confirm({
                    title: 'Are you sure you want to remove this Certificate?',
                    content: 'This action cannot be undone.',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      try {
                        const response = await tppOp.inactivateCertificates([
                          certificateID,
                        ]);
                        const updatedCertificates = data.map((certificate) => {
                          if (certificate.key === certificateKey) {
                            return {
                              ...certificate,
                              certificates: certificate.certificates.filter(
                                (certificate: ICertificate) =>
                                  certificate.certificates_id !== certificateID,
                              ),
                            };
                          }
                          return certificate;
                        });
                        setData(updatedCertificates);
                        fetchICTServices();
                      } catch (error) {
                        console.error('Failed to remove Certificate:', error);
                      }
                    },
                    onCancel: () => {
                      notification.info({
                        message: 'Action Canceled',
                        description:
                          'The Certificate removal has been canceled.',
                        duration: 10,
                      });
                    },
                  });
                };
                return (
                  <Styled.TooltipContainer
                    key={cert.certificates_id}
                    visible={isTooltipVisible[idFieldName]}
                    title={
                      <div>
                        <Styled.TooltipButtonContainer>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => handleCloseTooltip(idFieldName)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                          <Button
                            size="small"
                            type="text"
                            danger
                            onClick={() =>
                              handleRemoveCertificates(
                                record.key,
                                cert.certificates_id,
                              )
                            }
                          >
                            <DeleteOutlined />
                          </Button>
                        </Styled.TooltipButtonContainer>
                        <Styled.InputField>
                          <strong>
                            Registry <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(registryFieldName)
                                ? typeof editedData[registryFieldName] ===
                                  'boolean'
                                  ? editedData[registryFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[registryFieldName])
                                : String(cert.certificates_registry)
                            }
                            onChange={(e) =>
                              handleEditChange(e, registryFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Issuance date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(issuanceDateFieldName)
                                ? String(
                                    editedData[issuanceDateFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_issuance ?? '',
                                  )
                            }
                            fieldName={issuanceDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                issuanceDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Validity <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(validityFieldName)
                                ? typeof editedData[validityFieldName] ===
                                  'boolean'
                                  ? editedData[validityFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[validityFieldName])
                                : String(cert.certificates_validity)
                            }
                            onChange={(e) =>
                              handleEditChange(e, validityFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>Certificate Description </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(descriptionFieldName)
                                ? typeof editedData[descriptionFieldName] ===
                                  'boolean'
                                  ? editedData[descriptionFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[descriptionFieldName])
                                : String(cert.certificates_description)
                            }
                            onChange={(e) =>
                              handleEditChange(e, descriptionFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Start date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(startDateFieldName)
                                ? String(editedData[startDateFieldName] ?? '')
                                : String(cert.certificates_start_date ?? '')
                            }
                            fieldName={startDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                startDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            End date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(endDateFieldName)
                                ? String(editedData[endDateFieldName] ?? '')
                                : String(cert.certificates_end_date ?? '')
                            }
                            fieldName={endDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(e, endDateFieldName, record.key)
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Date of register<Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(dateOfRegisterFieldName)
                                ? String(
                                    editedData[dateOfRegisterFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_certificate_register ??
                                      '',
                                  )
                            }
                            fieldName={dateOfRegisterFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                dateOfRegisterFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Certificate TPP id
                            <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <CustomSelect
                            options={tppNames}
                            value={
                              editedData.hasOwnProperty(fieldNameTpp)
                                ? editedData[fieldNameTpp]
                                : cert.certificates_third_party_service_provider_id
                            }
                            fieldName={fieldNameTpp}
                            onChange={(value: any) => {
                              const syntheticEvent = {
                                target: { value },
                              } as React.ChangeEvent<HTMLInputElement>;
                              handleEditChange(
                                syntheticEvent,
                                fieldNameTpp,
                                record.key,
                              );
                            }}
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Certificate Function id
                            <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <CustomSelect
                            options={functionNames}
                            value={
                              editedData.hasOwnProperty(fieldNameFunctions)
                                ? editedData[fieldNameFunctions]
                                : cert.certificates_function_id
                            }
                            fieldName={fieldNameFunctions}
                            onChange={(value: string) => {
                              const syntheticEvent = {
                                target: { value },
                              } as React.ChangeEvent<HTMLInputElement>;

                              handleEditChange(
                                syntheticEvent,
                                fieldNameFunctions,
                                record.key,
                              );
                            }}
                            placeholder="Select a function"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            ICT service id <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <CustomSelect
                            options={services}
                            value={
                              editedData.hasOwnProperty(fieldNameServices)
                                ? editedData[fieldNameServices]
                                : cert.certificates_ict_service_id
                            }
                            fieldName={fieldNameServices}
                            onChange={(value: any) => {
                              const syntheticEvent = {
                                target: { value },
                              } as React.ChangeEvent<HTMLInputElement>;
                              handleEditChange(
                                syntheticEvent,
                                fieldNameServices,
                                record.key,
                              );
                            }}
                          />
                        </Styled.InputField>
                      </div>
                    }
                    open={isTooltipVisible[idFieldName] || false}
                    trigger={[]}
                  >
                    <Styled.TagComponent
                      color="blue"
                      onClick={() => handleOpenTooltip(idFieldName)}
                    >
                      <Input
                        value={
                          editedData.hasOwnProperty(descriptionFieldName)
                            ? typeof editedData[descriptionFieldName] ===
                              'boolean'
                              ? editedData[descriptionFieldName]
                                ? 'true'
                                : 'false'
                              : String(editedData[descriptionFieldName])
                            : String(cert.certificates_description)
                        }
                        readOnly
                        addonAfter={<PlusCircleOutlined />}
                      />
                    </Styled.TagComponent>
                    <Styled.GlobalStyle />
                  </Styled.TooltipContainer>
                );
              },
            )}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewCertificate(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: 'Storage of data',
      dataIndex: 'ictService_storage_of_data_id',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_ictService_storage_of_data_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={sensitivenessOfData}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.ictService_storage_of_data_id
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictService_start_date',
      render: (text: string, record) => {
        const fieldName = `${record.ictService_id}_ictService_start_date`;
        const rowKey = record.key;
        return (
          <Styled.InputField>
            <DynamicField
              value={
                editedData.hasOwnProperty(fieldName)
                  ? String(editedData[fieldName] ?? '')
                  : String(record.ictService_start_date ?? '')
              }
              fieldName={fieldName}
              rowKey={record.key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEditChange(e, fieldName, rowKey)
              }
              type="date"
            />
          </Styled.InputField>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ictService_end_date',
      render: (text: string, record) => {
        const fieldName = `${record.ictService_id}_ictService_end_date`;
        const rowKey = record.key;
        return (
          <Styled.InputField>
            <DynamicField
              value={
                editedData.hasOwnProperty(fieldName)
                  ? String(editedData[fieldName] ?? '')
                  : String(record.ictService_end_date ?? '')
              }
              fieldName={fieldName}
              rowKey={record.key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEditChange(e, fieldName, rowKey)
              }
              type="date"
            />
          </Styled.InputField>
        );
      },
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      render: (_, record) => {
        return (
          <div>
            {record.data.map((itemData: IData, index: number) => {
              const idFieldName = `${itemData.data_id}_newItem_data_id`;
              const descriptionFieldName = `${itemData.data_id}_newItem_data_description`;
              const restLocationFieldName = `${itemData.data_id}_newItem_data_location_of_data_at_rest`;
              const managementLocationFieldName = `${itemData.data_id}_newItem_data_location_of_data_management`;
              const sensitivenessNameFieldName = `${itemData.data_id}_newItem_dataSensitiveness_sensitiveness_name`;
              const startDateFieldName = `${itemData.data_id}_newItem_data_start_date`;
              const endDateFieldName = `${itemData.data_id}_newItem_data_end_date`;
              const sensitivenessDescriptionFieldName = `${itemData.data_id}_newItem_dataSensitiveness_description`;
              const sensitivenessOfDataStoredByICTTPSPName = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_name`;
              const sensitivenessOfDataStoredByICTTPSPId = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_id`;
              const sensitivenessOfDataStoredByICTTPSPDescription = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_description`;
              const rowKey = record.key;
              const handleRemoveData = (dataKey: number, dataID: number) => {
                Modal.confirm({
                  title: 'Are you sure you want to remove this Data?',
                  content: 'This action cannot be undone.',
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: async () => {
                    const dataToRemove = data.find(
                      (item) => item.key === dataKey,
                    );
                    try {
                      const response = await globalOp.inactivateData([dataID]);
                      const updatedDatas = data.map((data) => {
                        if (data.key === dataKey) {
                          return {
                            ...data,
                            datas: data.data.filter(
                              (data: IData) => data.data_id !== dataID,
                            ),
                          };
                        }
                        return data;
                      });
                      setData(updatedDatas);
                      fetchICTServices();
                    } catch (error) {
                      console.error('Failed to remove Data:', error);
                    }
                  },
                  onCancel: () => {
                    notification.info({
                      message: 'Action Canceled',
                      description: 'The Data removal has been canceled.',
                      duration: 10,
                    });
                  },
                });
              };
              return (
                <Styled.TooltipContainer
                  key={itemData.data_id || index}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                        <Button
                          size="small"
                          type="text"
                          danger
                          onClick={() =>
                            handleRemoveData(record.key, itemData.data_id)
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Location of the data at rest (storage)
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Identify the country of location of the data at rest (storage) using the ISO 3166–1 alpha–2 code.
If there are several countries of location, additional row(s) shall be used for each country.
(country)
(mandatory if ’Yes’ is reported in B_02.02.0140)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(restLocationFieldName)
                              ? typeof editedData[restLocationFieldName] ===
                                'boolean'
                                ? editedData[restLocationFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[restLocationFieldName])
                              : String(itemData.data_location_of_data_at_rest)
                          }
                          onChange={(e) =>
                            handleEditChange(e, restLocationFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Location of management of the data (processing)
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Identify the country of location of the management of the data (processing) using the ISO 3166–1 alpha–2 code.
If there are several countries of location, additional row(s) shall be used for each country.
(country)
(mandatory if the ICT service is based on or foresees data processing)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              managementLocationFieldName,
                            )
                              ? typeof editedData[
                                  managementLocationFieldName
                                ] === 'boolean'
                                ? editedData[managementLocationFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[managementLocationFieldName],
                                  )
                              : String(
                                  itemData.data_location_of_data_management,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              managementLocationFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(itemData.data_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Start date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(startDateFieldName)
                              ? String(editedData[startDateFieldName] ?? '')
                              : String(itemData.data_start_date ?? '')
                          }
                          fieldName={startDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, startDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          End date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(endDateFieldName)
                              ? String(editedData[endDateFieldName] ?? '')
                              : String(itemData.data_end_date ?? '')
                          }
                          fieldName={endDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, endDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Sensitiveness
                          <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessNameFieldName,
                            )
                              ? typeof editedData[
                                  sensitivenessNameFieldName
                                ] === 'boolean'
                                ? editedData[sensitivenessNameFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[sensitivenessNameFieldName])
                              : String(
                                  itemData.dataSensitiveness_sensitiveness_name,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessNameFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Sensitiveness Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessDescriptionFieldName,
                            )
                              ? typeof editedData[
                                  sensitivenessDescriptionFieldName
                                ] === 'boolean'
                                ? editedData[sensitivenessDescriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[
                                      sensitivenessDescriptionFieldName
                                    ],
                                  )
                              : String(itemData.dataSensitiveness_description)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessDescriptionFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Sensitiveness of the data stored by the ICT
                            third-party service provider
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Identify the level of sensitiveness of the data stored or processed by the ICT third-party service provider using one of the options provided in the following closed list:
1.	Low
2.	Medium;
3.	High.
The most sensitive data take precedence: e.g. if both ‘Medium’ and ‘High’ apply, then ‘High’ shall be selected.
(closed set of options)
(mandatory if the ICT third-party service provider stores data and if the ICT service is supporting a critical or important function or material part thereof)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <CustomSelect
                          options={sensitivenessOfData}
                          placeholder="Select an option"
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessOfDataStoredByICTTPSPName,
                            )
                              ? editedData[
                                  sensitivenessOfDataStoredByICTTPSPName
                                ]
                              : itemData.sensitivenessOfDataStoredbyICTTPSP_name
                          }
                          onChange={(
                            value: string,
                            option: { label: string; value: string },
                          ) => {
                            const idEvent = {
                              target: {
                                value: option.value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(
                              idEvent,
                              sensitivenessOfDataStoredByICTTPSPId,
                              rowKey,
                            );

                            const nameEvent = {
                              target: {
                                value: option.label,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(
                              nameEvent,
                              sensitivenessOfDataStoredByICTTPSPName,
                              rowKey,
                            );
                          }}
                          size="large"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Sensitiveness of data stored by ICT TPSP Description
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessOfDataStoredByICTTPSPDescription,
                            )
                              ? typeof editedData[
                                  sensitivenessOfDataStoredByICTTPSPDescription
                                ] === 'boolean'
                                ? editedData[
                                    sensitivenessOfDataStoredByICTTPSPDescription
                                  ]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[
                                      sensitivenessOfDataStoredByICTTPSPDescription
                                    ],
                                  )
                              : String(
                                  itemData.sensitivenessOfDataStoredbyICTTPSP_description,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessOfDataStoredByICTTPSPDescription,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(descriptionFieldName)
                          ? typeof editedData[descriptionFieldName] ===
                            'boolean'
                            ? editedData[descriptionFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[descriptionFieldName])
                          : String(itemData.data_description)
                      }
                      readOnly
                      addonAfter={<PlusCircleOutlined />}
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewData(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Level of reliance on the ICT service supporting the critical or
          important function.
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="One of the options in the following closed list shall be used:
1.	Not significant;
2.	Low reliance: in case of disruption of the services, the supported functions would not be significantly impacted (no interruption, no important damage) or disruption can be resolved quickly and with minimal impact on the functions supported;
3.	Material reliance: in case of disruption of the services, the supported functions would be significantly impacted if the disruption lasts more than a few minutes/ few hours, and the disruption may cause damages, but is still manageable;
4.	Full reliance: in case of disruption of the services, the supported functions would be immediately and severely interrupted/damaged, for a long period.
(closed set of options)
(mandatory if the ICT service is supporting a critical or important function or material part thereof)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'levelOfRelianceICTService_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_levelOfRelianceICTService_name`;
        const fieldId = `${record.ictService_id}_levelOfRelianceICTService_id`;
        const fieldIdICT = `${record.ictService_id}_ictService_level_of_reliance_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={levelsOfReliance}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.levelOfRelianceICTService_name
            }
            onChange={(
              value: string,
              option: { label: string; value: string },
            ) => {
              const idEvent = {
                target: {
                  value: option.value,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(idEvent, fieldId, rowKey);

              const nameEvent = {
                target: {
                  value: option.label,
                },
              } as React.ChangeEvent<HTMLInputElement>;
              handleEditChange(nameEvent, fieldName, rowKey);
              handleEditChange(nameEvent, fieldIdICT, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: 'Level of Reliance ICT Service - description',
      dataIndex: 'levelOfRelianceICTService_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.ictService_id}_levelOfRelianceICTService_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.levelOfRelianceICTService_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button
              danger
              onClick={() => handleInactivate(record as IICTServices)}
            >
              Soft delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Styled.ButtonDiv isEmpty={dataWithKey.length === 0}>
        {query.entity && (
          <Styled.AreaButton type="primary" onClick={addNewRow}>
            Add Row
          </Styled.AreaButton>
        )}
      </Styled.ButtonDiv>
      <Styled.TooltipTableContainer>
        <Table
          dataSource={dataWithKey}
          rowKey="key"
          scroll={{ x: 'max-content' }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
          columns={columns}
        />
      </Styled.TooltipTableContainer>
      {dataWithKey.length > 0 && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      )}
    </>
  );
};

export default ICTServices;
