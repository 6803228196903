import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { set_isAddRowTppFilled } from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';

const useTppStatus = () => {
  const dispatch = useDispatch();

  const {
    isTppAdded,
    isPersonTypeAdded,
    isNonSubstReasonAdded,
    isCurrencyAndCostsAdded,
    isCertificateAdded,
  } = useSelector((state: RootState) => state.tpp);

  useEffect(() => {
    const statuses = [
      isTppAdded,
      isPersonTypeAdded,
      isNonSubstReasonAdded,
      isCurrencyAndCostsAdded,
      isCertificateAdded,
    ];

    if (statuses.every(Boolean)) {
      dispatch(set_isAddRowTppFilled(true));
    } else {
      dispatch(set_isAddRowTppFilled(false));
    }
  }, [
    isTppAdded,
    isPersonTypeAdded,
    isNonSubstReasonAdded,
    isCurrencyAndCostsAdded,
    isCertificateAdded,
  ]);
};

export default useTppStatus;
