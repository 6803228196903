import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/configureStore';
import { Input, notification } from 'antd';
import CustomTable from 'components/CustomTable/CustomTable';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { IHierarchyInGroupType } from 'store/ducks/DataEntry/Entities/types';
import {
  set_hierarchyInGroup,
  set_isHierarchyInGroupAdded,
} from 'store/ducks/DataEntry/Entities/entitySlice';
import * as Styled from '../../DataEntry.styled';
import useEntityStatus from 'utils/hooks/useEntityStatus';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';

const HierarchyInGroup: React.FC = () => {
  useEntityStatus();
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<IHierarchyInGroupType[]>([]);
  const [initialData, setInitialData] = useState<IHierarchyInGroupType[]>([]);
  const [combinedData, setCombinedData] = useState<IHierarchyInGroupType[]>([]);
  const { addRowCount, hierarchyInGroup, entities, nameOfEntity } = useSelector(
    (state: RootState) => state.entities,
  );
  const isLoggedIn = localStorage.getItem('authToken');
  const dispatch = useDispatch();

  useEffect(() => {
    setCombinedData([...hierarchyInGroup, ...dataSource]);
  }, [dataSource, hierarchyInGroup, nameOfEntity]);

  const handleSave = (row: IHierarchyInGroupType) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );

      setDataSource(updatedDataSource);
      setCombinedData([...updatedDataSource, ...hierarchyInGroup]);
    } else {
      const updatedEntityType = hierarchyInGroup.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setCombinedData([...dataSource, ...updatedEntityType]);
      dispatch(set_hierarchyInGroup(updatedEntityType));
    }
  };

  const fetchHierarchyGroupByEntityName = async () => {
    try {
      const response = await entityOp.fetchHierarchyGroupByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.hierarchy_in_group;
      const mappedData = [
        {
          key: data?.id,
          id: data?.id,
          name: data?.name || '',
          description: data?.description || '',
          entity_name: response?.data?.entity_name || query.entity,
        },
      ];

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchHierarchyGroupByEntityName();
    }
  }, [query]);
  let uniqueIdCounter = 0;

  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now() % 1_000_000_000;
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId =
      (timestampPart * 1000 + randomPart + uniqueIdCounter) % 2_147_483_648;

    return uniqueId;
  };

  useEffect(() => {
    const updatedHierarchy = hierarchyInGroup.map((row) => ({
      ...row,
      entity_name: nameOfEntity,
    }));
    dispatch(set_hierarchyInGroup(updatedHierarchy));
  }, [nameOfEntity]);

  useEffect(() => {
    if (addRowCount === 0) {
      dispatch(set_hierarchyInGroup([]));
    }
  }, [addRowCount, dispatch]);

  const areAllFieldsFilled = (hierarchyInGroup: IHierarchyInGroupType[]) => {
    return hierarchyInGroup.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  useEffect(() => {
    if (addRowCount === 1) {
      const allFieldsFilled = areAllFieldsFilled(hierarchyInGroup);
      if (allFieldsFilled) {
        dispatch(set_isHierarchyInGroupAdded(true));
      } else {
        dispatch(set_isHierarchyInGroupAdded(false));
      }
    }
  }, [hierarchyInGroup, addRowCount]);

  const handleSubmitHierarchyInGroup = async () => {
    if (JSON.stringify(combinedData) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (!areAllFieldsFilled(combinedData)) {
      notification.warning({
        message: 'Not submitted',
        description: 'Please fill in all the required fields in the data.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (hierarchyInGroup.length !== 0) {
      const allFieldsFilled = areAllFieldsFilled(hierarchyInGroup);
      if (!allFieldsFilled) {
        notification.warning({
          message: 'Not submitted',
          description:
            'Please fill in all the required fields in Hierarchy in Group.',
          placement: 'topRight',
          duration: 20,
        });
        return;
      } else {
        dispatch(set_isHierarchyInGroupAdded(true));
      }
    }

    try {
      const payload = {
        entity_name: query.entity,
        data_list: [...combinedData],
        token: isLoggedIn,
      };
      const response = await entityOp.fetchHierarchyGroup(payload);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns = [
    {
      title: (
        <Styled.TitleComponent>
          Hierarchy of the financial entity within the group
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Determine the hierarchy of the financial entity in the consolidation using one of the options in the following closed list:
1.	The financial entity is the ultimate parent undertaking in the consolidation;
2.	The financial entity is the parent undertaking of a sub-consolidated part in the consolidation;
3.	The financial entity is a subsidiary in the consolidation and is not a parent undertaking of a sub-consolidated part;
4.	The financial entity is not part of a group;
5.	The financial entity is a service provider to which the financial entity (or the third- party service provider acting on its behalf) is outsourcing all its operational activities.
Where an entity fulfils more than one options from the closed list above, the higher- level option applicable to this entity shall be selected.
(closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'name',
      width: '30%',
      editable: false,
      render: (text: string) => (
        <Input value={text} disabled />
      ),
    },
    {
      title: ' Hierarchy In Group Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: (
        <>
          Entity Name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'entity_name',
      editable: false,
      render: (text: string) => (
        <Input value={text} disabled />
      ),
    },
  ];

  return (
    <Styled.StyledWrapper
      hasEntity={!!query.entity}
      style={{ marginTop: '64px' }}
    >
      <CustomTable
        columns={columns}
        dataSource={combinedData}
        handleSave={handleSave}
      />
      <Styled.AreaButton type="primary" onClick={handleSubmitHierarchyInGroup}>
        Submit
      </Styled.AreaButton>
    </Styled.StyledWrapper>
  );
};

export default HierarchyInGroup;
