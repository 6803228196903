import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';
import ReactBrightLogo from 'assets/img/main/logo.png';

export const StyledNav = styled('div')<IToken>`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => `0px ${props.token.paddingLG}px`};
`;

export const StyledLogo = styled.img`
  width: 136px;
  position: relative;
  top: -23px;
  left: -27px;
`;

export const logoSrc = ReactBrightLogo;

export const DropdownContainer = styled('div')`
  margin-top: 20px;
  flex-direction: column;
  display: flex;
`;

export const ChangeButton = styled('div')`
  text-align: end;

  a {
    margin: 16px 16px 0 0;
    z-index: 1;
    border: 1px solid black;
    color: black;
  }

  .ant-btn-variant-solid {
    margin: 16px 0 16px 16px;
  }
`;
