import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  competent_authority: string;
  country_name: string;
  entity_name: string;
  entity_type: string;
  lei_entity: string;
  reporting_date: string;
}

interface B0101Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
    {
      title: (
        <Styled.AddttionalInfoContainer>
          <p>LEI of the entity maintaining the register of information  <span> - B.01.01.0010</span></p>
         
          <AdditionalInformation description="Identify the financial entity maintaining and updating the register of information using the LEI, 20-character, alpha- numeric code based on the ISO 17442 standard (alphanumerical)" />
        </Styled.AddttionalInfoContainer>
      ),
      width: 100,
      dataIndex: 'lei_entity',
      key: 'lei_entity',
      fixed: 'left',
    },
    {
      title: (
        <Styled.AddttionalInfoContainer>
          <p>Name of the financial entity - <span>B.01.01.0020</span></p>
          <AdditionalInformation description="Legal name of the financial entity maintaining and updating the register of information
(alphanumerical)" />
        </Styled.AddttionalInfoContainer>
      ),
      width: 100,
      dataIndex: 'entity_name',
      key: 'entity_name',
    },
    {
      title: (
        <Styled.AddttionalInfoContainer>
          <p>Country of the financial entity -<span>B.01.01.0030</span></p>
          <AdditionalInformation description="Identify the ISO 3166–1 alpha–2 code of the country where the license or the registration of the entity reported in the register of information has been issued.
(country)" />
        </Styled.AddttionalInfoContainer>
      ),
      width: 100,
      dataIndex: 'country_name',
      key: 'country_name',
      fixed: 'left',
    },
    {
      title: (
        <Styled.AddttionalInfoContainer>
          <p>Type of financial entity - <span>B.01.01.0040</span></p>
          <AdditionalInformation description="Identify the type of financial entity using one of the options in the following closed list:
1.	credit institutions;
2.	payment institutions, including payment institutions exempted pursuant to Directive
(EU) 2015/2366 of the European Parliament and of the Council (1);
3.	account information service providers;
4.	electronic money institutions, including electronic money institutions exempted pursuant to Directive 2009/110/EC of the European Parliament and of the Council (2);
5.	investment firms;
6.	crypto-asset service providers authorised under Regulation (EU) 2023/1114 of the European Parliament and of the Council (3)
7.	issuers of asset-referenced tokens authorised under Regulation (EU) 2023/1114;
8.	central securities depositories;
9.	central counterparties;
10.	trading venues;
11.	trade repositories;
12.	managers of alternative investment funds;
13.	management companies;
14.	data reporting service providers;
15.	insurance and reinsurance undertakings;
16.	insurance	intermediaries,	reinsurance intermediaries and ancillary insurance intermediaries;
17.	institutions for occupational retirement provision;
18.	credit rating agencies;
19.	administrators of critical benchmarks;
20.	crowdfunding service providers;
21.	securitisation repositories.
22.	other financial entity;
Where the register of information is maintained at the group level by the parent undertaking, which is not itself subject to the obligation to maintain such register, i.e. it does not fall under the definition of financial entities set out in Article 2 of the Regulation (EU) 2022/2554 (e.g., financial holding company, mixed financial holding company or mixed-activity holding company) ‘Other financial entity’ option shall be chosen.

(closed set of options)" />
        </Styled.AddttionalInfoContainer>
      ),
      width: 100,
      dataIndex: 'entity_type',
      key: 'entity_type',
    },
    {
      title: (
        <Styled.AddttionalInfoContainer>
          <p> Competent Authority - <span>B.01.01.0050</span></p>
          <AdditionalInformation description="Identify the competent authority referred to in Article 46 of Regulation (EU) 2022/2554 to which the register of information is reported.
(alphanumerical)
(mandatory in case of reporting)" />
        </Styled.AddttionalInfoContainer>
      ),
      width: 100,
      dataIndex: 'competent_authority',
      key: 'competent_authority',
    },
    {
      title: (
        <Styled.AddttionalInfoContainer>
         <p> Date of the reporting - <span>B.01.01.0060</span></p>
          <AdditionalInformation description="Identify the date using ISO 8601 (yyyy–mm–dd) code of the date of reporting
(date)
(mandatory in case of reporting)" />
        </Styled.AddttionalInfoContainer>
      ),
      width: 100,
      dataIndex: 'reporting_date',
      key: 'reporting_date',
    },
];

const B0101: React.FC<B0101Props> = ({ entityName }) => {
  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchRT0101(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          competent_authority: item.competent_authority,
          country_name: item.country_name,
          entity_name: item.entity_name,
          entity_type: item.entity_type,
          lei_entity: item.lei_entity,
          reporting_date: item.reporting_date,
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    if (entityName) {
      fetchData();
    }
  }, [is_displayed, entityName]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0101;
