import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  identification_code_of_ICT_provider: string;
  type_of_code: string;
  name_of_the_ICT_provider: string;
  type_of_person: string;
  country: string;
  currency_reported: string;
  total_annual_expense: string;
  identification_code_of_ICT_providers_ultimate_parent: string;
  type_of_code_to_identify_ultimate_parent: string;
}

interface B0501Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
      <p>  Identification code of ICT third-party service provider - <span>B.05.01.0010</span></p>
        <AdditionalInformation description="Code to identify the ICT third-party service provider.
Where LEI is used, it shall be provided as a 20-character, alpha- numeric code based on the ISO 17442 standard.
Where EUID is used, it shall be provided as specified in Article 9 of the Commission Implementing Regulation (EU) 2021/1042.
(alphanumerical)
" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'identification_code_of_ICT_provider',
    key: 'identification_code_of_ICT_provider',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Type of code to identify the ICT third-party service provider - <span>B.05.01.0020</span></p>
        <AdditionalInformation description="Type of code to identify the ICT third-party service provider reported in B_05.01.0010
1.	‘LEI’ for LEI
2.	‘EUID’ for EUID
3.	‘Country Code’+Underscore+’Type of Code’ for non LEI and non EUID code
Country Code: Identify the ISO 3166–1 alpha–2 code of the country of issuance of the other code to identify the ICT third- party service provider
Type of Code:
1.	CRN for Corporate registration number
2.	VAT for VAT number
3.	PNR for Passport Number
4.	NIN for National Identity Number
Only LEI or EUID shall be used for legal persons, as identified in B_05.01.0070, whereas alternative code may be used only for an individual acting in a business capacity.
Only LEI shall be used for legal persons that are not established in the Union.
(pattern)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_code',
    key: 'type_of_code',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
      <p> Name of the ICT third-party service provider in Latin alphabet - <span>B.05.01.0060</span></p>
        <AdditionalInformation description="Name of the ICT third-party service provider in Latin alphabet.
Where the name of the ICT third-party service provider reported in B_05.01.0050 is in Latin alphabet, it shall be repeated also in this data field.
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'name_of_the_ICT_provider',
    key: 'name_of_the_ICT_provider',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>Type of person of the ICT third-party service provider - <span>B.05.01.0070</span></p>
        <AdditionalInformation description="One of the options in the following closed list shall be used:
1.	Legal person, excluding individuals acting in business capacity
2.	Individual acting in a business capacity
(closed set of options)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_person',
    key: 'type_of_person',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Country of the ICT third-party service provider’s headquarters - <p>B.05.01.0080</p></p>
        <AdditionalInformation description="Identify the ISO 3166–1 alpha–2 code of the country in which the global operating headquarters of ICT third-party service provider are located (usually, this country is the country of tax residence).
(country)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>Currency of the amount reported in B_05.01.0070 - <span>B.05.01.0090</span></p>
        <AdditionalInformation description="Identify the ISO 4217 alphabetic code of the currency used to express the amount in B_05.01.0100.
The currency reported shall be the same currency used by the financial entity for the preparation of the financial statements at entity, sub-consolidated or consolidated level, as applicable.
(currency)
(mandatory if B_05.01.0100 is reported)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'currency_reported',
    key: 'currency_reported',
  },
  {
      title: (
        <Styled.AddttionalInfoContainer>
         <p> Total annual expense or estimated cost of the ICT third-party service provider - <span>B.05.01.0100</span></p>
          <AdditionalInformation description="Annual expense or estimated cost for using the ICT services provided by the ICT third-party service provider to the entities making use of the ICT services. Monetary value shall be reported in units.
(monetary)
(mandatory if the ICT third- party service provider is a direct ICT third-party service provider)" />
        </Styled.AddttionalInfoContainer>
      ),
    width: 200,
    dataIndex: 'total_annual_expense',
    key: 'total_annual_expense',
  },
  {
      title: (
        <Styled.AddttionalInfoContainer>
         <p>Identification code of the ICT third-party service provider’s ultimate parent undertaking - <span>B.05.01.0110</span></p>
          <AdditionalInformation description="Code to identify the ICT third-party service provider’s ultimate parent undertaking.
The code used to identify ultimate parent undertaking in this field shall match the identification code provided in B_05.01.0010 for that ultimate parent undertaking.
Where the ICT third-party service provider is not part of a group, the identification code used to identify that ICT third- party service provider in B_05.01.0010 shall be repeated also in this data field.
(alphanumerical)
(mandatory if the ICT third- party service provider is not the ultimate parent undertaking)" />
        </Styled.AddttionalInfoContainer>
      ),
    width: 200,
    dataIndex: 'identification_code_of_ICT_providers_ultimate_parent',
    key: 'identification_code_of_ICT_providers_ultimate_parent',
  },
  {
      title: (
        <Styled.AddttionalInfoContainer>
         <p> Type of code to identify the ICT third-party service provider’s ultimate parent undertaking - <span>B.05.01.0120</span></p>
          <AdditionalInformation description="Type of code to identify the ICT third-party service provider’s ultimate parent undertaking in B_05.01.0110.
The type of the code used to identify ultimate parent undertaking in this field shall match the identification code provided in B_05.01.0020 for that ultimate parent undertaking. Where the ICT third-party service provider is not part of a group, the type of the identification code used to identify that ICT third-party service provider in B_05.01.0020 shall be repeated also in this data field.
(pattern)
(mandatory if the ICT third- party service provider is not the ultimate parent undertaking)" />
        </Styled.AddttionalInfoContainer>
      ),
    width: 200,
    dataIndex: 'type_of_code_to_identify_ultimate_parent',
    key: 'type_of_code_to_identify_ultimate_parent',
  },
];

const B0501: React.FC<B0501Props> = ({ entityName }) => {
  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };

    try {
      const fetchedData = await reportingOp.fetchRT0501(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          identification_code_of_ICT_provider:
            item['ICT service provider code'],
          type_of_code: item['Type of ICT service provider code'],
          name_of_the_ICT_provider: item['Name of ICT service provider'],
          type_of_person: item['Type of person'],
          country: item['Headquarters country'],
          currency_reported: item['Reported currency'],
          total_annual_expense: item['Total annual expense'],
          identification_code_of_ICT_providers_ultimate_parent:
            item['Ultimate parent code'],
          type_of_code_to_identify_ultimate_parent:
            item['Type of ultimate parent code'],
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0501;
