import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import * as Styled from './AdditionalInformation.styled';

interface AdditionalInformationProps {
  description: string;
}

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({
  description,
}) => {
  const content = (
    <Styled.PopoverComponent>
      <p>{description}</p>
    </Styled.PopoverComponent>
  );

  return (
    <div>
      <Popover
        content={content}
        trigger="click"
        placement="topLeft"
        overlayStyle={{ width: '200' }}
      >
        <QuestionCircleOutlined
          style={{ color: 'red', marginLeft: 8, cursor: 'pointer' }}
        />
      </Popover>
    </div>
  );
};

export default AdditionalInformation;
