import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  contractual_arangement_ref_num: string;
  country_of_ict_services: string;
  data_location_processing: string;
  data_location_storage: string;
  data_sensitiveness: string;
  end_date: string;
  entity_notice_period: number;
  function_identifier: string;
  governing_law_country: string;
  ict_service_provider_code: string;
  lei_of_the_entity_using_ict_service: string;
  level_of_reliance: string;
  provider_notice_period: string;
  start_date: string;
  storage_of_data: string;
  termination_reason: string;
  type_of_ict_service_provider_code: string;
  type_of_ict_services: string;
}

interface B0202Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Contractual arrangement reference number - <span>B.02.02.0010</span></p>
        <AdditionalInformation description="As reported in B_02.01.0010
(alphanumerical)
" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'contractual_arangement_ref_num',
    key: 'contractual_arangement_ref_num',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>LEI of the financial entity making use of the ICT service(s) - <span>B.02.02.0020</span></p>
        <AdditionalInformation description="As reported in B_04.01.0020
Identify the financial entity making use of the ICT service(s) using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'lei_of_the_entity_using_ict_service',
    key: 'lei_of_the_entity_using_ict_service',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Identification code of the ICT third-party service provider - <span>B.02.02.0030</span></p>
        <AdditionalInformation description="As reported in B_05.01.0010
Code to identify the ICT third-party service provider as reported in B_05.01.0010 for that provider.
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'ict_service_provider_code',
    key: 'ict_service_provider_code',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Type of code to identify the ICT third-party service provider - <span>B.02.02.0040</span></p>
        <AdditionalInformation description="As reported in B_05.01.0020
Type of code to identify the ICT third-party service provider in B_02.02.0030 as reported in B_05.01.0020 for that provider.
(pattern)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_ict_service_provider_code',
    key: 'type_of_ict_service_provider_code',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Function identifier - <span>B.02.02.0050</span></p>
        <AdditionalInformation description="As defined by the financial entity in B_06.01.0010
(pattern)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'function_identifier',
    key: 'function_identifier',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Type of ICT services - <span>B.02.02.0060</span></p>
        <AdditionalInformation description="One of the types of ICT services referred to in Annex III
(closed set of options)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_ict_services',
    key: 'type_of_ict_services',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Start date of the contractual arrangement - <span>B.02.02.0070</span></p>
        <AdditionalInformation description="Identify the date of entry into force of the contractual arrangement as stipulated in the contractual arrangement using the ISO 8601 (yyyy–mm–dd) code
(date)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'start_date',
    key: 'start_date',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>End date of the contractual arrangement - <span>B.02.02.0080</span></p>
        <AdditionalInformation description="Identify the end date as stipulated in the contractual arrangement using the ISO 8601 (yyyy–mm–dd) code. Where the contractual arrangement is indefinite, it shall be filled in with ‘9999-12-31’. Where the contractual arrangement has been terminated on a date different than the end date, this shall be filled in with the termination date.
Where the contractual arrangement foresees a renewal, this shall be filled in with the date of the contract renewal as stipulated in the contractual arrangement.
(date)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'end_date',
    key: 'end_date',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Reason of the termination or ending of the contractual arrangement - <span>B.02.02.0090</span></p>
        <AdditionalInformation description="Where the contractual arrangement has been terminated or ended, identify the reason of the termination or ending of the contractual arrangements using one of the options in the following closed list:
1.	Termination not for cause: The contractual arrangement has expired/ended and has not been renewed by any of the parties;
2.	Termination for cause: The contractual arrangement has been terminated, the ICT third-party service provider being in a breach of applicable law, regulations or contractual provisions;
3.	Termination for cause: The contractual arrangement has been terminated, due to the fact that impediments of the ICT third-party service provider capable of altering the supported function have been identified;
4.	Termination for cause: The contractual arrangement has been terminated due to weaknesses of the ICT third-party service provider regarding the management and security of sensitive data or information of any of the counterparties;
5.	Termination following a request by a competent authority: The contractual arrangement has been terminated following a request by a competent Authority.
6.	Other: The contractual arrangement has been terminated by any of the parties for any other reason than the reasons referred to in points 1 to 5.
(closed set of options)
(mandatory if the contractual arrangement is terminated)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'termination_reason',
    key: 'termination_reason',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Notice period for the financial entity making use of the ICT service(s) - <span>B.02.02.0100</span></p>
        <AdditionalInformation description="Identify the notice period for terminating the contractual arrangement by the financial entity in a business-as-usual case. The notice period shall be expressed as number of calendar days from the counterparty’s receipt of the request to terminate the ICT service.
(natural number)
(mandatory if the ICT service is supporting a critical or important function)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'entity_notice_period',
    key: 'entity_notice_period',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>Notice period for the ICT third-party service provider - <span>B.02.02.0110</span></p>
        <AdditionalInformation description="Identify the notice period for terminating the contractual arrangement by the direct ICT third-party service provider in a business-as-usual case. The notice period shall be expressed as number of calendar days from the counterparty’s receipt of the request to terminate the ICT service.
(natural number)
(mandatory if the ICT service is supporting a critical or important function)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'provider_notice_period',
    key: 'provider_notice_period',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Country of the governing law of the contractual arrangement - <span>B.02.02.0120</span></p>
        <AdditionalInformation description="Identify the country of the governing law of the contractual arrangement using the ISO 3166–1 alpha–2 code.
(country)
(mandatory if the ICT service is supporting a critical or important function)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'governing_law_country',
    key: 'governing_law_country',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Country of provision of the ICT services - <span>B.02.02.0130</span></p>
        <AdditionalInformation description="Identify the country from where the ICT services are provided using the ISO 3166–1 alpha–2 code.
(country)
(mandatory if the ICT service is supporting a critical or important function)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'country_of_ict_services',
    key: 'country_of_ict_services',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
      <p>  Storage of data - <span>B.02.02.0140</span></p>
        <AdditionalInformation description="Is the ICT service related to (or does it foresee) storage of data (even temporarily)? 
One of the options provided in the following closed list:
1.	Yes;
2.	No.
[Yes/No]
(mandatory if the ICT service is supporting a critical or important function)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'storage_of_data',
    key: 'storage_of_data',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p> Location of the data at rest (storage) - <span>B.02.02.0150</span></p>
        <AdditionalInformation description="Identify the country of location of the data at rest (storage) using the ISO 3166–1 alpha–2 code.
If there are several countries of location, additional row(s) shall be used for each country.
(country)
(mandatory if ’Yes’ is reported in B_02.02.0140)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'data_location_storage',
    key: 'data_location_storage',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>Location of management of the data (processing) - <span>B.02.02.0160</span></p>
        <AdditionalInformation description="Identify the country of location of the management of the data (processing) using the ISO 3166–1 alpha–2 code.
If there are several countries of location, additional row(s) shall be used for each country.
(country)
(mandatory if the ICT service is based on or foresees data processing)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'data_location_processing',
    key: 'data_location_processing',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>Sensitiveness of the data stored by the ICT third-party service provider - <span>B.02.02.0170</span></p>
        <AdditionalInformation description="Identify the level of sensitiveness of the data stored or processed by the ICT third-party service provider using one of the options provided in the following closed list:
1.	Low
2.	Medium;
3.	High.
The most sensitive data take precedence: e.g. if both ‘Medium’ and ‘High’ apply, then ‘High’ shall be selected.
(closed set of options)
(mandatory if the ICT third-party service provider stores data and if the ICT service is supporting a critical or important function or material part thereof)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'data_sensitiveness',
    key: 'data_sensitiveness',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Level of reliance on the ICT service supporting the critical or important function. - <span>B.02.02.0180</span></p>
        <AdditionalInformation description="One of the options in the following closed list shall be used:
1.	Not significant;
2.	Low reliance: in case of disruption of the services, the supported functions would not be significantly impacted (no interruption, no important damage) or disruption can be resolved quickly and with minimal impact on the functions supported;
3.	Material reliance: in case of disruption of the services, the supported functions would be significantly impacted if the disruption lasts more than a few minutes/ few hours, and the disruption may cause damages, but is still manageable;
4.	Full reliance: in case of disruption of the services, the supported functions would be immediately and severely interrupted/damaged, for a long period.
(closed set of options)
(mandatory if the ICT service is supporting a critical or important function or material part thereof)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'level_of_reliance',
    key: 'level_of_reliance',
  },
];


const B0202: React.FC<B0202Props> = ({ entityName }) => {
  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchRT0202(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          contractual_arangement_ref_num:
            item['Contractual arrangement ref. number'],
          country_of_ict_services: item['Country of ICT services'],
          data_location_processing: item['Data location (processing)'],
          data_location_storage: item['Data location (storage)'],
          data_sensitiveness: item['Data sensitiveness'],
          end_date: item['End date'],
          entity_notice_period: item['Entity notice period'],
          function_identifier: item['Function identifier'],
          governing_law_country: item['Governing law country'],
          ict_service_provider_code: item['ICT service provider code'],
          lei_of_the_entity_using_ict_service:
            item['LEI of the entity using ICT service'],
          level_of_reliance: item['Level of reliance'],
          provider_notice_period: item['Provider notice period'],
          start_date: item['Start date'],
          storage_of_data: item['Storage of data'],
          termination_reason: item['Termination reason'],
          type_of_ict_service_provider_code:
            item['Type of ICT service provider code'],
          type_of_ict_services: item['Type of ICT services'],
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0202;
