import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18nIsoCountries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { RootState } from 'store/configureStore';
import { Input, notification } from 'antd';
import CustomTable, {
  EditableColumnType,
} from 'components/CustomTable/CustomTable';
import useFilters from 'utils/hooks/useFilters';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { ICountryComponentDataType } from 'store/ducks/DataEntry/Entities/types';
import {
  set_countryComponent,
  set_isCountryAdded,
} from 'store/ducks/DataEntry/Entities/entitySlice';
import useEntityStatus from 'utils/hooks/useEntityStatus';
import * as Styled from '../../DataEntry.styled';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import CustomSelect from 'components/CustomSelect';

const CountryComponent: React.FC = () => {
  useEntityStatus();
  const { query } = useFilters();
  const [dataSource, setDataSource] = useState<ICountryComponentDataType[]>([]);
  const [initialData, setInitialData] = useState<ICountryComponentDataType[]>(
    [],
  );
  const [combinedData, setCombinedData] = useState<ICountryComponentDataType[]>(
    [],
  );
  const [countries, setCountries] = useState<
    { label: string; value: string }[]
  >([]);
  const { countryComponent, addRowCount, entities, nameOfEntity } = useSelector(
    (state: RootState) => state.entities,
  );
  const dispatch = useDispatch();
  i18nIsoCountries.registerLocale(enLocale);
  const isLoggedIn = localStorage.getItem('authToken');

  useEffect(() => {
    setCombinedData([...countryComponent, ...dataSource]);
  }, [dataSource, countryComponent]);

  const handleSave = (row: ICountryComponentDataType) => {
    const isRowInDataSource = dataSource.some((item) => item.key === row.key);

    if (isRowInDataSource) {
      const updatedDataSource = dataSource.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );

      setDataSource(updatedDataSource);
      setCombinedData([...updatedDataSource, ...countryComponent]);
    } else {
      const updatedEntityType = countryComponent.map((item) =>
        item.key === row.key ? { ...item, ...row } : item,
      );
      setCombinedData([...dataSource, ...updatedEntityType]);
      dispatch(set_countryComponent(updatedEntityType));
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await entityOp.fetchCountries();
      if (response?.data.countries) {
        const formattedOptions = response.data.countries.map(
          (reasons: { country_name: any; id: any }) => ({
            label: reasons.country_name,
            value: reasons.id,
          }),
        );
        setCountries(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCountry = async () => {
    try {
      const response = await entityOp.fetchCountryByEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data?.country;
      const mappedData = [
        {
          key: data?.id,
          id: data?.id,
          country_name: data?.country_name || '',
          iso_code: data?.iso_code || '',
          description: data?.description || '',
          entity_name: response?.data?.entity_name || query.entity,
        },
      ];

      setDataSource(mappedData);
      setInitialData(mappedData);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchCountry();
      fetchCountries();
    }
  }, [query]);

  let uniqueIdCounter = 0;

  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now() % 1_000_000_000;
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId =
      (timestampPart * 1000 + randomPart + uniqueIdCounter) % 2_147_483_648;

    return uniqueId;
  };
  
  useEffect(() => {
    if (addRowCount === 0) {
      dispatch(set_countryComponent([]));
    }
  }, [addRowCount, countryComponent.length, dispatch]);

  const areAllFieldsFilled = (
    countryComponent: ICountryComponentDataType[],
  ) => {
    return countryComponent.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  useEffect(() => {
    if (addRowCount === 1) {
      const allFieldsFilled = areAllFieldsFilled(countryComponent);
      if (allFieldsFilled) {
        dispatch(set_isCountryAdded(true));
      } else {
        dispatch(set_isCountryAdded(false));
      }
    }
  }, [countryComponent, addRowCount]);

  const handleSubmitCountry = async () => {
    if (JSON.stringify(combinedData) === JSON.stringify(initialData)) {
      notification.info({
        message: 'No Changes Detected',
        description: 'There are no changes to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }
    if (countryComponent.length !== 0) {
      const allFieldsFilled = areAllFieldsFilled(countryComponent);
      if (!allFieldsFilled) {
        notification.warning({
          message: 'Not submitted',
          description:
            'Please fill in all the required fields in Country Component.',
          placement: 'topRight',
          duration: 20,
        });
        return;
      } else {
        dispatch(set_isCountryAdded(true));
      }
    }

    try {
      const payload = {
        entity_name: query.entity,
        data_list: [...combinedData],
        token: isLoggedIn,
      };
      const response = await entityOp.fetchCountry(payload);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const columns: EditableColumnType[] = [
    {
      title: (
        <Styled.TitleComponent>
          Country of the financial entity
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the country of the financial entity using the ISO 3166–1 alpha–2 code. (country)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'country_name',
      width: '30%',
      editable: false,
      render: (text) => (
        <Input value={text} disabled />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          Country ISO code
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation description="Identify the ISO 3166–1 alpha–2 code of the country where the license or the registration of the entity reported in the register of information has been issued." />
        </Styled.TitleComponent>
      ),
      dataIndex: 'iso_code',
      editable: false,
      render: (text) => (
        <Input value={text} disabled />
      ),
    },
    { title: 'Description', dataIndex: 'description', editable: true },
    {
      title: (
        <>
          Entity Name <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'entity_name',
      editable: false,
      render: (text) => (
        <Input value={text} disabled />
      ),
    },
  ];

  return (
    <Styled.StyledWrapper
      hasEntity={!!query.entity}
      style={{ marginTop: '64px' }}
    >
      <CustomTable
        columns={columns}
        dataSource={combinedData}
        handleSave={handleSave}
      />
      <Styled.AreaButton type="primary" onClick={handleSubmitCountry}>
        Submit
      </Styled.AreaButton>
    </Styled.StyledWrapper>
  );
};

export default CountryComponent;
