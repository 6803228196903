import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  contractual_arrangement_reference_number: string;
  identification_code_of_ICT_provider: string;
  type_of_code_of_ICT_provider: string;
  type_of_ICT_services: string;
  sustainability_of_ICT_provider: string;
  reason_of_substitutable: string;
  date_of_last_audit: string;
  existance_of_exit_plan: string;
  possibility_of_reintegration: string;
  impact_of_discontinuing_ICT_services: string;
  alternative_ICT_identified: string;
  identification_of_alternative_ICT_TPP: string;
}

interface B0701Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>Contractual arrangement reference number - <span>B.07.01.0010</span></p>
        <AdditionalInformation description="As reported in B_02.01.0010
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'contractual_arrangement_reference_number',
    key: 'contractual_arrangement_reference_number',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>Identification code of the ICT third-party service provider - <span>B.07.01.0020</span></p>
        <AdditionalInformation description="As reported in B_05.01.0010
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'identification_code_of_ICT_provider',
    key: 'identification_code_of_ICT_provider',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Type of code to identify the ICT third-party service provider - <span>B.07.01.0030</span></p>
        <AdditionalInformation description="As reported in B_05.01.0020
(pattern)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_code_of_ICT_provider',
    key: 'type_of_code_of_ICT_provider',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Type of ICT services - <span>B.07.01.0040</span></p>
        <AdditionalInformation description="One of the types of ICT services referred to in Annex III
(closed set of options)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'type_of_ICT_services',
    key: 'type_of_ICT_services',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p>Substitutability of the ICT third-party service provider - <span>B.07.01.0050</span></p>
        <AdditionalInformation description="Use this column to provide the results of the financial entity’s assessment in relation to the degree of substitutability of the ICT third- party service provider to perform the specific ICT services supporting a critical or important function.
One of the options in the following closed list shall be used:
1.	Not substitutable;
2.	Highly complex substitutability;
3.	Medium	complexity	in	terms	of substitutability;
4.	Easily substitutable.
(closed set of options)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'sustainability_of_ICT_provider',
    key: 'sustainability_of_ICT_provider',
  },
  {
      title: (
        <Styled.AddttionalInfoContainer>
          <p> Reason where the ICT third- party service provider is considered not substitutable or difficult to be substitutable - <span>B.07.01.0060</span></p>
          <AdditionalInformation description="One of the options in the following closed list shall be used:
1.	The lack of real alternatives, even partial, due to the limited number of ICT third- party service providers active on a specific market, or the market share of the relevant ICT third-party service provider, or the technical complexity or sophistication involved, including in relation to any proprietary technology, or the specific features of the ICT third- party service provider’s organisation or activity;
2.	Difficulties in relation to a partial or full migration of the relevant data and workloads from the relevant ICT third- party service provider to another ICT third-party service provider or by reintegrating them in the financial entity’s operations, due either to significant financial costs, time or other resources that the migration process may entail, or to an increased ICT risk or other operational risks to which the financial entity might be exposed;
3.	Both reasons referred to in points 1 and 2.
(closed set of options)
(mandatory in case “not substitutable” or “highly complex substitutability” is selected in B_07.01.0041)" />
        </Styled.AddttionalInfoContainer>
      ),
    width: 200,
    dataIndex: 'reason_of_substitutable',
    key: 'reason_of_substitutable',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p> Date of the last audit on the ICT third-party service provider - <span>B.07.01.0070</span></p>
        <AdditionalInformation description="Use this column to provide the date of the last audit on the specific ICT services provided by the ICT third-party service provider.
This column relates to audits conducted by any of the following:
(a)	the internal audit department or any other additional qualified personnel of the financial entity;
(b)	a joint team together with other clients of the same ICT third-party service provider (‘pooled audit’);
(c)	a third party appointed by the supervised entity to audit the service provider.

This column does not relate to the reception or reference date of third-party certifications or internal audit reports of the ICT third- party service provider, the annual monitoring date of the arrangement by the financial entity or the date of review of the risk assessment performed by the financial entity.
This column shall be used to report all types of audits performed by any of the subjects referred to in points (a), (b) and (c) concerning fully or partially the ICT services provided by the ICT third-party service provider.
To report the date, the ISO 8601 (yyyy– mm–dd) code shall be used.
Where no audit has been performed, it shall be filled in with ‘9999-12-31’.
(date)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'date_of_last_audit',
    key: 'date_of_last_audit',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
      <p>Existence of an exit plan - <span>B.07.01.0080</span></p>
        <AdditionalInformation description="Use this column to report the existence of an exit plan from the ICT third-party service provider in relation to the specific ICT service provided.
One of the options in the following closed list shall be used:
1.	Yes;
2.	No.
[Yes/No]" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'existance_of_exit_plan',
    key: 'existance_of_exit_plan',
    render: (value) => (value === 'true' ? 'Yes' : 'No'),
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
     <p> Possibility of reintegration of the contracted ICT service - <span>B.07.01.0090</span></p>
        <AdditionalInformation description="One of the options in the following closed list shall be used:
1.	Easy;
2.	Difficult;
3.	Highly complex.
Use this column where the ICT service is provided by an ICT third-party service provider that is not an ICT intra-group service provider
(closed set of options)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'possibility_of_reintegration',
    key: 'possibility_of_reintegration',
    render: (value) => (value === 'true' ? 'Yes' : 'No'),
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
          <p>Impact of discontinuing the ICT services - <span>B.07.01.0100</span></p>
        <AdditionalInformation description="Use this column to provide the impact for the financial entity of discontinuing the ICT services provided by the ICT third-party service provider according to the financial entity’s assessment.
            One of the options in the following closed list shall be used:
            1.	Low
            2.	Medium;
            3.	High;
            4.	Assessment not performed.
            (closed set of options)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'impact_of_discontinuing_ICT_services',
    key: 'impact_of_discontinuing_ICT_services',
  },
  {
      title: (
        <Styled.AddttionalInfoContainer>
       <p>Are there alternative ICT third-party service providers identified?  - <span>B.07.01.0110</span></p>
          <AdditionalInformation description="One of the options in the following closed list shall be used:
          1.	Yes;
          2.	No;
          7. Assessment not performed.
          For each ICT third-party service provider supporting a critical or important function, the assessment to identify an alternative service provider shall be performed.
          (closed set of options)" />
        </Styled.AddttionalInfoContainer>
      ),
    width: 200,
    dataIndex: 'alternative_ICT_identified',
    key: 'alternative_ICT_identified',
    render: (value) => (value === 'true' ? 'Yes' : 'No'),
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
     <p> Identification of alternative ICT TPP - <span>B.07.01.0120</span></p>
        <AdditionalInformation description="If ‘Yes’ is reported in B_07.01.0110, additional information may be provided in this column
(optional)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 200,
    dataIndex: 'identification_of_alternative_ICT_TPP',
    key: 'identification_of_alternative_ICT_TPP',
  },
];

const B0701: React.FC<B0701Props> = ({ entityName }) => {
  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchRT0701(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          contractual_arrangement_reference_number:
            item['Arrangement reference number'],
          identification_code_of_ICT_provider:
            item['ICT service provider identification code'],
          type_of_code_of_ICT_provider:
            item['Type of ICT service provider code'],
          type_of_ICT_services: item['Type of ICT services'],
          sustainability_of_ICT_provider:
            item['Substitutability of ICT provider'],
          reason_of_substitutable: item['Reason for non-substitutability'],
          date_of_last_audit: item['Date of last audit'],
          existance_of_exit_plan: item['Exit plan exists'],
          possibility_of_reintegration: item['Reintegration possible'],
          impact_of_discontinuing_ICT_services:
            item['Impact of discontinuing ICT services'],
          alternative_ICT_identified: item['Alternative providers identified'],
          identification_of_alternative_ICT_TPP:
            item['Alternative ICT TPP identification'],
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0701;
