import React, { useState, useEffect } from 'react';
import { Button, notification, Input, Table, Modal } from 'antd';
import {
  CloseCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { functionOp } from 'store/ducks/DataEntry/Functions';
import useFilters from 'utils/hooks/useFilters';
import {
  IData,
  IICTService,
  ICertificate,
  IFunction,
  IFunctionLevels,
} from 'store/ducks/DataEntry/Functions/types';
import * as Styled from '../../DataEntry.styled';
import DynamicField from 'components/DynamicCalendarField/DynamicField';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { globalOp } from 'store/ducks/Global';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';

interface EditableColumnType<T> extends ColumnType<T> {
  editable?: true;
}

interface EditedData {
  [key: string]: string | number | boolean | undefined;
}
const criticalityOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Assessment not performed', value: 'assessment_not_performed' },
];

const doraCriticality = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: 'Unknown', value: false },
];

const mariskCriticalityOptions = [
  { label: 'high', value: 'High' },
  { label: 'medium', value: 'Medium' },
  { label: 'low', value: 'Low' },
];


const Function = () => {
  const { query } = useFilters();
  const { confirm } = Modal;
  const [data, setData] = useState<IFunction[]>([]);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [changedData, setChangedData] = useState<Record<string, any>[]>([]);
  const [licensedActivities, setLicensedActivities] = useState([]);
  const [ictSystems, setICTSystems] = useState([]);
  const [tppNames, setTppNames] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [possibility, setPossibility] = useState([]);
  const [fLevels, setFLevels] = useState([]);
  const [impact, setImpact] = useState([]);
  const [criticallyAssessments, setCriticallyAssessments] = useState<
    { label: string; value: string }[]
  >([]);
  const [functionNames, setFunctionNames] = useState<
    { label: string; value: string }[]
  >([]);
  const [dataSensitiveness, setDataSensitiveness] = useState<
    { label: string; value: string }[]
  >([]);
  const [services, setServices] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [sensitivenessOfData, setSensitivenessOfData] = useState([]);
  const [isTooltipVisible, setIsTooltipVisible] = useState<{
    [key: string]: boolean;
  }>({});

  const addNewIctSystem = (rowKey: string | number) => {
    const newIctSystem: IICTService = {
      ict_systems_id: generateUniqueId(),
      ict_systems_name: '',
      ict_systems_description: '',
      ict_systems_start_date: '',
      ict_systems_end_date: '',
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.f_function_id === rowKey) {
          return {
            ...record,
            ict_systems: [...record.ict_systems, { ...newIctSystem }],
          };
        }
        return record;
      }),
    );
  };
  const addNewFunctionLevel = (rowKey: string | number) => {
    const newFunctionLevels: IFunctionLevels = {
      function_level_id: generateUniqueId(),
      function_level_name: '',
      function_level_description: '',
    };

    setData((prevData) =>
      prevData.map((record) => {
        if (record.f_function_id === rowKey) {
          return {
            ...record,
            function_levels: [
              ...record.function_levels,
              { ...newFunctionLevels },
            ],
          };
        }
        return record;
      }),
    );
  };

  const addNewCertificate = (rowKey: string | number) => {
    const newCertificate: ICertificate = {
      certificates_id: generateUniqueId(),
      certificates_description: '',
      certificates_validity: '',
      certificates_date_of_issuance: '',
      certificates_date_of_certificate_register: '',
      certificates_third_party_service_provider_id: '',
      certificates_function_id: '',
      certificates_ict_service_id: '',
      certificates_registry: '',
      certificates_end_date: '',
      certificates_start_date: '',
    };

    setData((prevData) => {
      return prevData.map((record) => {
        if (record.f_function_id === rowKey) {
          return {
            ...record,
            certificates: [...record.certificates, newCertificate],
          };
        }
        return record;
      });
    });
  };
  let uniqueIdCounter = 0;
  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const timestampPart = Date.now() % 1_000_000_000;
    const randomPart = Math.floor(Math.random() * 1000);

    const uniqueId =
      (timestampPart * 1000 + randomPart + uniqueIdCounter) % 2_147_483_648;

    return uniqueId;
  };

  const dataSensitivenessId = generateUniqueId();
  const addNewData = (rowKey: string | number) => {
    const newData: IData = {
      sensitivenessOfDataStoredbyICTTPSP_id: '',
      sensitivenessOfDataStoredbyICTTPSP_name: '',
      sensitivenessOfDataStoredbyICTTPSP_description: '',
      dataSensitiveness_sensitiveness_id: dataSensitivenessId,
      dataSensitiveness_sensitiveness_name: '',
      dataSensitiveness_description: '',
      dataSensitiveness_ict_system_id: '',
      data_id: generateUniqueId(),
      data_location_of_data_at_rest: '',
      data_location_of_data_management: '',
      dataSensitiveness_id: '',
      data_sensitiveness_id: dataSensitivenessId,
      data_description: '',
      data_ict_system_id: '',
      data_start_date: '',
      data_end_date: '',
    };
    setData((prevData) => {
      return prevData.map((record) => {
        if (record.f_function_id === rowKey) {
          return {
            ...record,
            data: [...record.data, newData],
          };
        }
        return record;
      });
    });
  };

  const fetchLicencedActivities = async () => {
    try {
      const response = await functionOp.fetchLicencedActivities();
      if (response?.data.licensed_activities) {
        const formattedOptions = response.data.licensed_activities.map(
          (activity: { name: any; id: any }) => ({
            label: activity.name,
            value: activity.id,
          }),
        );
        setLicensedActivities(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchICTSystems = async () => {
    try {
      const response = await functionOp.fetchICTSystems();
      if (response?.data.ict_systems) {
        const formattedOptions = response.data.ict_systems.map(
          (system: { name: any; id: any }) => ({
            label: system.name,
            value: system.id,
          }),
        );
        setICTSystems(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAssessments = async () => {
    try {
      const response = await ict_serviceOp.fetchCriticalityAssessments();
      if (response?.data.criticality_assessments) {
        const formattedOptions = response.data.criticality_assessments.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setCriticallyAssessments(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSensitivinessOfData = async () => {
    try {
      const response = await globalOp.fetchSensitivenessOfData();
      if (response?.data.sensitiveness_of_data) {
        const formattedOptions = response.data.sensitiveness_of_data.map(
          (sensitiveness: { name: any; id: any }) => ({
            label: sensitiveness.name,
            value: sensitiveness.id,
          }),
        );
        setSensitivenessOfData(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTPPID = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;
      const dataForTable = data.map((item: any) => ({
        label: item?.third_party_service_provider_name || 'Unknown',
        value: item?.id || '',
      }));
      setTppNames(dataForTable);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const fetchFunctionNames = async () => {
    try {
      const response = await tppOp.fetchFunctions();
      if (response?.data.functions) {
        const formattedOptions = response.data.functions.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setFunctionNames(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataSensitiveness = async () => {
    try {
      const response = await functionOp.fetchDataSensitiveness();
      if (response?.data.data_sensitiveness) {
        const formattedOptions = response.data.data_sensitiveness.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setDataSensitiveness(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchImpactOfDiscontinuingICTServices = async () => {
    try {
      const response =
        await ict_serviceOp.fetchImpactOfDiscontinuingICTServices();
      if (response?.data.impact_of_discontinuing_ict_services) {
        const formattedOptions =
          response.data.impact_of_discontinuing_ict_services.map(
            (impact: { description: any; id: any }) => ({
              label: impact.description,
              value: impact.id,
            }),
          );
        setImpact(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReintegrationPossibilities = async () => {
    try {
      const response = await ict_serviceOp.fetchReintegrationPossibilities();
      if (response?.data.possibility_of_reintegration) {
        const formattedOptions = response.data.possibility_of_reintegration.map(
          (possibility: { description: any; id: any }) => ({
            label: possibility.description,
            value: possibility.id,
          }),
        );
        setPossibility(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFunctionLevels = async () => {
    try {
      const response = await functionOp.fetchFunctionLevels();
      if (response?.data.function_levels) {
        const formattedOptions = response.data.function_levels.map(
          (level: { name: any; id: any }) => ({
            label: level.name,
            value: level.id,
          }),
        );
        setFLevels(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await tppOp.fetchICTServices();
      if (response?.data.ict_services) {
        const formattedOptions = response.data.ict_services.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setServices(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchFunctionbyEntity = async () => {
    try {
      const response = await functionOp.fetchFunctionbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.functions;
      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        isNew: false,
        isEdited: false,
        critically_assessment_id: item.criticality_assessment.id,
        critically_assessment_name: item.criticality_assessment.name,
        critically_assessment_description:
          item.criticality_assessment.description,
        critically_assessment_dora_criticality:
          item.criticality_assessment.dora_criticality,
        critically_assessment_dora_regulator_criticality:
          item.criticality_assessment.dora_regulator_criticality,
        critically_assessment_functions_criticality:
          item.criticality_assessment.functions_criticality,
        critically_assessment_ict_services_criticality:
          item.criticality_assessment.ict_services_criticality,
        critically_assessment_marisk_criticality:
          item.criticality_assessment.marisk_criticality,
        auswahl_function_discont_id: item.impact_of_discontinuing.id,
        auswahl_function_discont_name: item.impact_of_discontinuing.name,
        auswahl_function_discont_description:
          item.impact_of_discontinuing.description,
        selection_licenced_act_id: item.licensed_activity.id,
        selection_licenced_act_name: item.licensed_activity.name,
        selection_licenced_act_description: item.licensed_activity.description,
        ict_systems: item.ict_systems
          ? item.ict_systems.map((ict_system: any) => ({
              ict_systems_id: ict_system.id,
              ict_systems_name: ict_system.name,
              ict_systems_description: ict_system.description,
              ict_systems_start_date: ict_system.start_date,
              ict_systems_end_date: ict_system.end_date,
            }))
          : [],
        certificates: item.certificates
          ? item.certificates.map((certificate: any) => ({
              certificates_id: certificate.id,
              certificates_description: certificate.description,
              certificates_validity: certificate.validity,
              certificates_date_of_issuance: certificate.date_of_issuance,
              certificates_registry: certificate.certificate_registry,
              certificates_date_of_certificate_register:
                certificate.date_of_certificate_register,
              certificates_third_party_service_provider_id:
                certificate.third_party_service_provider_id,
              certificates_function_id: certificate.function_id,
              certificates_ict_service_id: certificate.ict_service_id,
              certificates_end_date: certificate.end_date,
              certificates_start_date: certificate.start_date,
            }))
          : [],
        function_levels: item.function_level
          ? item.function_level.map((level: any) => ({
              function_level_id: level.id,
              function_level_name: level.name,
              function_level_description: level.description,
            }))
          : [],
        f_function_id: item.function_id,
        f_function_identifier: item.function_identifier,
        f_function_name: item.function_name,
        f_criticality_assessment_id: item.criticality_assessment_id,
        f_reasons_for_criticality: item.reasons_for_criticality,
        f_date_of_last_assessment: item.date_of_last_assessment,
        f_recovery_time_objective: item.recovery_time_objective,
        f_recovery_point_objective: item.recovery_point_objective,
        f_impact_of_discontinuing_id: item.impact_of_discontinuing_id,
        f_licensed_activity_id: item.licensed_activity_id,
        f_entity_id: item.entity_id,
        f_possibility_of_reintegration_id: item.possibility_of_reintegration_id,
        // f_function_level_id: item.function_level_id,
        f_function_description: item.description,
        f_start_date: item.start_date,
        f_end_date: item.end_date,
        // function_level_id: item.function_level.id,
        // function_level_name: item.function_level.name,
        // function_level_description: item.function_level.description,

        data: item.data
          ? item.data.map((iData: any) => ({
              sensitivenessOfDataStoredbyICTTPSP_id:
                iData.sensitiveness.data_stored_by_ict_tpsp.id,
              sensitivenessOfDataStoredbyICTTPSP_name:
                iData.sensitiveness.data_stored_by_ict_tpsp.name,
              sensitivenessOfDataStoredbyICTTPSP_description:
                iData.sensitiveness.data_stored_by_ict_tpsp.description,
              dataSensitiveness_id: iData.sensitiveness.id,
              dataSensitiveness_sensitiveness_name: iData.sensitiveness.name,
              dataSensitiveness_description: iData.sensitiveness.description,
              data_id: iData.id,
              data_location_of_data_at_rest: iData.location_of_data_at_rest,
              data_location_of_data_management:
                iData.location_of_data_management,
              data_sensitiveness_id: iData.sensitiveness.id,
              data_description: iData.description,
              data_ict_system_id: iData.ict_system_id,
              data_start_date: iData.start_date,
              data_end_date: iData.end_date,
            }))
          : [],
      }));
      setData(mappedData);
    } catch (error) {
      console.log(error, 'error');
      setData([]);
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchFunctionbyEntity();
      fetchLicencedActivities();
      fetchICTSystems();
      fetchSensitivinessOfData();
      fetchTPPID();
      fetchFunctionNames();
      fetchServices();
      fetchDataSensitiveness();
      fetchAssessments();
      fetchImpactOfDiscontinuingICTServices();
      fetchReintegrationPossibilities();
      fetchFunctionLevels();
    }
  }, [query]);

  const handleOpenTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const handleCloseTooltip = (key: string) => {
    setIsTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const handleEditChange = (
    e: React.ChangeEvent<HTMLInputElement> | string,
    fieldName: string,
    rowKey: string,
  ) => {
    const value = typeof e === 'string' ? e : e.target.value;
    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    const updatedRow = data.find(
      (row) => row.f_function_id.toString() == rowKey,
    );
      console.log(updatedRow, 'updatedRow1');
    if (updatedRow) {
      const updatedRowWithChanges: Record<string, any> = {
        ...updatedRow,
        [fieldName]: value,
      };

      if (
        fieldName === 'ict_systems_id' ||
        'certificates_id' ||
        'data_id' ||
        'function_level_id'
      ) {
        if (updatedRow.ict_systems) {
          const match = fieldName.match(/^(\d+)_systems_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];

            const targetIndex = updatedRow.ict_systems.findIndex(
              (system) => system.ict_systems_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.ict_systems[targetIndex] = {
                ...updatedRow.ict_systems[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching system found for idFromFieldName (${idFromFieldName}) in ict_systems`,
              );
            }
          }
        }
        if (updatedRow.certificates) {
          const match = fieldName.match(/^(\d+)_newcert_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];

            const targetIndex = updatedRow.certificates.findIndex(
              (cert) => cert.certificates_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.certificates[targetIndex] = {
                ...updatedRow.certificates[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching certificate found for idFromFieldName (${idFromFieldName}) in certificates`,
              );
            }
          }
        }
        if (updatedRow.data) {
          const match = fieldName.match(/^(\d+)_newItem_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.data.findIndex(
              (item) => item.data_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.data[targetIndex] = {
                ...updatedRow.data[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching data found for idFromFieldName (${idFromFieldName}) in datas`,
              );
            }
          }
        }
        if (updatedRow.function_levels) {
          const match = fieldName.match(/^(\d+)_levels_(.+)$/);
          if (match) {
            const idFromFieldName = match[1];
            const actualFieldName = match[2];
            const targetIndex = updatedRow.function_levels.findIndex(
              (item: { function_level_id: { toString: () => string } }) =>
                item.function_level_id.toString() === idFromFieldName,
            );

            if (targetIndex !== -1) {
              updatedRowWithChanges.function_levels[targetIndex] = {
                ...updatedRow.function_levels[targetIndex],
                [actualFieldName]: value,
              };
            } else {
              console.log(
                `No matching function levels found for idFromFieldName (${idFromFieldName}) in function levels`,
              );
            }
          }
        }
      }
      const cleanedUpRow = Object.keys(updatedRowWithChanges).reduce(
        (acc: Record<string, any>, key) => {
          const newKey = key.replace(/^[^a-zA-Z]+/, '');
          acc[newKey] = updatedRowWithChanges[key];
          return acc;
        },
        {},
      );

      setChangedData((prevChangedData) => {
        const newChangedData = [...prevChangedData];
        const existingRowIndex = newChangedData.findIndex(
          (row) => row.f_function_id === cleanedUpRow.f_function_id,
        );

        if (existingRowIndex !== -1) {
          const existingRow = newChangedData[existingRowIndex];
          const mergedRow = { ...existingRow, ...cleanedUpRow };
          if (JSON.stringify(existingRow) !== JSON.stringify(mergedRow)) {
            newChangedData[existingRowIndex] = mergedRow;
          }
        } else {
          newChangedData.push(cleanedUpRow);
        }

        return newChangedData;
      });
    } else {
      console.log('No match found for f_function_id:', rowKey);
    }
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = data.filter((item) => item.key !== key);
        fetchFunctionbyEntity();
        setData(updatedDataSource);
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: IFunction) => {
    if (record.f_function_id === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The function ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the function with ID ${record.f_function_id}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await functionOp.inactivateFunction([
            record.f_function_id,
          ]);
          fetchFunctionbyEntity();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  const updateChangedData = (editedData: any, changedData: any) => {
    if (editedData && typeof editedData === 'object') {
      Object.keys(editedData).forEach((key: string) => {
        const keyFromEditedData = parseInt(key.split('_')[0]);
        const matchingChangedRow = changedData.find((dataItem: any) => {
          return dataItem.f_function_id === keyFromEditedData;
        });

        if (matchingChangedRow) {
          const fieldName = key.replace(/^\d+_/, '');
          if (matchingChangedRow.hasOwnProperty(fieldName)) {
            matchingChangedRow[fieldName] = editedData[key];
          }
          if (
            fieldName.includes('certificates_start_date') ||
            fieldName.includes('certificates_end_date')
          ) {
            matchingChangedRow[fieldName] =
              editedData[key] !== undefined
                ? String(editedData[key] ?? '')
                : null;
          }
        }
      });
    } else {
      console.error('editedData is not an object:', editedData);
    }

    setChangedData([...changedData]);
  };

  useEffect(() => {
    updateChangedData(editedData, changedData);
  }, [editedData]);

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.f_function_id,
  }));

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['topRight'] as ['topRight'],
  });

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  const addNewRow = () => {
    const levelId = generateUniqueId();
    const newRowTemplate: IFunction = {
      key: generateUniqueId(),
      isNew: true,
      critically_assessment_id: '',
      critically_assessment_name: '',
      critically_assessment_description: '',
      auswahl_function_discont_id: generateUniqueId(),
      auswahl_function_discont_name: '',
      auswahl_function_discont_description: '',
      selection_licenced_act_id: '',
      selection_licenced_act_name: '',
      selection_licenced_act_description: '',
      f_function_id: generateUniqueId(),
      f_function_identifier: '',
      f_function_name: '',
      f_criticality_assessment_id: '',
      f_reasons_for_criticality: '',
      f_date_of_last_assessment: '',
      f_recovery_time_objective: '',
      f_recovery_point_objective: '',
      f_impact_of_discontinuing_id: '',
      f_licensed_activity_id: '',
      f_entity_id: query.entityId,
      f_possibility_of_reintegration_id: '',
      // f_function_level_id: generateUniqueId(), // TODO
      f_function_description: '',
      f_start_date: '',
      f_end_date: '',
      // function_level_id: generateUniqueId(),
      // function_level_name: '',
      // function_level_description: '',
      data_ict_system_name: '',
      data_ict_system_description: '',
      certificates: [
        {
          certificates_id: generateUniqueId(),
          certificates_description: '',
          certificates_registry: '',
          certificates_date_of_issuance: '',
          certificates_validity: '',
          certificates_date_of_certificate_register: '',
          certificates_third_party_service_provider_id: '',
          certificates_function_id: '',
          certificates_ict_service_id: '',
          certificates_end_date: '',
          certificates_start_date: '',
        },
      ],
      ict_systems: [
        {
          ict_systems_id: generateUniqueId(),
          ict_systems_name: '',
          ict_systems_description: '',
          ict_systems_start_date: '',
          ict_systems_end_date: '',
        },
      ],
      function_levels: [
        {
          function_level_id: generateUniqueId(),
          function_level_name: '',
          function_level_description: '',
        },
      ],
      data: [
        {
          sensitivenessOfDataStoredbyICTTPSP_id: '',
          sensitivenessOfDataStoredbyICTTPSP_name: '',
          sensitivenessOfDataStoredbyICTTPSP_description: '',
          dataSensitiveness_id: '',
          dataSensitiveness_sensitiveness_id: dataSensitiveness,
          dataSensitiveness_sensitiveness_name: '',
          dataSensitiveness_description: '',
          dataSensitiveness_ict_system_id: '',
          data_id: generateUniqueId(),
          data_location_of_data_at_rest: '',
          data_location_of_data_management: '',
          data_sensitiveness_id: dataSensitiveness,
          data_description: '',
          data_ict_system_id: '',
          data_start_date: '',
          data_end_date: '',
        },
      ],
      data_sensitiveness_name: '',
      data_sensitiveness_description: '',
      critically_assessment_dora_criticality: '',
      critically_assessment_dora_regulator_criticality: '',
      critically_assessment_functions_criticality: '',
      critically_assessment_ict_services_criticality: '',
      critically_assessment_marisk_criticality: '',
    };
    const newRow = structuredClone(newRowTemplate);

    setData((prevData) => [...prevData, newRow]);
    setChangedData((prevChangedData) => [...prevChangedData, newRow]);
  };

  const handleSubmit = async () => {
    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited Functions to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    const formattedData = {
      entity_name: query.entity,
      SensetevenessOfData: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.sensitivenessOfDataStoredbyICTTPSP_id,
            name: itemData.sensitivenessOfDataStoredbyICTTPSP_name,
            description:
              itemData.sensitivenessOfDataStoredbyICTTPSP_description,
          })),
        )
        .flat(),

      DataSensitiveness: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.data_sensitiveness_id,
            name: itemData.dataSensitiveness_sensitiveness_name,
            sensitiveness_of_data_stored_by_ict_tpsp_id:
              itemData.sensitivenessOfDataStoredbyICTTPSP_id,
            // description: itemData.dataSensitiveness_id, // ?
            description: itemData.dataSensitiveness_description,
          })),
        )
        .flat(),

      Data: changedData
        .map((item) =>
          item.data.map((itemData: IData) => ({
            id: itemData.data_id,
            location_of_data_at_rest: itemData.data_location_of_data_at_rest,
            location_of_data_management:
              itemData.data_location_of_data_management,
            sensitiveness_id: itemData.dataSensitiveness_id,
            ict_system_id: itemData.data_ict_system_id,
            description: itemData.data_description,
            start_date: itemData.data_start_date,
            end_date: itemData.data_end_date,
          })),
        )
        .flat(),

      CriticalityAssessment: changedData.map((item) => ({
        id: item.critically_assessment_id,
        name: item.critically_assessment_name,
        description: item.critically_assessment_description,
        critically_assessment_dora_criticality:
          item.critically_assessment_dora_criticality,
        critically_assessment_dora_regulator_criticality:
          item.critically_assessment_dora_regulator_criticality,
        critically_assessment_functions_criticality:
          item.critically_assessment_functions_criticality,
        critically_assessment_ict_services_criticality:
          item.critically_assessment_ict_services_criticality,
        critically_assessment_marisk_criticality:
          item.critically_assessment_marisk_criticality,
      })),

      AuswahlFunctionDiscont: changedData.map((item) => ({
        id: item.auswahl_function_discont_id,
        name: item.auswahl_function_discont_name,
        description: item.auswahl_function_discont_description,
      })),

      SelectionLicencedAct: changedData.map((item) => ({
        id: item.selection_licenced_act_id,
        name: item.selection_licenced_act_name,
        description: item.selection_licenced_act_description,
        entity_id: query.entityId,
      })),

      // FunctionLevel: changedData.map((item) => ({
      //   id: item.function_level_id,
      //   name: item.function_level_name,
      //   description: item.function_level_description,
      // })),
      Function: changedData.map((item) => ({
        id: item.f_function_id,
        function_identifier: item.f_function_identifier,
        function_name: item.f_function_name,
        criticality_assessment_id: item.f_criticality_assessment_id,
        reasons_for_criticality: item.f_reasons_for_criticality,
        date_of_last_assessment: item.f_date_of_last_assessment,
        recovery_time_objective: item.f_recovery_time_objective,
        recovery_point_objective: item.f_recovery_point_objective,
        impact_of_discontinuing_id: item.f_impact_of_discontinuing_id,
        licensed_activity_id: item.f_licensed_activity_id,
        entity_id: item.f_entity_id,
        possibility_of_reintegration_id: item.f_possibility_of_reintegration_id,
        function_level_id: item.f_function_level_id,
        description: item.f_function_description,
        start_date: item.f_start_date,
        end_date: item.f_end_date,
      })),

      ICTSystems: changedData
        .map((item) =>
          item.ict_systems.map((ict: IICTService) => ({
            id: ict.ict_systems_id,
            name: ict.ict_systems_name,
            description: ict.ict_systems_description,
            start_date: ict.ict_systems_start_date,
            end_date: ict.ict_systems_end_date,
          })),
        )
        .flat(),
      FunctionLevel: changedData
        .map((item) => {
          console.log("Processing item:", item); 
          const mappedLevels = item.function_levels.map((level: IFunctionLevels) => {
            console.log("Mapping level:", level);
            const transformedLevel = {
              id: level.function_level_id,
              name: level.function_level_name,
              description: level.function_level_description,
            };
            console.log("Transformed level:", transformedLevel);
            return transformedLevel;
          });
          console.log("Mapped levels for item:", mappedLevels);
          return mappedLevels;
        })
        .flat(),
      Certificates: changedData
        .map((item) =>
          item.certificates.map((cert: ICertificate) => ({
            id: cert.certificates_id,
            description: cert.certificates_description,
            validity: cert.certificates_validity,
            date_of_issuance: cert.certificates_date_of_issuance,
            certificates_registry: cert.certificates_registry,
            date_of_certificate_register:
              cert.certificates_date_of_certificate_register,
            third_party_service_provider_id:
              cert.certificates_third_party_service_provider_id,
            function_id: cert.certificates_function_id,
            ict_service_id: cert.certificates_ict_service_id,
            end_date: cert.certificates_end_date,
            start_date: cert.certificates_start_date,
          })),
        )
        .flat(),
    };

    try {
      const response = await functionOp.insertFunctions(formattedData);
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  }; //TODO

  const columns: EditableColumnType<IFunction>[] = [
    {
      title: (
        <Styled.TitleComponent>
          Function
          <Styled.Asterisx>*</Styled.Asterisx>
        </Styled.TitleComponent>
      ),
      dataIndex: 'f_function_name',
      editable: true,
      fixed: 'left',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_function_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_function_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Criticality or importance assessment
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Use this column to indicate whether the function is critical or important according to the financial entity’s assessment. One of the options in the following closed list shall be used:
          1. Yes
          2. No
          3. Assessment not performed"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'critically_assessment_name',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_name`;
        const idFieldName = `${record.f_function_id}_critically_assessment_id`;
        const idFunctionFieldName = `${record.f_function_id}_f_criticality_assessment_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={criticallyAssessments}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_name
            }
            onChange={(value: string, option: any) => {
              const eventName = {
                target: { value },
              } as React.ChangeEvent<HTMLInputElement>;

              const eventId = {
                target: { value: option.id },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(eventName, fieldName, rowKey);
              handleEditChange(eventId, idFieldName, rowKey);
              handleEditChange(eventId, idFunctionFieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Dora Criticality
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Use this column to indicate whether the function is critical or important according to the financial entity’s assessment. One of the options in the following closed list shall be used:
              1.	Yes;
              2.	No;
              3.	Assessment not performed.
              (closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'critically_assessment_dora_criticality',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_dora_criticality`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={criticalityOptions}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_dora_criticality
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <>
          Dora regulator criticality<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_dora_regulator_criticality',
      // editable: false,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_dora_regulator_criticality`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={doraCriticality}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_dora_regulator_criticality
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Functions Criticality
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Use this column to indicate whether the function is critical or important according to the financial entity’s assessment. One of the options in the following closed list shall be used:
              1.	Yes;
              2.	No;
              3.	Assessment not performed.
              (closed set of options)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'critically_assessment_functions_criticality',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_functions_criticality`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={criticalityOptions}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_functions_criticality
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
           ICT services criticality
          <Styled.Asterisx>*</Styled.Asterisx>
        </Styled.TitleComponent>
      ),
      dataIndex: 'critically_assessment_ict_services_criticality',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_ict_services_criticality`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={criticalityOptions}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_ict_services_criticality
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <>
          Marisk criticality<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_marisk_criticality',
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_marisk_criticality`;
        const rowKey = record.key;
        return (
          <CustomSelect
            value={record.marisk_criticality}
            onChange={(e: string | React.ChangeEvent<HTMLInputElement>) => handleEditChange(e, fieldName, rowKey)}
            options={mariskCriticalityOptions}
            filterOption={(input: string, option: { label: string }) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
        />
        );
      },
    },
    {
      title: (
        <>
          Critically assessment description<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'critically_assessment_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_critically_assessment_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.critically_assessment_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Auswahl function discont name<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'auswahl_function_discont_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_auswahl_function_discont_name`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.auswahl_function_discont_name
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Auswahl function discont description
          <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'auswahl_function_discont_description',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_auswahl_function_discont_description`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.auswahl_function_discont_description
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Selection of licenced activity<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'selection_licenced_act_name',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_selection_licenced_act_name`;
        const fieldId = `${record.f_function_id}_selection_licenced_act_id`;
        const fieldIdFunction = `${record.f_function_id}_selection_f_licensed_activity_id`;

        const rowKey = record.key;
        return (
          <CustomSelect
            options={licensedActivities}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.selection_licenced_act_name
            }
            onChange={(selectedOption: { value: string; label: string }) => {
              const { value, label } = selectedOption;

              const nameEvent = {
                target: {
                  value: label,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              const idEvent = {
                target: {
                  value: value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(nameEvent, fieldName, rowKey);
              handleEditChange(idEvent, fieldId, rowKey);
              handleEditChange(idEvent, fieldIdFunction, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Function identifier
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="The function identifier shall be composed by the letter F (capital letter) followed by a natural number (e.g. “F1” for the 1st function identifier and “Fn” for the nth function identifier with “n” being a natural number).
Each combination between ‘LEI of the financial entity making use of the ICT service(s)’ (B_06.01.0040), ‘Function name’ (B_06.01.0030) and ‘Licenced activity’ (B_06.01.0020) shall have a unique function identifier.
Example: a financial entity which operates under two licensed activities (‘activity A’ and ‘activity B’) will be given two unique ‘function identifiers’ for the same function X (e.g. Sales) performed for activity A and activity B.
(pattern)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'f_function_identifier',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_function_identifier`;
        const rowKey = record.key;
        return (
          <Input
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_function_identifier
            }
            onChange={(e) => handleEditChange(e, fieldName, rowKey)}
          />
        );
      },
    },
    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'f_start_date',
      render: (text: string, record) => {
        const fieldName = `${record.f_function_id}_f_start_date`;
        const rowKey = record.key;
        return (
          <Styled.InputField>
            <DynamicField
              value={
                editedData.hasOwnProperty(fieldName)
                  ? String(editedData[fieldName] ?? '')
                  : String(record.f_start_date ?? '')
              }
              fieldName={fieldName}
              rowKey={record.key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEditChange(e, fieldName, rowKey)
              }
              type="date"
            />
          </Styled.InputField>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'f_end_date',
      render: (text: string, record) => {
        const fieldName = `${record.f_function_id}_f_end_date`;
        const rowKey = record.key;
        return (
          <Styled.InputField>
            <DynamicField
              value={
                editedData.hasOwnProperty(fieldName)
                  ? String(editedData[fieldName] ?? '')
                  : String(record.f_end_date ?? '')
              }
              fieldName={fieldName}
              rowKey={record.key}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEditChange(e, fieldName, rowKey)
              }
              type="date"
            />
          </Styled.InputField>
        );
      },
    },
    {
      title: 'Impact of discontinuing',
      dataIndex: 'f_impact_of_discontinuing_id',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_impact_of_discontinuing_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={impact}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_impact_of_discontinuing_id
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: (
        <>
          ICT Systems<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ict_systems',
      key: 'ict_systems',
      render: (_, record) => {
        return (
          <div>
            {(record.ict_systems as IICTService[]).map(
              (system: IICTService) => {
                const idFieldName = `${system.ict_systems_id}_systems_ict_systems_id`;
                const nameFieldName = `${system.ict_systems_id}_systems_ict_systems_name`;
                const descriptionFieldName = `${system.ict_systems_id}_systems_ict_systems_description`;
                const startDateFieldName = `${system.ict_systems_id}_systems_ict_systems_start_date`;
                const endDateFieldName = `${system.ict_systems_id}_systems_ict_systems_end_date`;
                const rowKey = record.key;
                const handleRemoveIctSystem = (
                  activityKey: number,
                  ictSystemId: number,
                ) => {
                  Modal.confirm({
                    title: 'Are you sure you want to remove this ICT system?',
                    content: 'This action cannot be undone.',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      try {
                        const response = await globalOp.inactivateICTSystem([
                          ictSystemId,
                        ]);
                        const updatedAICTSystems = data.map((system) => {
                          if (system.key === activityKey) {
                            return {
                              ...system,
                              ict_systems: system.ict_systems.filter(
                                (system: IICTService) =>
                                  system.ict_systems_id !== ictSystemId,
                              ),
                            };
                          }
                          return system;
                        });
                        setData(updatedAICTSystems);
                        fetchFunctionbyEntity();
                      } catch (error) {
                        console.error('Failed to remove ICT system:', error);
                      }
                    },
                    onCancel: () => {
                      notification.info({
                        message: 'Action Canceled',
                        description:
                          'The ICT system removal has been canceled.',
                        duration: 10,
                      });
                    },
                  });
                };
                return (
                  <Styled.TooltipContainer
                    key={system.ict_systems_id}
                    visible={isTooltipVisible[idFieldName]}
                    title={
                      <div>
                        <Styled.TooltipButtonContainer>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => handleCloseTooltip(idFieldName)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                          <Button
                            size="small"
                            type="text"
                            danger
                            onClick={() =>
                              handleRemoveIctSystem(
                                record.key,
                                system.ict_systems_id,
                              )
                            }
                          >
                            <DeleteOutlined />
                          </Button>
                        </Styled.TooltipButtonContainer>
                        <Styled.InputField>
                          <strong>
                            Name <Styled.Asterisx>*</Styled.Asterisx>{' '}
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(nameFieldName)
                                ? typeof editedData[nameFieldName] === 'boolean'
                                  ? editedData[nameFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[nameFieldName])
                                : String(system.ict_systems_name)
                            }
                            onChange={(e) =>
                              handleEditChange(e, nameFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>Description</strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(descriptionFieldName)
                                ? typeof editedData[descriptionFieldName] ===
                                  'boolean'
                                  ? editedData[descriptionFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[descriptionFieldName])
                                : String(system.ict_systems_description)
                            }
                            onChange={(e) =>
                              handleEditChange(e, descriptionFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Start date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(startDateFieldName)
                                ? String(editedData[startDateFieldName] ?? '')
                                : String(system.ict_systems_start_date ?? '')
                            }
                            fieldName={startDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                startDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            End date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(endDateFieldName)
                                ? String(editedData[endDateFieldName] ?? '')
                                : String(system.ict_systems_end_date ?? '')
                            }
                            fieldName={endDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(e, endDateFieldName, record.key)
                            }
                            type="date"
                          />
                        </Styled.InputField>
                      </div>
                    }
                    open={isTooltipVisible[idFieldName] || false}
                    trigger={[]}
                  >
                    <Styled.TagComponent
                      color="blue"
                      onClick={() => handleOpenTooltip(idFieldName)}
                    >
                      <Input
                        value={
                          editedData.hasOwnProperty(nameFieldName)
                            ? typeof editedData[nameFieldName] === 'boolean'
                              ? editedData[nameFieldName]
                                ? 'true'
                                : 'false'
                              : String(editedData[nameFieldName])
                            : String(system.ict_systems_name)
                        }
                        readOnly
                        addonAfter={<PlusCircleOutlined />}
                      />
                    </Styled.TagComponent>
                    <Styled.GlobalStyle />
                  </Styled.TooltipContainer>
                );
              },
            )}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewIctSystem(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Certificates<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'certificates',
      key: 'certificates',
      render: (_, record) => {
        return (
          <div>
            {(record.certificates as ICertificate[]).map(
              (cert: ICertificate) => {
                const idFieldName = `${cert.certificates_id}_newcert_certificates_id`;
                const descriptionFieldName = `${cert.certificates_id}_newcert_certificates_description`;
                const registryFieldName = `${cert.certificates_id}_newcert_certificates_registry`;
                const issuanceDateFieldName = `${cert.certificates_id}_newcert_certificates_date_of_issuance`;
                const validityFieldName = `${cert.certificates_id}_newcert_certificates_validity`;
                const dateOfRegisterFieldName = `${cert.certificates_id}_newcert_certificates_date_of_certificate_register`;
                const startDateFieldName = `${cert.certificates_id}_newcert_certificates_start_date`;
                const endDateFieldName = `${cert.certificates_id}_newcert_certificates_end_date`;
                const fieldNameTpp = `${cert.certificates_id}_newcert_certificates_third_party_service_provider_id`;
                const fieldNameFunctions = `${cert.certificates_id}_newcert_certificates_function_id`;
                const fieldNameServices = `${cert.certificates_id}_newcert_certificates_ict_service_id`;
                const rowKey = record.key;
                const handleRemoveCertificates = (
                  certificateKey: number,
                  certificateID: number,
                ) => {
                  Modal.confirm({
                    title: 'Are you sure you want to remove this Certificate?',
                    content: 'This action cannot be undone.',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      try {
                        const response = await tppOp.inactivateCertificates([
                          certificateID,
                        ]);
                        const updatedCertificates = data.map((certificate) => {
                          if (certificate.key === certificateKey) {
                            return {
                              ...certificate,
                              certificates: certificate.certificates.filter(
                                (certificate: ICertificate) =>
                                  certificate.certificates_id !== certificateID,
                              ),
                            };
                          }
                          return certificate;
                        });
                        setData(updatedCertificates);
                        fetchFunctionbyEntity();
                      } catch (error) {
                        console.error('Failed to remove Certificate:', error);
                      }
                    },
                    onCancel: () => {
                      notification.info({
                        message: 'Action Canceled',
                        description:
                          'The Certificate removal has been canceled.',
                        duration: 10,
                      });
                    },
                  });
                };
                return (
                  <Styled.TooltipContainer
                    key={cert.certificates_id}
                    visible={isTooltipVisible[idFieldName]}
                    title={
                      <div>
                        <Styled.TooltipButtonContainer>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => handleCloseTooltip(idFieldName)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                          <Button
                            size="small"
                            type="text"
                            danger
                            onClick={() =>
                              handleRemoveCertificates(
                                record.key,
                                cert.certificates_id,
                              )
                            }
                          >
                            <DeleteOutlined />
                          </Button>
                        </Styled.TooltipButtonContainer>
                        <Styled.InputField>
                          <strong>
                            Registry <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(registryFieldName)
                                ? typeof editedData[registryFieldName] ===
                                  'boolean'
                                  ? editedData[registryFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[registryFieldName])
                                : String(cert.certificates_registry)
                            }
                            onChange={(e) =>
                              handleEditChange(e, registryFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Issuance date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(issuanceDateFieldName)
                                ? String(
                                    editedData[issuanceDateFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_issuance ?? '',
                                  )
                            }
                            fieldName={issuanceDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                issuanceDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Validity <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(validityFieldName)
                                ? typeof editedData[validityFieldName] ===
                                  'boolean'
                                  ? editedData[validityFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[validityFieldName])
                                : String(cert.certificates_validity)
                            }
                            onChange={(e) =>
                              handleEditChange(e, validityFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>Certificate Description</strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(descriptionFieldName)
                                ? typeof editedData[descriptionFieldName] ===
                                  'boolean'
                                  ? editedData[descriptionFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[descriptionFieldName])
                                : String(cert.certificates_description)
                            }
                            onChange={(e) =>
                              handleEditChange(e, descriptionFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Date of register<Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(dateOfRegisterFieldName)
                                ? String(
                                    editedData[dateOfRegisterFieldName] ?? '',
                                  )
                                : String(
                                    cert.certificates_date_of_certificate_register ??
                                      '',
                                  )
                            }
                            fieldName={dateOfRegisterFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                dateOfRegisterFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>

                        <Styled.InputField>
                          <strong>
                            Start date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(startDateFieldName)
                                ? String(editedData[startDateFieldName] ?? '')
                                : String(cert.certificates_start_date ?? '')
                            }
                            fieldName={startDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(
                                e,
                                startDateFieldName,
                                record.key,
                              )
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            End date <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <DynamicField
                            value={
                              editedData.hasOwnProperty(endDateFieldName)
                                ? String(editedData[endDateFieldName] ?? '')
                                : String(cert.certificates_end_date ?? '')
                            }
                            fieldName={endDateFieldName}
                            rowKey={record.key}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              handleEditChange(e, endDateFieldName, record.key)
                            }
                            type="date"
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Certificate TPP id
                            <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <CustomSelect
                            options={tppNames}
                            value={
                              editedData.hasOwnProperty(fieldNameTpp)
                                ? String(editedData[fieldNameTpp] ?? '')
                                : String(
                                    cert.certificates_third_party_service_provider_id ??
                                      '',
                                  )
                            }
                            fieldName={fieldNameTpp}
                            onChange={(value: any) => {
                              const syntheticEvent = {
                                target: { value },
                              } as React.ChangeEvent<HTMLInputElement>;
                              handleEditChange(
                                syntheticEvent,
                                fieldNameTpp,
                                record.key,
                              );
                            }}
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            Certificate Function id
                            <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <CustomSelect
                            options={functionNames}
                            value={
                              editedData.hasOwnProperty(fieldNameFunctions)
                                ? String(editedData[fieldNameFunctions] ?? '')
                                : String(cert.certificates_function_id ?? '')
                            }
                            fieldName={fieldNameFunctions}
                            onChange={(value: any) => {
                              const syntheticEvent = {
                                target: { value },
                              } as React.ChangeEvent<HTMLInputElement>;
                              handleEditChange(
                                syntheticEvent,
                                fieldNameFunctions,
                                record.key,
                              );
                            }}
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>
                            ICT service id <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <CustomSelect
                            options={services}
                            value={
                              editedData.hasOwnProperty(fieldNameServices)
                                ? String(editedData[fieldNameServices] ?? '')
                                : String(cert.certificates_ict_service_id ?? '')
                            }
                            fieldName={fieldNameServices}
                            onChange={(value: any) => {
                              const syntheticEvent = {
                                target: { value },
                              } as React.ChangeEvent<HTMLInputElement>;
                              handleEditChange(
                                syntheticEvent,
                                fieldNameServices,
                                record.key,
                              );
                            }}
                          />
                        </Styled.InputField>
                      </div>
                    }
                    open={isTooltipVisible[idFieldName] || false}
                    trigger={[]}
                  >
                    <Styled.TagComponent
                      color="blue"
                      onClick={() => handleOpenTooltip(idFieldName)}
                    >
                      <Input
                        value={
                          editedData.hasOwnProperty(descriptionFieldName)
                            ? typeof editedData[descriptionFieldName] ===
                              'boolean'
                              ? editedData[descriptionFieldName]
                                ? 'true'
                                : 'false'
                              : String(editedData[descriptionFieldName])
                            : String(cert.certificates_description)
                        }
                        readOnly
                        addonAfter={<PlusCircleOutlined />}
                      />
                    </Styled.TagComponent>
                    <Styled.GlobalStyle />
                  </Styled.TooltipContainer>
                );
              },
            )}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewCertificate(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Data<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'data',
      key: 'data',
      render: (_, record) => {
        return (
          <div>
            {record.data.map((itemData: IData, index: number) => {
              const idFieldName = `${itemData.data_id}_newItem_data_id`;
              const descriptionFieldName = `${itemData.data_id}_newItem_data_description`;
              const restLocationFieldName = `${itemData.data_id}_newItem_data_location_of_data_at_rest`;
              const startDateFieldName = `${itemData.data_id}_newItem_data_start_date`;
              const endDateFieldName = `${itemData.data_id}_newItem_data_end_date`;
              const managementLocationFieldName = `${itemData.data_id}_newItem_data_location_of_data_management`;
              const sensitivenessNameFieldName = `${itemData.data_id}_newItem_dataSensitiveness_sensitiveness_name`;
              const sensitivenessDescriptionFieldName = `${itemData.data_id}_newItem_dataSensitiveness_description`;
              const sensitivenessOfDataStoredByICTTPSPName = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_name`;
              const sensitivenessOfDataStoredByICTTPSPId = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_id`;
              const sensitivenessOfDataStoredByICTTPSPDescription = `${itemData.data_id}_newItem_sensitivenessOfDataStoredbyICTTPSP_description`;
              const ictSystemID = `${itemData.data_id}_newItem_dataSensitiveness_ict_system_id`;
              const ictSystemIDData = `${itemData.data_id}_newItem_data_ict_system_id`;

              const rowKey = record.key;
              const handleRemoveData = (dataKey: number, dataID: number) => {
                Modal.confirm({
                  title: 'Are you sure you want to remove this Data?',
                  content: 'This action cannot be undone.',
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: async () => {
                    const dataToRemove = data.find(
                      (item) => item.key === dataKey,
                    );
                    try {
                      const response = await globalOp.inactivateData([dataID]);
                      const updatedDatas = data.map((data) => {
                        if (data.key === dataKey) {
                          return {
                            ...data,
                            datas: data.data.filter(
                              (data: IData) => data.data_id !== dataID,
                            ),
                          };
                        }
                        return data;
                      });
                      setData(updatedDatas);
                      fetchFunctionbyEntity();
                    } catch (error) {
                      console.error('Failed to remove Data:', error);
                    }
                  },
                  onCancel: () => {
                    notification.info({
                      message: 'Action Canceled',
                      description: 'The Data removal has been canceled.',
                      duration: 10,
                    });
                  },
                });
              };
              return (
                <Styled.TooltipContainer
                  key={itemData.data_id || index}
                  visible={isTooltipVisible[idFieldName]}
                  title={
                    <div>
                      <Styled.TooltipButtonContainer>
                        <Button
                          size="small"
                          type="text"
                          onClick={() => handleCloseTooltip(idFieldName)}
                        >
                          <CloseCircleOutlined />
                        </Button>
                        <Button
                          size="small"
                          type="text"
                          danger
                          onClick={() =>
                            handleRemoveData(record.key, itemData.data_id)
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                      </Styled.TooltipButtonContainer>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Location of the data at rest (storage)
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Identify the country of location of the data at rest (storage) using the ISO 3166–1 alpha–2 code.
If there are several countries of location, additional row(s) shall be used for each country.
(country)
(mandatory if ’Yes’ is reported in B_02.02.0140)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(restLocationFieldName)
                              ? typeof editedData[restLocationFieldName] ===
                                'boolean'
                                ? editedData[restLocationFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[restLocationFieldName])
                              : String(itemData.data_location_of_data_at_rest)
                          }
                          onChange={(e) =>
                            handleEditChange(e, restLocationFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Location of management of the data (processing)
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Identify the country of location of the management of the data (processing) using the ISO 3166–1 alpha–2 code.
If there are several countries of location, additional row(s) shall be used for each country.
(country)
(mandatory if the ICT service is based on or foresees data processing)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              managementLocationFieldName,
                            )
                              ? typeof editedData[
                                  managementLocationFieldName
                                ] === 'boolean'
                                ? editedData[managementLocationFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[managementLocationFieldName],
                                  )
                              : String(
                                  itemData.data_location_of_data_management,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              managementLocationFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Description:</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(descriptionFieldName)
                              ? typeof editedData[descriptionFieldName] ===
                                'boolean'
                                ? editedData[descriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[descriptionFieldName])
                              : String(itemData.data_description)
                          }
                          onChange={(e) =>
                            handleEditChange(e, descriptionFieldName, rowKey)
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Start date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(startDateFieldName)
                              ? String(editedData[startDateFieldName] ?? '')
                              : String(itemData.data_start_date ?? '')
                          }
                          fieldName={startDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, startDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          End date <Styled.Asterisx>*</Styled.Asterisx>
                        </strong>
                        <DynamicField
                          value={
                            editedData.hasOwnProperty(endDateFieldName)
                              ? String(editedData[endDateFieldName] ?? '')
                              : String(itemData.data_end_date ?? '')
                          }
                          fieldName={endDateFieldName}
                          rowKey={record.key}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleEditChange(e, endDateFieldName, record.key)
                          }
                          type="date"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Sensitiveness
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Identify the level of sensitiveness of the data stored or processed by the ICT third-party service provider using one of the options provided in the following closed list:
                                  1.	Low
                                  2.	Medium;
                                  3.	High.
                                  The most sensitive data take precedence: e.g. if both ‘Medium’ and ‘High’ apply, then ‘High’ shall be selected.
                                  (closed set of options)
                                  (mandatory if the ICT third-party service provider stores data and if the ICT service is supporting a critical or important function or material part thereof)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessNameFieldName,
                            )
                              ? typeof editedData[
                                  sensitivenessNameFieldName
                                ] === 'boolean'
                                ? editedData[sensitivenessNameFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(editedData[sensitivenessNameFieldName])
                              : String(itemData.data_description)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessNameFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>Sensitiveness Description</strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessDescriptionFieldName,
                            )
                              ? typeof editedData[
                                  sensitivenessDescriptionFieldName
                                ] === 'boolean'
                                ? editedData[sensitivenessDescriptionFieldName]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[
                                      sensitivenessDescriptionFieldName
                                    ],
                                  )
                              : String(itemData.dataSensitiveness_description)
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessDescriptionFieldName,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            Sensitiveness of data stored by ICT TPSP
                            <Styled.Asterisx>*</Styled.Asterisx>
                            <AdditionalInformation
                              description="Is the ICT service related to (or does it foresee) storage of data (even temporarily)? 
                                  One of the options provided in the following closed list:
                                  1.	Yes;
                                  2.	No.
                                  [Yes/No]
                                  (mandatory if the ICT service is supporting a critical or important function)"
                            />
                          </Styled.TitleComponent>
                        </strong>
                        <CustomSelect
                          options={sensitivenessOfData}
                          placeholder="Select an option"
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessOfDataStoredByICTTPSPName,
                            )
                              ? editedData[
                                  sensitivenessOfDataStoredByICTTPSPName
                                ]
                              : itemData.sensitivenessOfDataStoredbyICTTPSP_name
                          }
                          onChange={(
                            value: string,
                            option: { label: string; value: string },
                          ) => {
                            const idEvent = {
                              target: {
                                value: option.value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(
                              idEvent,
                              sensitivenessOfDataStoredByICTTPSPId,
                              rowKey,
                            );

                            const nameEvent = {
                              target: {
                                value: option.label,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(
                              nameEvent,
                              sensitivenessOfDataStoredByICTTPSPName,
                              rowKey,
                            );
                          }}
                          size="large"
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          Sensitiveness of data stored by ICT TPSP Description
                        </strong>
                        <Input
                          value={
                            editedData.hasOwnProperty(
                              sensitivenessOfDataStoredByICTTPSPDescription,
                            )
                              ? typeof editedData[
                                  sensitivenessOfDataStoredByICTTPSPDescription
                                ] === 'boolean'
                                ? editedData[
                                    sensitivenessOfDataStoredByICTTPSPDescription
                                  ]
                                  ? 'true'
                                  : 'false'
                                : String(
                                    editedData[
                                      sensitivenessOfDataStoredByICTTPSPDescription
                                    ],
                                  )
                              : String(
                                  itemData.sensitivenessOfDataStoredbyICTTPSP_description,
                                )
                          }
                          onChange={(e) =>
                            handleEditChange(
                              e,
                              sensitivenessOfDataStoredByICTTPSPDescription,
                              rowKey,
                            )
                          }
                        />
                      </Styled.InputField>
                      <Styled.InputField>
                        <strong>
                          <Styled.TitleComponent>
                            ICT System
                            <Styled.Asterisx>*</Styled.Asterisx>
                          </Styled.TitleComponent>
                        </strong>
                        <CustomSelect
                          options={ictSystems}
                          placeholder="Select an option"
                          value={
                            editedData.hasOwnProperty(ictSystemID)
                              ? editedData[ictSystemID]
                              : itemData.data_ict_system_id
                          }
                          onChange={(
                            value: string,
                            option: { label: string; value: string },
                          ) => {
                            const idEvent = {
                              target: {
                                value: value,
                              },
                            } as React.ChangeEvent<HTMLInputElement>;
                            handleEditChange(idEvent, ictSystemID, rowKey);
                            handleEditChange(idEvent, ictSystemIDData, rowKey);
                          }}
                          size="large"
                        />
                      </Styled.InputField>
                    </div>
                  }
                  open={isTooltipVisible[idFieldName] || false}
                  trigger={[]}
                >
                  <Styled.TagComponent
                    color="blue"
                    onClick={() => handleOpenTooltip(idFieldName)}
                  >
                    <Input
                      value={
                        editedData.hasOwnProperty(descriptionFieldName)
                          ? typeof editedData[descriptionFieldName] ===
                            'boolean'
                            ? editedData[descriptionFieldName]
                              ? 'true'
                              : 'false'
                            : String(editedData[descriptionFieldName])
                          : String(itemData.data_description)
                      }
                      readOnly
                      addonAfter={<PlusCircleOutlined />}
                    />
                  </Styled.TagComponent>
                  <Styled.GlobalStyle />
                </Styled.TooltipContainer>
              );
            })}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => {
                addNewData(record.key);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Function levels<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'function_levels',
      key: 'function_levels',
      render: (_, record) => {
        return (
          <div>
            {(record.function_levels as IFunctionLevels[]).map(
              (level: IFunctionLevels) => {
                const idFieldName = `${level.function_level_id}_levels_function_level_id`;
                const nameFieldName = `${level.function_level_id}_levels_function_level_name`;
                const descriptionFieldName = `${level.function_level_id}_levels_function_level_description`;
                const rowKey = record.key;
                const handleRemoveFunctionLevel = (functionLevelKey: number, functionLevelID: number) => {
                  Modal.confirm({
                    title: 'Are you sure you want to remove this level?',
                    content: 'This action cannot be undone.',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                      const dataToRemove = data.find(
                        (item) => item.key === functionLevelKey,
                      );
                      try {
                        const response = await functionOp.inactivateFunctionLevel([functionLevelID]);
                        const updatedDatas = data.map((data) => {
                          if (data.key === functionLevelKey) {
                            return {
                              ...data,
                              datas: data.data.filter(
                                (data: IData) => data.data_id !== functionLevelID,
                              ),
                            };
                          }
                          return data;
                        });
                        setData(updatedDatas);
                        fetchFunctionbyEntity();
                      } catch (error) {
                        console.error('Failed to remove Function level:', error);
                      }
                    },
                    onCancel: () => {
                      notification.info({
                        message: 'Action Canceled',
                        description: 'The Function level removal has been canceled.',
                        duration: 10,
                      });
                    },
                  });
                };
                return (
                  <Styled.TooltipContainer
                    key={level.function_level_id}
                    visible={isTooltipVisible[idFieldName]}
                    title={
                      <div>
                        <Styled.TooltipButtonContainer>
                          <Button
                            size="small"
                            type="text"
                            onClick={() => handleCloseTooltip(idFieldName)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                          <Button
                          size="small"
                          type="text"
                          danger
                          onClick={() =>
                            handleRemoveFunctionLevel(record.key, level.function_level_id)
                          }
                        >
                          <DeleteOutlined />
                        </Button>
                        </Styled.TooltipButtonContainer>
                        <Styled.InputField>
                          <strong>
                            Name <Styled.Asterisx>*</Styled.Asterisx>
                          </strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(nameFieldName)
                                ? typeof editedData[nameFieldName] === 'boolean'
                                  ? editedData[nameFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[nameFieldName])
                                : String(level.function_level_name)
                            }
                            onChange={(e) =>
                              handleEditChange(e, nameFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                        <Styled.InputField>
                          <strong>Description</strong>
                          <Input
                            value={
                              editedData.hasOwnProperty(descriptionFieldName)
                                ? typeof editedData[descriptionFieldName] ===
                                  'boolean'
                                  ? editedData[descriptionFieldName]
                                    ? 'true'
                                    : 'false'
                                  : String(editedData[descriptionFieldName])
                                : String(level.function_level_description)
                            }
                            onChange={(e) =>
                              handleEditChange(e, descriptionFieldName, rowKey)
                            }
                          />
                        </Styled.InputField>
                      </div>
                    }
                    open={isTooltipVisible[idFieldName] || false}
                    trigger={[]}
                  >
                    <Styled.TagComponent
                      color="blue"
                      onClick={() => handleOpenTooltip(idFieldName)}
                    >
                      <Input
                        value={
                          editedData.hasOwnProperty(nameFieldName)
                            ? typeof editedData[nameFieldName] === 'boolean'
                              ? editedData[nameFieldName]
                                ? 'true'
                                : 'false'
                              : String(editedData[nameFieldName])
                            : String(level.function_level_name)
                        }
                        readOnly
                        addonAfter={<PlusCircleOutlined />}
                      />
                    </Styled.TagComponent>
                    <Styled.GlobalStyle />
                  </Styled.TooltipContainer>
                );
              },
            )}
            <Styled.AddButton
              type="text"
              icon={<PlusOutlined />}
              onClick={() => addNewFunctionLevel(record.key)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <Styled.TitleComponent>
          Possibility of reintegration of the contracted ICT service
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="One of the options in the following closed list shall be used:
          1.	Easy;
          2.	Difficult;
          3.	Highly complex.
          Use this column where the ICT service is provided by an ICT third-party service provider that is not an ICT intra-group service provider
          (closed set of options)"
          />
        </Styled.TitleComponent>
      ),

      dataIndex: 'f_possibility_of_reintegration_id',
      editable: true,
      render: (_: any, record: any) => {
        const fieldName = `${record.f_function_id}_f_possibility_of_reintegration_id`;
        const rowKey = record.key;
        return (
          <CustomSelect
            options={possibility}
            placeholder="Select an option"
            value={
              editedData.hasOwnProperty(fieldName)
                ? editedData[fieldName]
                : record.f_possibility_of_reintegration_id
            }
            onChange={(value: string) => {
              const event = {
                target: {
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>;

              handleEditChange(event, fieldName, rowKey);
            }}
            size="large"
          />
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button
              danger
              onClick={() => handleInactivate(record as IFunction)}
            >
              Soft delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      <Styled.ButtonDiv isEmpty={dataWithKey.length === 0}>
        {query.entity && (
          <Styled.AreaButton type="primary" onClick={addNewRow}>
            Add Row
          </Styled.AreaButton>
        )}
      </Styled.ButtonDiv>
      <Styled.TooltipTableContainer>
        <Table
          dataSource={dataWithKey}
          rowKey="key"
          scroll={{ x: 'max-content' }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: handlePaginationChange,
            showSizeChanger: pagination.showSizeChanger,
            pageSizeOptions: pagination.pageSizeOptions,
            position: pagination.position,
          }}
          columns={columns}
        />
      </Styled.TooltipTableContainer>
      {dataWithKey.length > 0 && (
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      )}
    </div>
  );
};

export default Function;
