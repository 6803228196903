import React, { useState, useEffect } from 'react';
import useFilters from 'utils/hooks/useFilters';
import i18nIsoCountries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import CustomTable from 'components/CustomTable/CustomTable';
import { notification, DatePicker, Modal, Button } from 'antd';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { branchOp } from 'store/ducks/DataEntry/Branch';
import { EditableColumnType } from 'components/CustomTable/CustomTable';
import { IBranch, DataType } from 'store/ducks/DataEntry/Branch/types';
import * as Styled from '../../DataEntry.styled';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import CustomSelect from 'components/CustomSelect';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';

const Branch: React.FC = () => {
  const { query } = useFilters();
  const { confirm } = Modal;
  const [dataSource, setDataSource] = useState<IBranch[]>([]);
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [entityNames, setEntityNames] = useState<
    { label: string; value: string }[]
  >([]);
  const [competentAuthorities, setCompetentAuthorities] = useState<
    { label: string; value: string }[]
  >([]);
  const [countries, setCountries] = useState<
    { label: string; value: string }[]
  >([]);
  const [valueOfTotalAssets, setValueOfTotalAssets] = useState<
    { label: string; value: string }[]
  >([]);
  const [pageSize, setPageSize] = useState(10);
  i18nIsoCountries.registerLocale(enLocale);
  const isLoggedIn = localStorage.getItem('authToken');

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleDateChange = (key: number, field: string, dateString: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        [field]: dateString,
        isEdited: !item.isNew,
      });
      setDataSource(newData);
    }
  };

  const fetchBranchbyEntity = async () => {
    try {
      const response = await branchOp.fetchBranchbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data;
      const mappedData = [
        {
          key: 0,
          branch_identification_code: data.branch.branch_identification_code,
          id_branch: data.branch.id,
          branch_name: data.branch.branch_name,
          competent_authority_id: data.branch.competent_authority_id,
          start_date: data.branch.start_date,
          end_date: data.branch.end_date,
          description_branch: data.branch.description,
          entity_id: data.branch.entity_id,
          head_office_lei: data.branch.head_office_lei,
          id_country: data.country.id,
          country_name: data.country.name,
          iso_code: data.country.iso_code,
          country_description: data.country.description,
          id_valueOfTotalAssets: data.value_of_total_assets.id,
          value: data.value_of_total_assets.value,
          description_valueOfTotalAssets:
            data.value_of_total_assets.description,
          isNew: false,
          isEdited: false,
        },
      ];
      setDataSource(mappedData);
      setCount(mappedData.length);
    } catch (error) {
      setDataSource([]);
      console.log(error, 'error');
    }
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = dataSource.filter((item) => item.key !== key);
        setDataSource(updatedDataSource);
        fetchBranchbyEntity();
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: IBranch) => {
    if (record.id_branch === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The branch ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the branch with ID ${record.id_branch}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await branchOp.inactivateBranch([record.id_branch]);
          fetchBranchbyEntity();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };

  const fetchEntityNames = async () => {
    try {
      const response = await entityOp.fetchEntityNames();
      if (response?.data) {
        const formattedOptions = response.data.map(
          (reasons: { name_of_entity: any; id: any }) => ({
            label: reasons.name_of_entity,
            value: reasons.id,
          }),
        );
        setEntityNames(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCompetentAuthorities = async () => {
    try {
      const response = await entityOp.fetchCompetentAuthorities();
      if (response?.data.competent_authorities) {
        const formattedOptions = response.data.competent_authorities.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setCompetentAuthorities(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await entityOp.fetchCountries();
      if (response?.data.countries) {
        const formattedOptions = response.data.countries.map(
          (reasons: { country_name: any; id: any }) => ({
            label: reasons.country_name,
            value: reasons.id,
          }),
        );
        setCountries(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchValues = async () => {
    try {
      const response = await tppOp.fetchValues();
      if (response?.data.value_of_total_assets) {
        const formattedOptions = response.data.value_of_total_assets.map(
          (reasons: { value: any; id: any }) => ({
            label: reasons.value,
            value: reasons.id,
          }),
        );
        setValueOfTotalAssets(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (query.entity) {
      fetchBranchbyEntity();
      fetchEntityNames();
      fetchCompetentAuthorities();
      fetchCountries();
      fetchValues();
    }
  }, [query]);

  const handleAddRow = (record?: any, index?: number) => {
    let uniqueIdCounter = 0;

    const generateUniqueId = (): number => {
      uniqueIdCounter += 1;
      const timestampPart = Date.now() % 1_000_000_000;
      const randomPart = Math.floor(Math.random() * 1000);

      const uniqueId =
        (timestampPart * 1000 + randomPart + uniqueIdCounter) % 2_147_483_648;

      return uniqueId;
    };

    const newData: IBranch = {
      key: generateUniqueId(),
      id_branch: generateUniqueId(),
      head_office_lei: '',
      branch_name: '',
      branch_identification_code: '',
      start_date: '',
      end_date: '',
      description_branch: '',
      entity_id: query.entityId,
      competent_authority_id: '',
      country_description: '',
      id_country: '',
      country_name: '',
      iso_code: '',
      id_valueOfTotalAssets: '',
      value: '',
      description_valueOfTotalAssets: '',
      isNew: true,
    };
    const insertIndex = dataSource.findIndex(
      (item) => item.key === record?.key,
    );

    const updatedDataSource = [
      ...dataSource.slice(0, insertIndex + 1),
      newData,
      ...dataSource.slice(insertIndex + 1),
    ];

    setDataSource(updatedDataSource);
    setCount(count + 1);
  };

  const handleSave = (row: IBranch) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleSubmit = async () => {
    const changedData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );

    if (changedData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited Branches to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    const formattedData = {
      entity_name: query.entity,
      data_list: [
        {
          Branch: changedData.map((item) => ({
            id: item.id_branch,
            head_office_lei: item.head_office_lei,
            branch_name: item.branch_name,
            branch_identification_code: item.branch_identification_code,
            description: item.description_branch,
            entity_id: item.entity_id,
            competent_authority_id: item.competent_authority_id,
            country_id: item.id_country,
            value_of_total_assets_id: item.id_valueOfTotalAssets,
            start_date: item.start_date,
            end_date: item.end_date,
          })),
        },
        {
          Country: changedData.map((item) => ({
            id: item.id_country,
            country_name: item.country_name,
            iso_code: item.iso_code,
            description: item.country_description,
          })),
        },
        {
          ValueOfTotalAssets: changedData.map((item) => ({
            id: item.id_valueOfTotalAssets,
            value: item.value,
            description: item.description_valueOfTotalAssets,
          })),
        },
      ],
      token: isLoggedIn,
    };

    try {
      const response = await branchOp.insertBranches(formattedData);
      notification.success({
        message: 'Submission Successful',
        description: 'The data was successfully submitted.',
        placement: 'topRight',
        duration: 20,
      });
    } catch (error) {
      console.error('Submission Error:', error);
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: (
        <Styled.TitleComponent>
          Name of the branch
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the name of the branch
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'branch_name',
      editable: true,
      fixed: 'left',
    },
    {
      title: (
        <Styled.TitleComponent>
          LEI of the financial entity head office of the branch
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="As reported in B_01.02.0010
Identify the financial entity head office of the branch, using the LEI, 20-character, alpha- numeric code based on the ISO 17442 standard
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'head_office_lei',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          Identification code of the branch
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify a branch of a financial entity located outside its home country using a unique code for each branch. One of the options in the following closed list shall be used:
(a)	LEI of the branch if unique for this branch and different from B_01.03.0020;
(b)	other identification code used by the financial entity to identify the branch (where the LEI of the branch is equivalent to the one in template B_01.03.0020 or equivalent to the LEI of another branch).
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'branch_identification_code',
      editable: true,
    },
    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'start_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                handleDateChange(record.key, 'start_date', formattedDateString);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'end_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                handleDateChange(record.key, 'end_date', formattedDateString);
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Branch description',
      dataIndex: 'description_branch',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          Country of the branch
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation description="Identify the ISO 3166–1 alpha–2 code of the country where the branch is located (country)" />
        </Styled.TitleComponent>
      ),
      dataIndex: 'id_country',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={countries}
          value={record.id_country}
          onChange={(value: any) => {
            const selectedCountry = countries.find(
              (country) => country.value === value,
            );

            if (selectedCountry) {
              const countryName = selectedCountry.label;

              const isoCode = i18nIsoCountries.getAlpha2Code(countryName, 'en');

              handleSave({
                ...record,
                id_country: value,
                country_name: selectedCountry?.label || '',
                iso_code: isoCode || '',
              } as IBranch);
            }
          }}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <>
          Iso code<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'iso_code',
      editable: true,
    },

    {
      title: 'Country description',
      dataIndex: 'country_description',
      editable: true,
    },
    {
      title: (
        <>
          Value<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'value',
      editable: true,
    },
    {
      title: 'Description for value of total assets',
      dataIndex: 'description_valueOfTotalAssets',
      editable: true,
    },
    {
      title: (
        <>
          Competent Authority<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'competent_authority_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={competentAuthorities}
          value={record.competent_authority_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              competent_authority_id: value,
            } as IBranch)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <>
          Value of Total Assets <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'id_valueOfTotalAssets',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={valueOfTotalAssets}
          value={record.id_valueOfTotalAssets}
          onChange={(value: any) =>
            handleSave({
              ...record,
              id_valueOfTotalAssets: value,
            } as IBranch)
          }
        />
      ),
    },
    {
      title: 'Create',
      dataIndex: 'add_row',
      key: 'add_row',
      width: '10px',
      fixed: 'right',
      render: (_: any, record: { key: React.Key }, index: number) => (
        <Styled.OperationsRow onClick={() => handleAddRow(record, index)}>
          <PlusOutlined />
        </Styled.OperationsRow>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button danger onClick={() => handleInactivate(record as IBranch)}>
              Soft delete
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    dataSource && (
      <div>
        {query.entity && (
          <Styled.AreaButton onClick={handleAddRow} type="primary">
            Add a row
          </Styled.AreaButton>
        )}
        <CustomTable
          columns={defaultColumns}
          dataSource={dataSource}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        {dataSource.length > 0 && (
          <Styled.AreaButton type="primary" onClick={handleSubmit}>
            Submit
          </Styled.AreaButton>
        )}
      </div>
    )
  );
};

export default Branch;
