import { ThemeList } from 'store/ducks/globalTypes';

function ColorPalettes() {
  const colorPalettes = [
    { label: 'Coastal Serenity', value: ThemeList.MAIN_THEME },
    {
      label: 'Elegant Twilight',
      value: ThemeList.FIRST_THEME,
    },
    {
      label: 'Midnight Horizon',
      value: ThemeList.SECOND_THEME,
    },
    {
      label: 'Blue Mist',
      value: ThemeList.THIRD_THEME,
    },
    {
      label: 'Tropical Drift',
      value: ThemeList.FOURTH_THEME,
    },
    {
      label: 'Rosewood Glow',
      value: ThemeList.FIFTH_THEME,
    },
    {
      label: 'Golden Hearth',
      value: ThemeList.SIXTH_THEME,
    },
    {
      label: 'Serene Waters',
      value: ThemeList.SEVENTH_THEME,
    },
    {
      label: 'Forest Night',
      value: ThemeList.EIGHTH_THEME,
    },
  ];

  return colorPalettes;
}

export default ColorPalettes;
