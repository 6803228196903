import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportingOp } from 'store/ducks/Reportings';
import { RootState } from 'store/configureStore';
import { set_isDisplayed } from 'store/ducks/Reportings/reportingSlice';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import * as Styled from '../Reporting.styled';

interface DataType {
  key: React.Key;
  annual_expense: string;
  arrangement_reference_number: string;
  contract_type: string;
  currency: string;
  overarching_reference_number: string;
}

interface B0201Props {
  entityName: string | null | undefined;
}

const columns: TableColumnsType<DataType> = [
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Contractual arrangement reference number - <span>B.02.01.0010</span></p>
        <AdditionalInformation description="Identify the contractual arrangement between the financial entity or, in case of a group, the group subsidiary and the direct ICT third-party service provider.
The contractual arrangement reference number is the internal reference number of the contractual arrangement assigned by the financial entity.
The contractual arrangement reference number shall be unique and consistent over time at entity, sub-consolidated and consolidated level, where applicable.
The contractual arrangement reference number shall be used consistently across all templates of the register of information when referring to the same contractual arrangement.
For the case where an entity is acting on behalf of a financial entity for all the activities of the financial entity including the ICT services (refer to recital 7) the contractual arrangement reference number can be the contractual arrangement between the entity and its direct ICT third-party service provider.
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'arrangement_reference_number',
    key: 'arrangement_reference_number',
    fixed: 'left',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Type of contractual arrangement - <span>B.02.01.0020</span></p>
        <AdditionalInformation description="Identify the type of contractual arrangement by using one of the options in the following closed list:
1.	standalone arrangement;
2.	overarching / master contractual arrangement;
3.	subsequent or associated arrangement.
(closed set of options)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'contract_type',
    key: 'contract_type',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Overarching contractual arrangement reference number <span>B.02.01.0030</span></p>
        <AdditionalInformation description="Not applicable if the contractual arrangement is the ‘overarching contractual arrangement’ or a ‘standalone arrangement’. In the other cases, report the contractual arrangement reference number of the overarching arrangement, which shall be equal to the value as reported in column B_02.01.0010 when reporting the overarching contractual arrangement.
(alphanumerical)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'overarching_reference_number',
    key: 'overarching_reference_number',
    fixed: 'left',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
        <p>Currency of the amount reported in RT.02.01.0050 - <span>B.02.01.0040</span></p>
        <AdditionalInformation description="Identify the ISO 4217 alphabetic code of the currency used to express the amount in B_02.01.0050.
(currency)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: (
      <Styled.AddttionalInfoContainer>
       <p> Annual expense or estimated cost of the contractual arrangement for the past year - <span>B.02.01.0050</span></p>
        <AdditionalInformation description="Annual expenses or estimated costs (or intragroup transfer) of the ICT services contractual arrangement for the past year. Monetary value shall be reported in units.
The annual expense or estimated cost shall be expressed in the currency reported in B_01.02.0040.
In case of an overarching arrangement with subsequent or associated arrangements, the sum of the annual expenses or estimated costs reported for the overarching arrangement and the subsequent or associated arrangements shall be equal to the total expenses or estimated costs for the overall contractual arrangement. There shall be no repetition or duplication of annual expenses or estimated costs. The following cases should be reflected:
(a) where the annual expenses or estimate costs are not determined at the level of the overarching arrangement (i.e. they are 0), the annual expenses or estimated costs shall be reported at the level of each subsequent or associated arrangements.
(b)	where the annual expenses or estimated costs cannot be reported for each of the subsequent or associated arrangements, the total annual expenses or estimated costs shall be reported at the level of the overarching arrangement.
(c)	where there are annual expenses or estimated costs related to each level of the arrangement, i.e. overarching and subsequent or associated, and that information is available, the annual expenses or estimated costs shall be reported without duplication at each level of the contractual arrangement.
(monetary)" />
      </Styled.AddttionalInfoContainer>
    ),
    width: 100,
    dataIndex: 'annual_expense',
    key: 'annual_expense',
  },
];

const B0201: React.FC<B0201Props> = ({ entityName }) => {
  const { date, is_displayed } = useSelector(
    (state: RootState) => state.reportings,
  );
  const [data, setData] = useState<DataType[]>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(set_isDisplayed(false));
    const params = {
      entity_name: entityName,
      date,
    };
    try {
      const fetchedData = await reportingOp.fetchRT0201(params);
      if (Array.isArray(fetchedData)) {
        const formattedData = fetchedData.map((item: any, index: number) => ({
          key: index,
          annual_expense: item.annual_expense,
          arrangement_reference_number: item.arrangement_reference_number,
          contract_type: item.contract_type,
          currency: item.currency,
          overarching_reference_number: item.overarching_reference_number,
        }));
        setData(formattedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [is_displayed, entityName]);

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 430 }} />
    </>
  );
};

export default B0201;
