import React, { useState, useEffect, useRef } from 'react';
import useFilters from 'utils/hooks/useFilters';
import CustomTable from 'components/CustomTable/CustomTable';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { notification, DatePicker, Input, Button, Modal } from 'antd';
import {
  PlusOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { tppOp } from 'store/ducks/DataEntry/ThirdPartyProviders';
import {
  ICertificates,
  ITPP,
} from 'store/ducks/DataEntry/ThirdPartyProviders/types';
import {
  EditableColumnType,
  DataType,
} from 'components/CustomTable/CustomTable';
import {
  setProviderNames,
  set_isAddRowTpp,
  set_isTppAdded,
  set_tpps,
  set_addRowCountTpp,
  set_certificates,
  set_tppId,
  set_nameOfTpp,
} from 'store/ducks/DataEntry/ThirdPartyProviders/tppSlice';
import useTppStatus from 'utils/hooks/useTppStatus';
import * as Styled from '../../DataEntry.styled';
import CustomSelect from 'components/CustomSelect';
import { entityOp } from 'store/ducks/DataEntry/Entities';
import { ict_serviceOp } from 'store/ducks/DataEntry/ICTServices';
import AdditionalInformation from 'components/AdditionalInformation/AdditionalInformation';
import { IData } from 'store/ducks/DataEntry/Functions/types';

const ThirdPartyServiceProvider: React.FC = () => {
  useTppStatus();
  const dispatch = useDispatch();
  const { query } = useFilters();
  const [combinedData, setCombinedData] = useState<ITPP[]>([]);
  const { selectedTPP, addRowCountTpp, isAddRowTppFilled, tpps, certificates } =
    useSelector((state: RootState) => state.tpp);
  const { confirm } = Modal;
  const [countries, setCountries] = useState<
    { label: string; value: string }[]
  >([]);
  const [canAddRow, setCanAddRow] = useState(true);
  const [dataSource, setDataSource] = useState<ITPP[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<ITPP[]>([]);
  const [entityNames, setEntityNames] = useState<
    { label: string; value: string }[]
  >([]);
  const [functions, setFunctions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ictServices, setICTServices] = useState<
    { label: string; value: string }[]
  >([]);
  const [count, setCount] = useState(1);
  const [nonSubstReasons, setNonSubstReasons] = useState<
    { label: string; value: string }[]
  >([]);
  const [tppName, setTppName] = useState<string>('');
  const [personTypes, setPersonTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [criticallyAssessments, setCriticallyAssessments] = useState<
    { label: string; value: string }[]
  >([]);
  const [tooltipsForData, setTooltipsForData] = useState<any[]>([]);
  const [tooltipVisibility, setTooltipVisibility] = useState<{
    [key: number]: boolean;
  }>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const isLoggedIn = localStorage.getItem('authToken');
  const [natures, setNatures] = useState<{ label: string; value: string }[]>(
    [],
  );

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const fetchTPPbyEntity = async () => {
    try {
      const response = await tppOp.fetchTPPbyEntityName({
        entity_id: query.entityId,
        entity_name: query.entity,
      });
      const data = response?.data.third_party_service_providers;

      const mappedData = data.map((item: any, index: number) => ({
        key: index,
        ict_service_provider_identification_code:
          item.ict_service_provider_identification_code,
        id: item.id,
        ict_service_provider_lei: item.ict_service_provider_lei,
        notice_period: item.notice_period,
        identification_code_type: item.identification_code_type,
        substitutability: item.substitutability,
        non_subst_reason_id: item.non_subst_reason_id,
        third_party_service_provider_name:
          item.third_party_service_provider_name,
        person_type_id: item.person_type_id,
        headquarters_country: item.headquarters_country,
        entity_nature: item.entity_nature,
        ultimate_parent_identification_code:
          item.ultimate_parent_identification_code,
        last_audit_date: item.last_audit_date,
        description: item.description,
        entity_id: item.entity_id,
        criticality_assessment_id: item.criticality_assessment_id,
        dora_criticality: item.dora_criticality,
        dora_regulator_criticality: item.dora_regulator_criticality,
        end_date: item.end_date,
        marisk_criticality: item.marisk_criticality,
        start_date: item.start_date,
        isNew: false,
        isEdited: false,
        certificates: [],
      }));

      setDataSource(mappedData);

      setCount(mappedData.length);
      const providerNames = mappedData.map(
        (item: { third_party_service_provider_name: any }) =>
          item.third_party_service_provider_name,
      );
      dispatch(setProviderNames(providerNames));
    } catch (error) {
      console.log(error, 'error');
    }
  };
  const fetchFunctions = async () => {
    try {
      const response = await tppOp.fetchFunctions();
      if (response?.data.functions) {
        const formattedOptions = response.data.functions.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setFunctions(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNatures = async () => {
    try {
      const response = await entityOp.fetchNatureID();
      if (response?.data.nature_of_entities) {
        const formattedOptions = response.data.nature_of_entities.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setNatures(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchICTServices = async () => {
    try {
      const response = await tppOp.fetchICTServices();
      if (response?.data.ict_services) {
        const formattedOptions = response.data.ict_services.map(
          (reasons: { arrangement_reference_number: any; id: any }) => ({
            label: reasons.arrangement_reference_number,
            value: reasons.id,
          }),
        );
        setICTServices(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(set_certificates(tooltipsForData));
  }, [tooltipsForData, dispatch]);

  const getCertificates = async () => {
    try {
      const matchedTPP = dataSource.find(
        (item: any) => item.id.toString() === selectedTPP.toString(),
      );

      if (matchedTPP) {
        const thirdPartyServiceProviderName =
          matchedTPP.third_party_service_provider_name;
        setTppName(thirdPartyServiceProviderName);
        const response = await tppOp.setCertificates(
          thirdPartyServiceProviderName,
          selectedTPP,
        );
        const data = response.certificates;
        const mappedData = data.map((certificate: any, index: number) => ({
          key: index,
          id: certificate.id,
          description: certificate.description,
          validity: certificate.validity,
          date_of_issuance: certificate.date_of_issuance,
          certificate_registry: certificate.certificate_registry,
          date_of_certificate_register:
            certificate.date_of_certificate_register,
          third_party_service_provider_id:
            certificate.third_party_service_provider_id,
          function_id: certificate.function_id,
          ict_service_id: certificate.ict_service_id,
          start_date: certificate.start_date,
          end_date: certificate.end_date,
          isNew: false,
          isEdited: false,
          tpp_name: response.tpp_name,
        }));
        setTooltipsForData(mappedData);
        // set_activitiesByTypeEntity(mappedData);
        // dispatch(set_certificates(mappedData));
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchNonSubstReasons = async () => {
    try {
      const response = await tppOp.fetchNonSubstReasons();
      if (response?.data.non_subst_reasons) {
        const formattedOptions = response.data.non_subst_reasons.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setNonSubstReasons(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPersonTypes = async () => {
    try {
      const response = await tppOp.fetchPersonTypes();
      if (response?.data) {
        const formattedOptions = response.data.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setPersonTypes(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAssessments = async () => {
    try {
      const response = await ict_serviceOp.fetchCriticalityAssessments();
      if (response?.data.criticality_assessments) {
        const formattedOptions = response.data.criticality_assessments.map(
          (reasons: { name: any; id: any }) => ({
            label: reasons.name,
            value: reasons.id,
          }),
        );
        setCriticallyAssessments(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEntityNames = async () => {
    try {
      const response = await entityOp.fetchEntityNames();
      if (response?.data) {
        const formattedOptions = response.data.map(
          (reasons: { name_of_entity: any; id: any }) => ({
            label: reasons.name_of_entity,
            value: reasons.id,
          }),
        );
        setEntityNames(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCombinedData([...tpps, ...dataSource]);
  }, [dataSource, tpps]);

  useEffect(() => {
    if (tpps.length < 1) {
      setCombinedData([...dataSource]);
    } else {
      setCombinedData([...tpps]);
      dispatch(set_addRowCountTpp(1));
    }
  }, [dataSource, tpps]);

  useEffect(() => {
    if (query.entity) {
      setDataSource([]);
      fetchTPPbyEntity();
      fetchNonSubstReasons();
      fetchPersonTypes();
      fetchEntityNames();
      fetchAssessments();
      fetchFunctions();
      fetchICTServices();
      fetchCountries();
      fetchNatures();
    } else {
      dispatch(set_tpps([]));
      dispatch(set_addRowCountTpp(0));
    }
  }, [query.entity]);

  useEffect(() => {
    getCertificates();
  }, [selectedTPP]);

  useEffect(() => {
    if (tpps.length > 0) {
      setCombinedData([...filteredDataSource, ...tpps]);
    }
  }, [tpps]);

  useEffect(() => {
    if (dataSource.length === 0 && !query) {
      setFilteredDataSource([]);
      return;
    }

    if (selectedTPP === 'All') {
      const filteredDataSourceWithoutDuplicates = dataSource.filter(
        (item: ITPP) => !tpps.some((tpp: ITPP) => tpp.key === item.key),
      );
      setFilteredDataSource(filteredDataSourceWithoutDuplicates);
      setCombinedData([...filteredDataSourceWithoutDuplicates, ...tpps]);
    } else {
      const filteredData = dataSource.filter(
        (item: ITPP) => item.id.toString() === selectedTPP.toString(),
      );

      const filteredDataSourceWithoutDuplicates = filteredData.filter(
        (item: ITPP) => !tpps.some((tpp: ITPP) => tpp.key === item.key),
      );

      setFilteredDataSource(filteredDataSourceWithoutDuplicates);
      setCombinedData([...filteredDataSourceWithoutDuplicates, ...tpps]);
    }
  }, [dataSource, selectedTPP, tpps]);

  let uniqueIdCounter = 0;
  const generateUniqueId = (): number => {
    uniqueIdCounter += 1;
    const datePart = new Date().getTime() % 1000;
    const randomPart = Math.floor(Math.random() * 100);

    const uniqueId =
      (datePart * 100 + randomPart + uniqueIdCounter) % 1_000_000;
    return uniqueId;
  };

  const doraCriticalityOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
    { label: 'Assessment not performed', value: 'assessment_not_performed' },
  ];
  const mariskCriticalityOptions = [
    { label: 'high', value: 'High' },
    { label: 'medium', value: 'Medium' },
    { label: 'low', value: 'Low' },
  ];

  const handleAddRow = (record?: any, index?: number) => {
    const newlyAddedTpps = tpps.filter((tpp) => tpp.isNew);
    if (!canAddRow) {
      notification.info({
        message: 'Row already added',
        description: 'Please submit to add a new tpp',
        duration: 20,
      });
      return;
    }
    dispatch(set_isAddRowTpp(true));

    const newData: ITPP = {
      key: generateUniqueId(),
      // id: selectedTPP !== 'All' ? selectedTPP : generateUniqueId(),
      id: generateUniqueId(),
      ict_service_provider_identification_code: '',
      ict_service_provider_lei: '',
      notice_period: null,
      identification_code_type: '',
      substitutability: null,
      non_subst_reason_id: '',
      third_party_service_provider_name: '',
      person_type_id: '',
      headquarters_country: '',
      entity_nature: '',
      ultimate_parent_identification_code: '',
      last_audit_date: '',
      description: '',
      entity_id: Number(query.entityId),
      criticality_assessment_id: '',
      dora_criticality: '',
      dora_regulator_criticality: '',
      end_date: '',
      marisk_criticality: '',
      start_date: '',
      isNew: true,
      certificates: [],
    };
    dispatch(set_tppId(newData.id));
    const updatedDataSource = [...dataSource, newData];
    setDataSource(updatedDataSource);
    setCombinedData([...updatedDataSource, ...tpps]);

    dispatch(set_tpps([newData]));
    if (selectedTPP === 'All' || selectedTPP === newData.id.toString()) {
      setFilteredDataSource((prevFiltered) => [...prevFiltered, newData]);
      setCombinedData(filteredDataSource);
    }
    setCanAddRow(false);
  };

  const fetchCountries = async () => {
    try {
      const response = await entityOp.fetchCountries();
      if (response?.data.countries) {
        const formattedOptions = response.data.countries.map(
          (reasons: { country_name: any; id: any }) => ({
            label: reasons.country_name,
            value: reasons.id,
          }),
        );
        setCountries(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = (row: ITPP) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (index > -1) {
      newData.splice(index, 1, {
        ...newData[index],
        ...row,
        isEdited: !newData[index]?.isNew || false,
      });
      setDataSource(newData);
    } else {
      newData.push({
        ...row,
        isEdited: false,
      });
    }
    if (row.isNew) {
      dispatch(set_nameOfTpp(row.third_party_service_provider_name));
    }

    setDataSource(newData);
    setCombinedData([...newData, ...tpps]);
    tpps.length < 1 ? setCombinedData(newData) : setCombinedData([...tpps]);
    const updatedTpps = [...tpps];
    const tppIndex = updatedTpps.findIndex((item) => item.key === row.key);

    if (tppIndex > -1) {
      updatedTpps[tppIndex] = { ...updatedTpps[tppIndex], ...row };
    } else {
      updatedTpps.push(row);
    }
    dispatch(set_tpps(updatedTpps));
  };

  const areAllFieldsFilled = (tpps: ITPP[]) => {
    const newlyAddedTpps = tpps.filter((tpp) => tpp.isNew);
    return newlyAddedTpps.every((item) => {
      const invalidKeys = Object.entries(item).filter(([key, value]) => {
        if (key === 'description') return false;
        return value === '' || value === null || value === undefined;
      });
      return invalidKeys.length === 0;
    });
  };

  const handleOpenTooltip = (certificateId: string) => {
    setTooltipVisibility((prev) => ({ ...prev, [certificateId]: true }));
  };

  const handleCloseTooltip = (certificateId: string) => {
    setTooltipVisibility((prev) => ({ ...prev, [certificateId]: false }));
  };

  const handleRemoveRow = (key: number) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this row?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const updatedDataSource = tpps.filter((item) => item.key !== key);
        dispatch(set_tpps(updatedDataSource));
        dispatch(set_addRowCountTpp(0));
        notification.success({
          message: 'Row Removed',
          description: 'The row has been successfully removed.',
          duration: 10,
        });
      },
      onCancel: () => {
        notification.info({
          message: 'Action Canceled',
          description: 'The row removal has been canceled.',
          duration: 10,
        });
      },
    });
  };

  const handleInactivate = (record: ITPP) => {
    if (record.id === undefined) {
      notification.error({
        message: 'Inactivation Error',
        description: 'The tpp ID is missing.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    confirm({
      title: 'Confirm Inactivation',
      content: `Are you sure you want to soft delete the tpp with ID ${record.id}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          const response = await tppOp.inactivateTPP([Number(record.id)]);
          fetchTPPbyEntity();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel: () => {
        console.log('Soft delete cancelled by the user.');
      },
    });
  };
  const handleDeleteCertificate = (certificateId: number) => {
    confirm({
      title: 'Are you sure you want to delete this certificate?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        const certificateToDelete = tooltipsForData.find(
          (certificate) => certificate.id === certificateId,
        );

        if (certificateToDelete?.isNew) {
          setTooltipsForData((prevData) =>
            prevData.filter((certificate) => certificate.id !== certificateId),
          );
        } else {
          try {
            await tppOp.inactivateCertificates([certificateId]);
            getCertificates();
            // setTooltipsForData((prevData) =>
            //   prevData.filter((activity) => activity.id !== activityId)
            // );
            // dispatch(set_activitiesByTypeEntity(tooltipsForData));
          } catch (error) {
            console.error('Failed to inactivate activity:', error);
          }
        }
      },
      onCancel: () => {
        console.log('Delete action cancelled');
      },
    });
  };
  useEffect(() => {
    if (addRowCountTpp === 1) {
      const allFieldsFilled = areAllFieldsFilled(tpps);
      if (allFieldsFilled) {
        dispatch(set_isTppAdded(true));
      } else {
        dispatch(set_isTppAdded(false));
      }
    }
  }, [tpps, addRowCountTpp]);

  const handleSubmit = async () => {
    const changedData = dataSource.filter(
      (item) => item.isNew || item.isEdited,
    );
    changedData.forEach((item) => {
      if (item.notice_period) {
        item.notice_period = Number(item.notice_period);
      }
    });
    const changedCertificates = certificates.filter(
      (item) => item.isNew || item.isEdited,
    );
    if (changedData.length === 0 && changedCertificates.length === 0) {
      notification.warning({
        message: 'No Changes',
        description:
          'There are no new or edited TPPs or Certificates to submit.',
        placement: 'topRight',
        duration: 20,
      });
      return;
    }

    const newlyAddedTpps = tpps.filter((tpp) => tpp.isNew);
    if (newlyAddedTpps.length !== 0) {
      const allFieldsFilled = areAllFieldsFilled(newlyAddedTpps);
      if (!allFieldsFilled) {
        notification.warning({
          message: 'Not submitted',
          description: 'Please fill in all the required fields in TPP.',
          placement: 'topRight',
          duration: 20,
        });
        return;
      } else {
        dispatch(set_isTppAdded(true));
      }
    }
    try {
      const response = await tppOp.fetchTPP({
        entity_name: query.entity,
        data_list: changedData,
        token: isLoggedIn,
      });
      const responseCertificates = await tppOp.insertCertificates({
        tpp_name: tppName,
        entity_name: query.entity,
        data_list: changedCertificates,
        token: isLoggedIn,
      });
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
        duration: 20,
      });
    }
  };

  const defaultColumns: EditableColumnType[] = [
    {
      title: (
        <>
          Third Party Service Provider<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'third_party_service_provider_name',
      fixed: 'left',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          Identification code of the ICT third-party service provider
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="As reported in B_05.01.0010
Code to identify the ICT third-party service provider as reported in B_05.01.0010 for that provider.
(alphanumerical)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ict_service_provider_identification_code',
      editable: true,
    },
    {
      title: (
        <>
          ICT Service Provider LEI <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'ict_service_provider_lei',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          Notice period for the ICT third-party service provider
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the notice period for terminating the contractual arrangement by the direct ICT third-party service provider in a business-as-usual case. The notice period shall be expressed as number of calendar days from the counterparty’s receipt of the request to terminate the ICT service.
(natural number)
(mandatory if the ICT service is supporting a critical or important function)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'notice_period',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          Type of code to identify the ICT third-party service provider
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="As reported in B_05.01.0020
Type of code to identify the ICT third-party service provider in B_02.02.0030 as reported in B_05.01.0020 for that provider.
(pattern)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'identification_code_type',
      editable: true,
    },
    {
      title: (
        <>
          Substitutability<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'substitutability',
      editable: false,
      render: (_: any, record: any) => (
        <CustomSelect
          value={record.substitutability}
          onChange={(value: string) =>
            handleSave({
              ...record,
              substitutability: value,
            } as ITPP)
          }
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <Styled.TitleComponent>
          Country of the ICT third- party service provider’s headquarters
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Identify the ISO 3166–1 alpha–2 code of the country in which the global operating headquarters of ICT third-party service provider are located (usually, this country is the country of tax residence).
(country)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'headquarters_country',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={countries}
          value={record.headquarters_country}
          onChange={(value: any, option: { label: string; value: string }) => {
            handleSave({
              ...record,
              headquarters_country: option.label,
            } as ITPP);
          }}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <>
           Nature of the financial entity<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'entity_nature',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={natures}
          value={record.entity_nature}
          onChange={(value: any) =>
            handleSave({
              ...record,
              entity_nature: value,
            } as ITPP)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    // {
    //   title: (
    //     <>
    //       Entity Nature<Styled.Asterisx>*</Styled.Asterisx>
    //     </>
    //   ),
    //   dataIndex: 'entity_nature',
    //   editable: true,
    // },
    {
      title: (
        <Styled.TitleComponent>
          Identification code of the ICT third-party service provider’s ultimate
          parent undertaking
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Code to identify the ICT third-party service provider’s ultimate parent undertaking.
The code used to identify ultimate parent undertaking in this field shall match the identification code provided in B_05.01.0010 for that ultimate parent undertaking.
Where the ICT third-party service provider is not part of a group, the identification code used to identify that ICT third- party service provider in B_05.01.0010 shall be repeated also in this data field.
(alphanumerical)
(mandatory if the ICT third- party service provider is not the ultimate parent undertaking)"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'ultimate_parent_identification_code',
      editable: true,
    },
    {
      title: (
        <>
          Last Audit Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'last_audit_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as ITPP;
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                const updatedRow: ITPP = {
                  ...entityRecord,
                  last_audit_date: formattedDateString,
                };
                handleSave(updatedRow);
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: (
        <Styled.TitleComponent>
          Criticality or importance assessment
          <Styled.Asterisx>*</Styled.Asterisx>
          <AdditionalInformation
            description="Use this column to indicate whether the function is critical or important according to the financial entity’s assessment. One of the options in the following closed list shall be used:
            1. Yes
            2. No
            3. Assessment not performed"
          />
        </Styled.TitleComponent>
      ),
      dataIndex: 'criticality_assessment_id',
      editable: false,
      render: (_: any, record: any) => (
        <CustomSelect
          value={record.criticality_assessment_id}
          onChange={(value: string) =>
            handleSave({
              ...record,
              criticality_assessment_id: value,
            } as ITPP)
          }
          options={criticallyAssessments}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <>
          Dora Criticality<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'dora_criticality',
      editable: false,
      render: (_: any, record: any) => (
        <CustomSelect
          value={record.dora_criticality}
          onChange={(value: string) =>
            handleSave({
              ...record,
              dora_criticality: value,
            } as ITPP)
          }
          options={doraCriticalityOptions}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <>
          Dora Regulator Criticality<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'dora_regulator_criticality',
      editable: false,
      render: (_: any, record: any) => (
        <CustomSelect
          value={record.dora_regulator_criticality}
          onChange={(value: string) =>
            handleSave({
              ...record,
              dora_regulator_criticality: value,
            } as ITPP)
          }
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
            { label: 'Unknown', value: 'unknown' },
          ]}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <>
          Start Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'start_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as ITPP;
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                const updatedRow: ITPP = {
                  ...entityRecord,
                  start_date: formattedDateString,
                };
                handleSave(updatedRow);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          End Date <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'end_date',
      editable: false,
      render: (text: string, record: DataType) => {
        const entityRecord = record as ITPP;
        return (
          <div style={{ position: 'relative' }}>
            <DatePicker
              value={text ? dayjs(text) : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date) => {
                const formattedDateString = dayjs(date).format('YYYY-MM-DD');
                const updatedRow: ITPP = {
                  ...entityRecord,
                  end_date: formattedDateString,
                };
                handleSave(updatedRow);
              }}
            />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Marisk Criticality<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'marisk_criticality',
      editable: false,
      render: (_: any, record: any) => (
        <CustomSelect
          value={record.marisk_criticality}
          onChange={(value: string) =>
            handleSave({
              ...record,
              marisk_criticality: value,
            } as ITPP)
          }
          options={mariskCriticalityOptions}
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <>
          Certificates <Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'certificates',
      render: (_: any, record: any) => {
        const relatedCertificates = tooltipsForData.filter(
          (cert) => cert.third_party_service_provider_id === record.id,
        );
        const handleAddCertificate = (recordId: any) => {
          console.log(recordId);
          const newCertificate = {
            id: generateUniqueId(),
            description: '',
            validity: '',
            date_of_issuance: '',
            certificate_registry: '',
            date_of_certificate_register: '',
            third_party_service_provider_id: recordId,
            function_id: '',
            ict_service_id: '',
            tpp_name: '',
            start_date: '',
            end_date: '',
            isNew: true,
          };
          setTooltipsForData((prevData) => [...prevData, newCertificate]);
          dispatch(set_certificates(tooltipsForData));
        };

        const handleUpdateCertificate = (
          certificateId: string,
          field: string,
          value: any,
        ) => {
          setTooltipsForData((prevData) =>
            prevData.map((cert) =>
              cert.id === certificateId
                ? { ...cert, [field]: value, isEdited: true }
                : cert,
            ),
          );
          dispatch(set_certificates(tooltipsForData));
        };

        return (
          <div>
            {relatedCertificates.map((certificate) => (
              <Styled.TooltipContainer
                key={certificate.id}
                title={
                  <div>
                    <Styled.TooltipButtonContainer>
                      <Button
                        size="small"
                        type="text"
                        onClick={() => handleCloseTooltip(certificate.id)}
                      >
                        <CloseCircleOutlined />
                      </Button>
                      <Button
                        size="small"
                        type="text"
                        danger
                        onClick={() => handleDeleteCertificate(certificate.id)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Styled.TooltipButtonContainer>
                    <Styled.InputField>
                      <strong>
                        Description <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <Input
                        defaultValue={certificate.description || ''}
                        onChange={(e) =>
                          handleUpdateCertificate(
                            certificate.id,
                            'description',
                            e.target.value,
                          )
                        }
                      />
                    </Styled.InputField>
                    <Styled.InputField>
                      <strong>Validity</strong>
                      <Input
                        defaultValue={certificate.validity || ''}
                        onChange={(e) =>
                          handleUpdateCertificate(
                            certificate.id,
                            'validity',
                            e.target.value,
                          )
                        }
                      />
                    </Styled.InputField>
                    <Styled.InputField>
                      <strong>Date of Issuance</strong>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          certificate.date_of_issuance
                            ? dayjs(certificate.date_of_issuance, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate =
                            date?.format('YYYY-MM-DD') || '';
                          handleUpdateCertificate(
                            certificate.id,
                            'date_of_issuance',
                            formattedDate,
                          );
                        }}
                      />
                    </Styled.InputField>
                    <Styled.InputField>
                      <strong>
                        Certificate Registry{' '}
                        <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <Input
                        defaultValue={certificate.certificate_registry || ''}
                        onChange={(e) =>
                          handleUpdateCertificate(
                            certificate.id,
                            'certificate_registry',
                            e.target.value,
                          )
                        }
                      />
                    </Styled.InputField>

                    <Styled.InputField>
                      <strong>
                        Date of Certificate Register{' '}
                        <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          certificate.date_of_certificate_register
                            ? dayjs(
                                certificate.date_of_certificate_register,
                                'YYYY-MM-DD',
                              )
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate =
                            date?.format('YYYY-MM-DD') || '';
                          handleUpdateCertificate(
                            certificate.id,
                            'date_of_certificate_register',
                            formattedDate,
                          );
                        }}
                      />
                    </Styled.InputField>

                    <Styled.InputField>
                      <strong>
                        Functions <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <CustomSelect
                        options={functions}
                        value={certificate.function_id}
                        onChange={(value: any) => {
                          handleUpdateCertificate(
                            certificate.id,
                            'function_id',
                            value,
                          );
                        }}
                        filterOption={(
                          input: string,
                          option: { label: string },
                        ) =>
                          option?.label
                            ?.toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Styled.InputField>

                    <Styled.InputField>
                      <strong>
                        ICT Services <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <CustomSelect
                        options={ictServices}
                        value={certificate.ict_service_id}
                        onChange={(value: any) => {
                          handleUpdateCertificate(
                            certificate.id,
                            'ict_service_id',
                            value,
                          );
                        }}
                        filterOption={(
                          input: string,
                          option: { label: string },
                        ) =>
                          option?.label
                            ?.toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Styled.InputField>

                    {/* Start and End Date Picker Fields */}

                    <Styled.InputField>
                      <strong>
                        Start Date <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          certificate.start_date
                            ? dayjs(certificate.start_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate =
                            date?.format('YYYY-MM-DD') || '';
                          handleUpdateCertificate(
                            certificate.id,
                            'start_date',
                            formattedDate,
                          );
                        }}
                      />
                    </Styled.InputField>

                    <Styled.InputField>
                      <strong>
                        End Date <Styled.Asterisx>*</Styled.Asterisx>
                      </strong>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={
                          certificate.end_date
                            ? dayjs(certificate.end_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate =
                            date?.format('YYYY-MM-DD') || '';
                          handleUpdateCertificate(
                            certificate.id,
                            'end_date',
                            formattedDate,
                          );
                        }}
                      />
                    </Styled.InputField>

                    <Styled.InputField>
                      <strong>TPP Name</strong>
                      <Input
                        value={certificate.tpp_name || ''}
                        onChange={(e) =>
                          handleUpdateCertificate(
                            certificate.id,
                            'tpp_name',
                            e.target.value,
                          )
                        }
                      />
                    </Styled.InputField>
                  </div>
                }
                visible={tooltipVisibility[certificate.id] || false}
                trigger="click"
              >
                <Styled.OpenTooltipBtn
                  type="link"
                  onClick={() => handleOpenTooltip(certificate.id)}
                >
                  <Input
                    value={certificate.description || 'Add Certificate'}
                    readOnly
                    addonAfter={<PlusCircleOutlined />}
                  />
                </Styled.OpenTooltipBtn>
              </Styled.TooltipContainer>
            ))}

            <Button
              type="dashed"
              onClick={() => {
                handleAddCertificate(record.id);
              }}
              icon={<PlusOutlined />}
            ></Button>
            <Styled.GlobalStyle />
          </div>
        );
      },
    },
    {
      title: (
        <>
          Non Subst Reason<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'non_subst_reason_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={nonSubstReasons}
          value={record.non_subst_reason_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              non_subst_reason_id: value,
            } as ITPP)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: (
        <>
          Person Type<Styled.Asterisx>*</Styled.Asterisx>
        </>
      ),
      dataIndex: 'person_type_id',
      editable: false,
      render: (_, record) => (
        <CustomSelect
          options={personTypes}
          value={record.person_type_id}
          onChange={(value: any) =>
            handleSave({
              ...record,
              person_type_id: value,
            } as ITPP)
          }
          filterOption={(input: string, option: { label: string }) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record) => (
        <>
          {record.isNew ? (
            <Button danger onClick={() => handleRemoveRow(record.key)}>
              Remove
            </Button>
          ) : (
            <Button danger onClick={() => handleInactivate(record as ITPP)}>
              Soft delete
            </Button>
          )}
        </>
      ),
    },
    // {
    //   title: (
    //     <>
    //       Entity Name<Styled.Asterisx>*</Styled.Asterisx>
    //     </>
    //   ),
    //   dataIndex: 'entity_id',
    //   editable: false,
    //   render: (_, record) => {
    //     const selectedEntity = entityNames.find(
    //       (entity) => entity.value === record.entity_id,
    //     );

    //     return (
    //       <CustomSelect
    //         options={entityNames}
    //         value={selectedEntity ? selectedEntity.value : undefined}
    //         onChange={(value: any) =>
    //           handleSave({
    //             ...record,
    //             entity_id: value,
    //           } as ITPP)
    //         }
    //         filterOption={(input: string, option: { label: string }) =>
    //           option?.label?.toLowerCase().includes(input.toLowerCase())
    //         }
    //       />
    //     );
    //   },
    // },

    // {
    //   title: 'Create',
    //   dataIndex: 'add_row',
    //   key: 'add_row',
    //   width: '10px',
    //   fixed: 'right',
    //   render: (_: any, record: { key: React.Key }, index: number) => (
    //     <Styled.OperationsRow onClick={() => handleAddRow(record, index)}>
    //       <PlusOutlined />
    //     </Styled.OperationsRow>
    //   ),
    // },
  ];

  return (
    dataSource && (
      <div>
        {query.entity && (
          <Styled.AreaButton onClick={handleAddRow} type="primary">
            Add Row
          </Styled.AreaButton>
        )}
        <CustomTable
          columns={defaultColumns}
          dataSource={combinedData}
          handleSave={handleSave}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: handlePageChange,
            showSizeChanger: true,
          }}
        />
        <Styled.AreaButton type="primary" onClick={handleSubmit}>
          Submit
        </Styled.AreaButton>
      </div>
    )
  );
};

export default ThirdPartyServiceProvider;
