import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialGlobalState from './initialState';
import { IReportingState } from './types';

const reportingSlice = createSlice({
  name: 'reporting',
  initialState: initialGlobalState(),
  reducers: {
    set_selectedDate(state: IReportingState, action: PayloadAction<string>) {
      state.date = action.payload;
    },
    set_isDisplayed(state: IReportingState, action: PayloadAction<boolean>) {
      state.is_displayed = action.payload;
    },
    set_isAdditionalDisplayed(
      state: IReportingState,
      action: PayloadAction<boolean>,
    ) {
      state.is_additional_displayed = action.payload;
    },
  },
});
export const { set_selectedDate, set_isDisplayed, set_isAdditionalDisplayed } =
  reportingSlice.actions;
export default reportingSlice;
